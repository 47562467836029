

"use client";

import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import Http from "../../util/http";
import {
    setDeepValue,
    Capitalize,
    FormatNumber,
    nullTransform,
    delay, capitalizeFirstLetter, sortListAlphabeticallyByKey,
} from "../../util/util";
import Swal from "sweetalert2";
import UserGrid from "./UserGrid";

export default function SearchUserModal({open,setOpen,organization}) {
    const [users, setUsers] = useState([]);
    const [paging, setPaging] = useState({
        text: "",
        sort: "",
        total: 0,
        page: 1,
        count: 0,
        perPage: 10,
        branch: "",
        organization: organization._id || "",
    });
    const [loading,setLoading] = useState(false);

    async function search(field) {
        let pagingData = { ...paging };
        pagingData.organization = organization._id;
        pagingData[field.key] = field.value;
        pagingData.page = parseInt(pagingData.page);
        setPaging(pagingData);
        getData(pagingData);
    }

    useEffect(()=>{
        if(users.length === 0 && organization._id !== undefined && organization._id !== null && open === true){
            let pagingData = { ...paging };
            pagingData.organization = organization._id;
            getData(pagingData, true);
        }
    },[open])

    function closeModal(){
        setUsers([])
        setOpen(false);
    }
    function getData(pagingData, showloader,concat) {
        let dialog;
        if (showloader) {
            dialog = Swal.fire({
                title: "Loading...",
                timerProgressBar: true,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
                willClose: () => {},
            });
        }
        Http.request("admin", "users/get-all", pagingData)
            .then((response) => {
                let newUsers = concat ? [...users, ...response.users] : response.users;
                setUsers(sortListAlphabeticallyByKey(newUsers, "lastName"));
                let newPagingData = { ...pagingData };
                delete newPagingData.text;
                setPaging({
                    ...newPagingData,
                    total: response.total,
                    count: response.users.length,
                    perPage: response.perPage,
                });
                if (showloader) {
                    dialog.close();
                }
            })
            .catch((e) => {
                if (showloader) {
                    dialog.close();
                }
                console.log(e);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Failed to make request to servers.",
                });
            });
    }
    async function handleScroll(event) {
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        console.log({ scrollTop, scrollHeight, clientHeight });
        if(clientHeight < 81) return;
        //console.log(scrollTop, scrollHeight, clientHeight);
        const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
        if (scrollPercentage > 99 && !loading) {
            console.log("Scrolled more than 80% of the height");
            setLoading(true)
            await getData({ ...paging, page: paging.page + 1,organization: organization._id },true,true);
            event.target.scrollTop = scrollTop - 181;
            await delay(300)
            setLoading(false);

        }
    }
    return (
        <Dialog open={open} onClose={closeModal} className="relative z-10">
            <Dialog.Backdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Dialog.Panel
                        transition
                        style={{minWidth: "60vw"}}
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className={"text-center font-bold text-2xl mb-3"}>
                            {Capitalize(organization.name)} Users
                        </div>
                        <div className={"mx-5 flex"}>
                            <div className={"w-10/12 mx-2"}>
                                <div className="relative rounded-md shadow-sm">
                                    <div
                                        className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                        <svg
                                            className="h-5 w-5 text-gray-400"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    </div>
                                    <input
                                        value={paging.text}
                                        onChange={(e) => {
                                            search({key: "text", value: e.target.value});
                                        }}
                                        type="search"
                                        name="search"
                                        id="search"
                                        className="block w-full rounded-md border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="Search"
                                    />
                                </div>
                            </div>
                            {/*<div className={"w-4/12 mr-2"}>*/}
                            {/*    <input*/}
                            {/*        value={paging.text}*/}
                            {/*        onChange={(e) => {*/}
                            {/*            search({key: "text", value: e.target.value});*/}
                            {/*        }}*/}
                            {/*        type="search"*/}
                            {/*        name="search"*/}
                            {/*        id="search"*/}
                            {/*        className="block w-full rounded-md border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
                            {/*        placeholder="Search"*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <div className={"w-3/12"}>
                                <button type="button"
                                        className="relative -ml-px rounded inline-flex items-center bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10">Search
                                </button>
                            </div>
                        </div>
                            <div className={"bg-gray-200 p-3 rounded-2xl my-2 max-h-96 overflow-y-auto"} onScroll={(e)=>{handleScroll(e)}}>
                                <UserGrid users={users} organization={organization}/>
                            </div>
                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                            {/*<button*/}
                            {/*    type="button"*/}
                            {/*    disabled={summary === null}*/}
                            {/*    onClick={() => {*/}
                            {/*        next();*/}
                            {/*    }}*/}
                            {/*    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"*/}
                            {/*>*/}
                            {/*    Create Contract*/}
                            {/*</button>*/}
                            <button
                                type="button"
                                data-autofocus
                                onClick={() => closeModal()}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            >
                                Cancel
                            </button>
                        </div>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    );
}
