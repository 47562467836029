import DashboardLayout from "../../layouts/dashboard";
import Layout from "../../layouts/layout";
import Http from "../../util/http";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import {
  CursorArrowRaysIcon,
  EnvelopeOpenIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import Infopib from "../../components/infopib";
import AutomaticWidget from "../../components/dashboard/automaticwidget";
import AccessControl from "../../util/accessControl";

export default function DashboardIndex() {
  const [user, setUser] = useState({});
  const [stats, setStats] = useState([]);
  const [flag, setFlag] = useState(false);
  const [overallTotal, setOverallTotal] = useState(0);
  const [clients, setClients] = useState(0);
  const [averagePerDay, setAveragePerDay] = useState(0);
  const [averagePerDayD, setAveragePerDayD] = useState(0);
  const [averagePerDayI, setAveragePerDayI] = useState(0);
  const [contracts, setContracts] = useState({
    current: 0,
    cancelled: 0,
    approved: 0,
    pending: 0,
    closed: 0,
    arrears: 0,
  });
  const [approvals, setApprovals] = useState(0);
  const [organization, setOrganization] = useState({ name: "" });

  async function getContracts() {
    let tempContracts = {};

    await Http.request("contracts", "contracts/dashboard", {})
      .then((response) => {
        console.log(response.data);
        setContracts(response.data);
        tempContracts = response.data;
      })
      .catch((e) => {
        console.log(e);
      });

    return tempContracts;
  }

  async function getClients() {
    let pagingData = {
      text: "",
      sort: "",
      total: 0,
      page: 1,
      count: 0,
      perPage: 5,
      organization: "",
    };
    let clients = 0;
    await Http.request(
      "contracts",
      `contracts/clients/get-all-count`,
      pagingData
    )
      .then((response) => {
        clients = response.total;
      })
      .catch((e) => {});

    return clients;
  }

  const getCalculations = async (status = "") => {
    const moment = require("moment");
    const currentDate = moment();

    const from = currentDate.subtract(1, "month").startOf("day");

    let pagingData = {
      from,
      to: moment().utc(),
      status,
    };

    await Http.request(
      "contracts",
      `contracts/clients/getAveragePerDay`,
      pagingData
    )
      .then((response) => {
        console.log(response.average);
        if (status == "disbursed") {
          setAveragePerDayD(response.average);
        } else {
          setAveragePerDay(response.average);
        }
      })
      .catch((e) => {});
  };

  const getAutomaticApprovals = async (day) => {
    if(process.env.REACT_APP_USE_SMS === 'false'){
      return;
    }
    let pagingData = {
      text: "",
      sort: "",
      total: 0,
      page: 1,
      date: day,
      count: 0,
      perPage: 10,
      type: "",
      status: "",
    };

    await Http.request("contracts", `contract/approval/get-count`, pagingData)
      .then((response) => {
        setApprovals(response.total);
      })
      .catch((e) => {
        console.log("Error getting a approval data", e);
      });
  };

  const getCalculationsI = async (status = "") => {
    const moment = require("moment");
    const currentDate = moment();

    const from = currentDate.subtract(1, "month").startOf("day");

    let pagingData = {
      from,
      to: moment().utc(),
      status,
    };

    await Http.request(
      "contracts",
      `contracts/clients/getAveragePerDayInsurers`,
      pagingData
    )
      .then((response) => {
        console.log(response.average);
        if (status == "disbursed") {
          setAveragePerDayI(response.average);
        } else {
          setAveragePerDayI(response.average);
        }
      })
      .catch((e) => {});
  };

  const initializeData = async () => {
    console.log("initializeData");

    let dialog = Swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {},
    });

    await getCalculations();
    await getCalculations("disbursed");
    await getCalculationsI();
    await getAutomaticApprovals(moment().format("YYYY-MM-DD"));
    if (JSON.parse(localStorage.getItem("user")) === null) {
      window.location.href = window.location.href.replace(
        "/dashboard",
        "/signin"
      );
    }
    setUser(JSON.parse(localStorage.getItem("user")));
    if (JSON.parse(localStorage.getItem("user")) === null) {
      window.location.href = window.location.href.replace(
        "/dashboard",
        "/signin"
      );
    }
    let vals = [];
    getContracts()
      .then(async (result) => {
        getClients().then(async (result2) => {
          vals = [
            {
              name: "Active Contracts",
              status: "current",
              stat: result.current ? result.current : 0,
            },
            {
              name: "Contracts in Arrears",
              status: "late",
              stat: result.late ? result.late : 0,
            },
            {
              name: "Contracts Pending Approval",
              status: "pendingApproval",
              stat: result.pendingApproval || 0,
            },
            {
              name: "Contracts Approved",
              status: "approved",
              stat: result.approved ? result.approved : 0,
            },
            {
              name: "Contracts Cancelled",
              status: "cancelled",
              stat: result.cancelled ? result.cancelled : 0,
            },
            {
              name: "Contracts Closed",
              nolink: true,
              status: "closed",
              stat:
                result.paidUp + result.paidOff + result.writtenOff
                  ? result.paidUp + result.paidOff + result.writtenOff
                  : 0,
            },
            { name: "Total Clients", stat: result2, nolink: true },
            {
              name: "Total IPF Applications",
              nolink: true,
              stat:
                result.current +
                result.pendingApproval +
                result.late +
                result.paidUp +
                result.paidOff +
                result.writtenOff
                  ? result.current +
                    result.pendingApproval +
                    result.late +
                    result.paidUp +
                    result.paidOff +
                    result.writtenOff
                  : 0,
            },
          ];

          setStats(vals);
          setFlag(true);
        });
      })
      .then((res3) => {
        dialog.close();
      });
  };

  useEffect(() => {
    setOrganization(JSON.parse(localStorage.organization));

    initializeData();
    // getContracts()
  }, []);
  return (
    <>
      <Infopib />
      <div>
        {user.firstName !== null && (
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Welcome{" "}
            <strong>
              {" "}
              {user.firstName} {user.lastName} / {localStorage?.role || ""} /{" "}
              {organization.name}
            </strong>
          </h3>
        )}

        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
          {stats.map((item) => (
            <a
              style={{ cursor: "pointer" }}
              key={item.name}
              onClick={() => {
                if (!item.nolink) {
                  window.location.replace(
                    `${window.location.origin}/dashboard/contracts?status=${item.status}`
                  );
                }
              }}
            >
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">
                  {item.name}
                </dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {item.stat}
                </dd>
              </div>
            </a>
          ))}

          {flag && (
            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">
                IPF Applications Per Day{" "}
              </dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                {averagePerDay?.toFixed(2) || 0}
              </dd>
            </div>
          )}
          {flag && (
            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">
                IPF Applications Disbursed Per Day{" "}
              </dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                {averagePerDayD?.toFixed(2) || 0}
              </dd>
            </div>
          )}

          {flag && (
            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">
                IPF Applications Disbursed Per Insurer{" "}
              </dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                {averagePerDayI?.toFixed(2) || 0}
              </dd>
            </div>
          )}
          {flag && process.env.REACT_APP_USE_SMS === 'true'&& (
            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500 flex justify-between row">
                <div>Automatic Approvals</div>
                <AccessControl fallback={<></>} permission={'viewApproval'} target={['god']}>
                 <AutomaticWidget getAutomaticApprovals={getAutomaticApprovals}/>
                </AccessControl>
              </dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                {approvals || 0}
              </dd>
            </div>
          )}
        </dl>
      </div>
    </>
  );
}
