import { useEffect, useState } from 'react'
import {setDeepValue} from '../../../util/util'
import Http from '../../../util/http'
import Swal from 'sweetalert2'
import AccessControl from '../../../util/accessControl'
import TelephoneInput from '../../../components/dashboard/telephoneInput'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}




export default function OrganizationCreate() {
  const [companyTypes, setCompanyTypes] = useState([])
  const [countries,setCountries] = useState([])
  const [organization,SetOrganization] =  useState({
    address:{street: '', city: '', state: '',zip:'',country: ''},
    contactInfo: {telephone: '',email: ''},
    name: '',
    type: 'insurers',
    registrationNumber: Date.now(),
    branchInput:''
  })

  useEffect(() => {
    getCompanyType()
    Http.request('admin', 'countries',[]).then((response) => {
      setCountries(response);
    });

    let geo = JSON.parse(localStorage.getItem('geolocation'));
    let org = {...organization}
    org.address.country = geo.code;
    SetOrganization(org)
  }, [])


  function setKey(key,value) {
    let object = {... organization}

    setDeepValue(object,value,key)
    SetOrganization(object)
  }

/*  function saveBranch() {
    const list = organization.branches;
    list.push(organization.branchInput);
    organization.branchInput = '';
    setKey('branches',list);
  }*/

  function getCompanyType() {
    Http.request('admin','companyTypes/get-all',{text: '',sort: '',total: 0,page: 1,count: 0,perPage: 999, type:'', companyType:''}).then((response)=>{
      setCompanyTypes(response.coverages)
  })
  }

/*  function deleteBranch(name){
    const newList = organization.branches.filter((elem) => elem !== name);
    setKey('branches',newList);
  }*/
  function save(e){
    let dialog = Swal.fire({
      title: 'Loading...',
      timerProgressBar: true,
      allowOutsideClick: false, 
      didOpen: () => {
        Swal.showLoading()
      },
      willClose: () => {
      }
    })
      e.preventDefault();
      try
      {
          delete organization.branchInput;
          Http.request('admin','organizations/create',organization).then((response)=>{
            Swal.fire({
              icon: 'success',
              title: 'Hurray!',
              text: 'Organization added successfully.',
            }).then(() => {
              dialog.close()
              window.location = `${window.location.origin}/dashboard/organizations/${response._id}`
            })
          }).catch((err)=>{
             dialog.close()
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err.message || 'Failed to make request to servers.',
            })
          })
      }catch(err){
        dialog.close()
        console.log(err)
      }
  }
  return (
    <div className="shadow sm:overflow-hidden sm:rounded-md ">
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <form onSubmit={(e)=>{save(e)}}>
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
              <div>
                <h2 className="text-base font-semibold leading-6 text-gray-900">Organization Information</h2>
                <p className="mt-1 text-sm text-gray-500">Please ensure all fields are filled in correctly.</p>
              </div>

              <div className="grid grid-cols-6 gap-10">
                <div className="col-span-6 sm:col-span-3">
                  <label  className="block text-sm font-medium leading-6 text-gray-900">
                    Name
                  </label>
                  <input
                    required
                    type="text"
                    onChange={(e)=>{setKey('name', e.target.value)}}
                    value={organization.name}
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label  className="block text-sm font-medium leading-6 text-gray-900">
                    Registration Number
                  </label>
                  <input
                    type="text"
                    disabled
                    readOnly
                    value={organization.registrationNumber}
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="email-address" className="block text-sm font-medium leading-6 text-gray-900">
                    Email address
                  </label>
                  <input
                    required
                    value={organization.contactInfo.email}
                    onChange={(e)=>{setKey('contactInfo.email', e.target.value)}}
                    type="email"
                    name="email-address"
                    id="email-address"
                    autoComplete="email"
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="telephone" className="block text-sm font-medium leading-6 text-gray-900">
                    Telephone
                  </label>
                  <TelephoneInput isRequired={true} setKey={setKey} path={'contactInfo.telephone'} value={organization.contactInfo.telephone}  className={"mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"}/>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label  className="block text-sm font-medium leading-6 text-gray-900">
                    Organization Type
                  </label>
                  <select
                    required
                    id="type"
                    name="type"
                    autoComplete="type"
                    value={organization.type}
                    onChange={(e)=>{setKey('type', e.target.value)}}
                    className="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option key={''} value={''} >Choose organization type</option>
                         { companyTypes.map(item => <option key={item._id} value={item.name}>{item.name}</option>) }
                  </select>
                </div>

                <div className="col-span-6">
                  <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
                    Street address
                  </label>
                  <input
                    type="text"
                    required
                    name="street-address"
                    id="street-address"
                    value={organization.address.street}
                    onChange={(e)=>{setKey('address.street', e.target.value)}}
                    autoComplete="street-address"
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                    City
                  </label>
                  <input
                    type="text"
                    required
                    name="city"
                    id="city"
                    value={organization.address.city}
                    onChange={(e)=>{setKey('address.city', e.target.value)}}
                    autoComplete="address-level2"
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                    State / Province
                  </label>
                  <input
                    type="text"
                    name="region"
                    required
                    id="region"
                    autoComplete="address-level1"
                    value={organization.address.state}
                    onChange={(e)=>{setKey('address.state', e.target.value)}}
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
                    ZIP / Postal code
                  </label>
                  <input
                    type="text"
                    name="postal-code"
                    id="postal-code"
                    autoComplete="postal-code"
                    value={organization.address.zip}
                    onChange={(e)=>{setKey('address.zip', e.target.value)}}
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="col-span-6">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Country
                  </label>
                  <select
                      required
                      disabled
                      id="country"
                      name="country"
                      value={organization.address.country}
                      onChange={(e)=>{setKey('address.country', e.target.value)}}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value=""> Select a Country </option>
                    {countries.map(item => <option key={item.code} value={item.code}> {item.title} </option> )}
                  </select>
                </div>
              </div>

              {/*<div className="mb-10">
                <div className="mb-5">
                  <div>
                    <h2 className="text-base font-semibold leading-6 text-gray-900">Organization Branches</h2>
                    <p className="mt-1 text-sm text-gray-500">Add the name of any branches affiliated with this organization.</p>
                  </div>
                </div>
                <ul role="list" className="divide-y divide-y-8 divide-white">
                  {organization.branches.map((branch,index) => (
                      <li key={index} className="flex bg-stone-200">
                        <div className="p-5 grid grid-cols-8">
                          <div className="col-span-1">
                            <span>{branch}</span>
                          </div>
                          <div className="col-span-1">
                            <button
                                type="button"
                                onClick={() => {
                                  deleteBranch(branch)
                                }}
                                className="rounded-full h-10 w-10 bg-red-700">
                              <FontAwesomeIcon
                                  icon={faTrash}
                                  className="font-medium text-2xl leading-7 mt-1"
                                  style={{color: "white"}}
                              />
                            </button>
                          </div>
                        </div>
                      </li>
                  ))}
                  <li className="flex bg-stone-200">
                    <div className="p-5 grid grid-cols-2">
                      <div className="col-span-1">
                        <input
                            type="text"
                            name="region"
                            id="region"
                            autoComplete="address-level1"
                            onChange={(e)=>{
                              setKey('branchInput',e.target.value)
                            }}
                            value={organization.branchInput}
                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <div className="col-span-1">
                        <button
                            type="button"
                            onClick={() => {
                              saveBranch()
                            }}
                            className="rounded-full h-10 w-10 bg-lime-600">
                          <FontAwesomeIcon
                              icon={faPlus}
                              className="font-medium text-2xl leading-7 mt-1"
                              style={{color: "white"}}
                          />
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>*/}


            </div>
            <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
              <a href='/dashboard/organizations'  type="button" class="text-sm font-semibold leading-6 text-gray-900 mx-3">Cancel</a>
              <AccessControl fallback={<></>} permission={'addOrganization'} target={['god']}>
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Save
                  </button>
              </AccessControl>

            </div>
          </div>
        </form>
      </div>
    </div>
  )
}


