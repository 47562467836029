import TableRow from "./tablerow";
import React from "react";
import {useState,useEffect} from "react";
import {FormatNumber} from "../../util/util";


export default function DetailedSchedule({currentContract}){

    return (
        <div>
            <div className="mb-5">
                <p className="text-3xl">Payment Schedule</p>
            </div>
            <table className="min-w-full divide-y divide-gray-300">
                <thead>
                <tr>
                    <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                    >
                        {" "}
                        Payment Date
                    </th>
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                        Principal
                    </th>
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                        Interest
                    </th>
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                        Fee
                    </th>
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                        Penalty
                    </th>
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                        Paid
                    </th>
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                        Total Due
                    </th>
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                        Loan Balance
                    </th>
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                        Status
                    </th>
                </tr>
                </thead>
                {currentContract?.loan?.installments?.length > 0 && (
                    <tbody className="bg-white">
                    {currentContract?.loan?.installments?.map(
                        (pySch, index) => (
                            <TableRow
                                currentContract={currentContract}
                                pySch={pySch}
                                index={index}
                            />
                        )
                    )}
                    <tr className="border-t border-gray-300 bg-slate-200">
                        <th
                            colSpan="1"
                            scope="colgroup"
                            className=" pr-3 text-left text-base font-semibold text-gray-900 pl-3"
                        >
                            Totals
                        </th>
                        <th
                            scope="colgroup"
                            className="py-2  px-3 text-left text-sm font-semibold text-gray-900 "
                        >
                            ${" "}
                            {FormatNumber(
                                parseFloat(
                                    currentContract?.loan?.paid?.principal || 0
                                ).toFixed(2)
                            )}
                        </th>
                        <th
                            scope="colgroup"
                            className=" py-2 px-3 text-left text-sm font-semibold text-gray-900 "
                        >
                            ${" "}
                            {FormatNumber(
                                parseFloat(
                                    currentContract?.loan?.paid?.interest || 0
                                ).toFixed(2)
                            )}
                        </th>
                        <th
                            scope="colgroup"
                            className=" py-2 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                            ${" "}
                            {FormatNumber(
                                parseFloat(
                                    currentContract?.loan?.paid?.fee || 0
                                ).toFixed(2)
                            )}
                        </th>
                        <th
                            scope="colgroup"
                            className=" py-2 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                            ${" "}
                            {FormatNumber(
                                parseFloat(
                                    currentContract?.loan?.paid?.penalty || 0
                                ).toFixed(2)
                            )}
                        </th>
                        <th
                            scope="colgroup"
                            className=" py-2 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                            ${" "}
                            {FormatNumber(
                                parseFloat(
                                    currentContract?.loan?.paid?.total || 0
                                ).toFixed(2)
                            )}
                        </th>
                        <th
                            scope="colgroup"
                            className=" py-2 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                            ${" "}
                            {FormatNumber(
                                parseFloat(
                                    currentContract?.loan?.balance?.total || 0
                                ).toFixed(2)
                            )}
                        </th>
                        <th
                            scope="colgroup"
                            className=" py-2 px-3 text-left text-sm font-semibold text-gray-900"
                        ></th>
                        <th
                            colSpan="2"
                            scope="colgroup"
                            className=" py-2 px-3 text-left text-sm font-semibold text-gray-900"
                        ></th>
                    </tr>
                    </tbody>
                )}
            </table>
        </div>
    )
}