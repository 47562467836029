import { useState,useEffect } from 'react'
import {setDeepValue} from '../../../util/util'
import Http from '../../../util/http'
import Swal from 'sweetalert2'
import moment from 'moment'
import AccessControl from '../../../util/accessControl'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function isEmpty(value){
    return (value == null || value.length === 0);
  }

export default function IPEdit() {
  const [organizations,setOrganizations] = useState([])
  const [users,setUsers] = useState([])
  const [ip,setIP] =  useState({
    description: '',
    address: '',
    text: '',
    status: 'whitelist',
    userId: '',
    organization: '',
    maxAddress: '',
    createdAt: moment(new Date()).format('MMMM Do YYYY, h:mm:ss a')
  })

  useEffect(() => {

    async function fetchAPI() {
      try{
        let response = await Http.request('admin','organizations/get-all',{text: '',page: 1,sort: '',total: 0,count: 0,perPage: 10000})
        if(response.organizations.length > 0){
            setOrganizations(response.organizations)
        }
        let res = await Http.request('admin','users/get-all',{text: '',page: 1,sort: '',total: 0,count: 0,perPage: 10000, organization: response.organizations[0]._id})
        if(res.users.length > 0){
          setUsers(res.users)
        }
        let data = await  Http.request('admin','ips/get-one',{id: window.location.pathname.split("/")[3]})
        setIP(data)
      }
      catch(e){
        console.log(e)
      }
    }

    fetchAPI()
  }, [])

  function setKey(key,value) {
    console.log({key:key,value:value})
    let object = {... ip}

    setDeepValue(object,value,key)
    setIP(object)
  }
  function save(e){
    let dialog = Swal.fire({
      title: 'Loading...',
      timerProgressBar: true,
      allowOutsideClick: false, 
      didOpen: () => {
        Swal.showLoading()
      },
      willClose: () => {
      }
    })
      e.preventDefault();
        if(isEmpty(ip.address) || isEmpty(ip.maxAddress) || isEmpty(ip.userId) || isEmpty(ip.organization)) {
            alert('Please check fields they cannot be empty')
        }
      try
      {
          Http.request('admin','ips/update-one',{id:ip._id,ip:ip}).then((response)=>{
            console.log(response)
            dialog.close()
            Swal.fire({
              icon: 'success',
              title: 'Hurray!',
              text: 'ip added successfully.',
            })
            window.location = `${window.location.origin}/dashboard/ips`
          }).catch((err)=>{
            dialog.close()
            console.log(err)
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Failed to make request to servers.',
            })
          })
      }catch(err){
        console.log(err)
      }
  }
  function remove(e){
    e.preventDefault();
    try
    {

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
      }).then((result) => {
        if (result.isConfirmed) {

        Http.request('admin','ips/remove-one',{id: ip._id}).then((response)=>{
          console.log(response)
          Swal.fire({
            icon: 'success',
            title: 'Hurray!',
            text: 'IP removed successfully',
          })
          window.location = `${window.location.origin}/dashboard/ips`
        }).catch((err)=>{
          console.log(err)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Failed to make request to servers.',
          })
        })
      }})
    }catch(err){
      console.log(err)
    }
  }
  return (
    <div className="shadow sm:overflow-hidden sm:rounded-md  ">
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <div >
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
              <div>
                <h2 className="text-base font-semibold leading-6 text-gray-900">IP Information</h2>
                <p className="mt-1 text-sm text-gray-500">Please ensure all fields are filled in correctly.</p>
              </div>

              <div className="grid grid-cols-6 gap-10">
              <div className="col-span-6 sm:col-span-3">
                  <label  className="block text-sm font-medium leading-6 text-gray-900">
                   Organization
                  </label>
                  <select
                    id="type"
                    name="type"
                    autoComplete="type"
                    value={ip.organization}
                    onChange={(e)=>{setKey('organization', e.target.value)}}
                    className="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    {
                      organizations.map((org,index)=>{
                        return <option key={index} value={org._id}>{org.name}</option>
                      })
                    }
                  </select>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label  className="block text-sm font-medium leading-6 text-gray-900">
                    User
                  </label>
                  <select
                    id="type"
                    name="type"
                    autoComplete="type"
                    value={ip.userId}
                    onChange={(e)=>{setKey('userId', e.target.value)}}
                    className="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    {
                      users.map((user,index)=>{
                        return <option key={index} value={user._id}>{user.firstName} {user.lastName}</option>
                      })
                    }
                  </select>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label  className="block text-sm font-medium leading-6 text-gray-900">
                    Address
                  </label>
                  <input
                    type="text"
                    onChange={(e)=>{setKey('address', e.target.value)}}
                    value={ip.address}
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label  className="block text-sm font-medium leading-6 text-gray-900">
                    Max Address
                  </label>
                  <input
                    type="text"
                    onChange={(e)=>{setKey('maxAddress', e.target.value)}}
                    value={ip.maxAddress}
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="col-span-6">
                  <label  className="block text-sm font-medium leading-6 text-gray-900">
                    Requested By
                  </label>
                  <input
                    type="text"
                    disabled
                    onChange={(e)=>{setKey('requested_BY', e.target.value)}}
                    value={ip.requested_BY}
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label  className="block text-sm font-medium leading-6 text-gray-900">
                    IP Status
                  </label>
                  <select
                    id="type"
                    name="type"
                    autoComplete="type"
                    value={ip.status}
                    onChange={(e)=>{setKey('status', e.target.value)}}
                    className="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value={'whitelist'}>Whitelisted</option>
                    <option value={'blacklist'}>BlackListed</option>
                    <option value={'override'}>Override</option>
                  </select>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label  className="block text-sm font-medium leading-6 text-gray-900">
                    Date Added
                  </label>
                  <input
                    type="text"
                    disabled
                    value={moment(new Date(ip.createdAt)).format('MMMM Do YYYY, h:mm:ss a')}
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

              </div>
            </div>
            <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
              <a href='/dashboard/ips'  type="button" className="text-sm font-semibold leading-6 text-gray-900 mx-3">Cancel</a>
              <AccessControl fallback={<></>} permission={'deleteIps'} target={['god']}>
                  <button
                    onClick={(e)=>{remove(e)}}
                    type="submit"
                    className="mx-2 inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                  >
                    Remove
                  </button>
              </AccessControl>
              <AccessControl fallback={<></>} permission={'updateIps'} target={['god']}>
                <button
                  onClick={(e)=>{save(e)}}
                  type="submit"
                  className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Save
                </button>
              </AccessControl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

