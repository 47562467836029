/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import {
  setDeepValue,
  getDeepValue,
  formatPhoneNumber,
  getStatus,
} from "../../../../../util/util";
import Http from "../../../../../util/http";
import AccessControl from "../../../../../util/accessControl";
import Flag from "react-world-flags";
import Swal from "sweetalert2";
import CommercialClientEditModal from "../../../../../components/CommercialClientEditModal";
import {
  CurrencyDollarIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import swal from "sweetalert2";
import DetailedSchedule from "../../../../../components/dashboard/DetailedSchedule";
import CommercialClientHeader from "../../../../../components/dashboard/commercial/clientheader";
import CommercialClientTabs from "../../../../../components/dashboard/commercial/clienttabs";
import CommercialContractList from "../../../../../components/dashboard/commercial/contractlist";
import CommercialContractDetails from "../../../../../components/dashboard/commercial/contractdetails";

export default function CommercialClientProfile() {
  const [commUser, setCommUser] = useState({
    _id: "",
    photoLink: "",
    businessPhoneNumberOne: "...",
    businessPhoneNumberTwo: "...",
    businessFaxNumber: "...",
    businessEmailAddress: "...",
    businessName: "...",
    businessAddress: "...",
    businessAddressFirstLine: "...",
    businessAddressSecondLine: "",
    businessAddressCity: "...",
    businessAddressState: "...",
    businessAddressCountry: "...",
    businessClientCode: "...",
    businessDescription: "...",
    businessRegistrationNumber: "...",
    authorizerFirstName: "...",
    authorizerLastName: "...",
    paymentInfoType: "...",
    paymentInfoBankName: "...",
    paymentInfoAccountType: "...",
    paymentInfoAccountNumber: "...",
    status: "...",
  });
  const [modCommUser, setModCommUser] = useState({
    _id: "",
    photoLink: "",
    businessPhoneNumberOne: "",
    businessPhoneNumberTwo: "",
    businessFaxNumber: "",
    businessEmailAddress: "",
    businessName: "",
    businessAddress: "",
    businessAddressFirstLine: "...",
    businessAddressSecondLine: "",
    businessAddressCity: "...",
    businessAddressState: "...",
    businessAddressCountry: "...",
    businessClientCode: "",
    businessDescription: "",
    businessRegistrationNumber: "",
    authorizerFirstName: "",
    authorizerLastName: "",
    paymentInfoType: "",
    paymentInfoBankName: "",
    paymentInfoAccountType: "",
    paymentInfoAccountNumber: "",
    status: "",
  });
  const [current, setCurrent] = useState(0);
  const [contractView, setContractView] = useState(false);
  const [selectedContract, setSelectedContract] = useState({
    _id: "",
    contractNumber: "",
    status: "",
    insuranceCompany: "",
    policyStartDate: "",
    policyEndDate: "",
    totalPremiumFinanced: "",
    scheduledPaymentDate: "",
    policyBreakdown: [],
    paymentSchedule: [],
  });
  const [modalToggle, setModalToggle] = useState(false);
  const [modalAction, setModalAction] = useState(0);
  const [contracts, setContract] = useState([]);
  const [link, setLink] = useState("");
  const [tabs, setTabs] = useState([
    { name: "Business Details", current: true },
    { name: "Commercial Contacts", current: false },
  ]);

  useEffect(() => {
    try {
      getCommercialClientData(window.location.pathname.split("/")[5]).then(
        () => {
          getCommercialClientContracts(
            window.location.pathname.split("/")[5]
          ).then(() => {});
        }
      );
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to make request to servers.",
      });
    }
  }, []);

  function openModal(action) {
    setModalAction(action);
    setModCommUser({ ...commUser });
    setModalToggle(true);
  }
  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  function saveModalData(action, obj) {
    if (isValidEmail(obj.businessEmailAddress)) {
      console.log("Valid email:", obj.businessEmailAddress);
    } else {
      console.log("Invalid email:", obj.businessEmailAddress);
      swal.fire({
        title: "Oops",
        text: "Please check the email address.",
        icon: "info",
        showConfirmButton: true,
      });
      return;
    }
    let dialog = Swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {},
    });
    Http.request("contracts", "client/commercial/edit-info", obj)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Hurray!",
          text: "Commercial client account updated successfully",
        }).then(() => {
          dialog.close();
          setModalToggle(false);
          window.location.reload();
        });
      })
      .catch((err) => {
        dialog.close();
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err || "Failed to make request to servers.",
        });
      });
    //reload page after posting info
  }

  function selectTab(index) {
    let tempTabs = tabs;
    tempTabs[index].current = true;
    tempTabs[current].current = false;
    setTabs(tempTabs);
    setCurrent(index);
  }


  function nullTransform(val) {
    if (val === null || val === undefined) {
      return "";
    }
    return val;
  }

  function FormatNumber(str) {
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  async function cancelLoan(id) {
    try {
      Swal.fire({
        title: "Attention!",
        text: "Are you sure you want to cancel this loan\n!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, send it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let response = await Http.request(
            "contracts",
            `contracts/cancel/${id}`
          );
          Swal.fire(
            "Success!",
            "Loan has been cancelled successfully.",
            "success"
          ).then(() => {
            window.location.reload();
          });
        }
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to cancel loan!",
      });
    }
  }


  function openContractDetailsPage(id) {
    if (id !== "" && id !== null && id !== undefined) {
      getCommercialClientContractDetail(id);
    } else {
      setContractView(false);
    }
  }

  async function getCommercialClientData(id) {
    try {
      let userData = await Http.request(
        "contracts",
        "client/commercial/get-one",
        { id }
      );
      if (typeof userData !== "undefined") {
        setCommUser(userData);
        setModCommUser(userData);
      } else {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to retrieve commercial client information",
        });
      }
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err || "Failed to make request to servers.",
      });
    }
  }

  async function getCommercialClientContracts(id) {
    try {
      let contracts = await Http.request(
        "contracts",
        "contract/client/commercial/get-all",
        { id }
      );
      if (typeof contracts !== "undefined" && contracts.length > 0) {
        setContract(contracts);
      } else {
      }
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err || "Failed to make request to servers.",
      });
    }
  }

  async function getCommercialClientContractDetail(id) {
    let dialog = Swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {},
    });
    try {
      let details = await Http.request(
        "contracts",
        "contract/client/commercial/details",
        { id }
      );
      dialog.close();
      if (typeof details !== "undefined" && details) {
        console.log("This is dataaaaaaa", details);
        setSelectedContract(details);
        setContractView(true);
        console.log("This is dataaaaaaa", details);
        let base = `${"https://portal.yoozit.today/quickpay/1/"}${getDeepValue(
          details,
          "loan.loanNumber"
        )}/0/${commUser.businessEmailAddress}`;

        if (localStorage.geolocation) {
          let geo = JSON.parse(localStorage.geolocation);
          if (geo.code === "grd" || geo.code === "lca") {
            base = "https://dtu.linkuptt.com/linkupv2/index.php/welcome";
          }
        }
        setLink(base);
      } else {
      }
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err || "Failed to make request to servers.",
      });
    }
  }


  function back() {
    window.history.back();
  }

  async function sendEmailInvite() {
    try {
      let url = window.location.href.split("/");
      let response = await Http.request(
        "contracts",
        `contracts/clients/verify/email`,
        { id: `${window.location.href.split("/")[url.length - 1]}` }
      );
      Swal.fire(
        "Hurray!",
        "Verification code has been sent to the clients email",
        "success"
      );
    } catch (e) {
      console.log(e);
      Swal.fire("Oops", "Failed to send email verification code.", "error");
    }
  }

  return (
    <div className="flex justify-center ">
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 w-full">
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
            <div className="mb-20">
              <h1 className="text-4xl text-center font-semibold  leading-6 text-gray-900">
                Commercial Client Profile
              </h1>
              <CommercialClientEditModal
                open={modalToggle}
                setOpen={setModalToggle}
                action={modalAction}
                commUser={modCommUser}
                setCommUser={setModCommUser}
                save={saveModalData}
              ></CommercialClientEditModal>
            </div>
            <CommercialClientHeader commUser={commUser} openModal={openModal} sendEmailInvite={sendEmailInvite}/>

            <CommercialClientTabs selectTab={selectTab} tabs={tabs} current={current}/>

            <div className="p-5 px-20">
              {current === 0 && (
                <div>
                  <div className="mt-6 border-t border-gray-100">
                    <div className="mb-5">
                      <div className="grid grid-cols-2 mb-10">
                        <div className="col-span-1">
                          <h2 className="text-2xl">Authorized Officer</h2>
                          <div className="grid grid-cols-2">
                            <div className="col-span-2">
                              <div className="grid grid-cols-12">
                                <div className="col-span-9">
                                  <input
                                    disabled
                                    type="text"
                                    value={
                                      commUser.authorizerFirstName +
                                      " " +
                                      commUser.authorizerLastName
                                    }
                                    className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                                <div className="col-span-3">
                                  <svg
                                    onClick={() => {
                                      openModal(1);
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="ml-10 h-4 w-4 cursor-pointer hover:red-500"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-span-1">
                          <h2 className="text-2xl">Company Registration </h2>
                          <div className="flex">
                            <div className="w-9/12 mr-5">
                              <input
                                disabled
                                type="text"
                                value={commUser.businessRegistrationNumber}
                                className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                            <div className="col-span-1">
                              <svg
                                onClick={() => {
                                  openModal(2);
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 cursor-pointer hover:red-500"
                                viewBox="0 0 512 512"
                              >
                                <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-10">
                        <h2 className="text-2xl">Description</h2>
                        <div className="grid grid-cols-2">
                          <div className="col-span-1 mr-3">
                            <div className="grid grid-cols-12">
                              <div className="col-span-9">
                                <textarea
                                  rows={5}
                                  disabled
                                  type="text"
                                  value={commUser.businessDescription}
                                  className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                              <div className="col-span-3">
                                <svg
                                  onClick={() => {
                                    openModal(3);
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="ml-10 h-4 w-4 cursor-pointer hover:red-500"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="grid grid-cols-2">
                          <div className="col-span-1">
                            <div className="mb-2">
                              <h2 className="text-2xl">Address</h2>
                              <div className="flex">
                                <div className="w-10/12 mr-5">
                                  <div className="grid grid-cols-12">
                                    <div className="col-span-9">
                                      <textarea
                                        rows={3}
                                        disabled
                                        value={commUser.businessAddress}
                                        className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                    <div className="col-span-3">
                                      <svg
                                        onClick={() => {
                                          openModal(4);
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="ml-10 h-4 w-4 cursor-pointer hover:red-500"
                                        viewBox="0 0 512 512"
                                      >
                                        <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <h2 className="text-2xl">Email Address</h2>
                              <div className="grid grid-cols-2">
                                <div className="col-span-2">
                                  <div className="grid grid-cols-12">
                                    <div className="col-span-9">
                                      <input
                                        disabled
                                        value={commUser.businessEmailAddress}
                                        className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                    <div className="col-span-3">
                                      <svg
                                        onClick={() => {
                                          openModal(5);
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="ml-10 h-4 w-4 cursor-pointer hover:red-500"
                                        viewBox="0 0 512 512"
                                      >
                                        <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-1">
                            <div className="mb-2">
                              <h2 className="text-2xl">Business Phone # 1</h2>
                              <div className="flex">
                                <div className="w-10/12 mr-5">
                                  <input
                                    disabled
                                    value={formatPhoneNumber(
                                      commUser.businessPhoneNumberOne
                                    )}
                                    className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                                <div className="col-span-1">
                                  <svg
                                    onClick={() => {
                                      openModal(6);
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 cursor-pointer hover:red-500"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <div className="mb-2">
                              <h2 className="text-2xl">Business Phone # 2</h2>
                              <div className="flex">
                                <div className="w-10/12 mr-5">
                                  <input
                                    disabled
                                    value={formatPhoneNumber(
                                      commUser.businessPhoneNumberTwo
                                    )}
                                    className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                                <div className="col-span-1">
                                  <svg
                                    onClick={() => {
                                      openModal(7);
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 cursor-pointer hover:red-500"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <div>
                              <h2 className="text-2xl">Business Fax Phone</h2>
                              <div className="flex">
                                <div className="w-10/12 mr-5">
                                  <input
                                    disabled
                                    value={formatPhoneNumber(
                                      commUser.businessFaxNumber
                                    )}
                                    className="mt-2 block w-full rounded-md border-1 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                                <div className="col-span-1">
                                  <svg
                                    onClick={() => {
                                      openModal(8);
                                    }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 cursor-pointer hover:red-500"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              )}

              {current === 1 && !contractView && (
                <CommercialContractList contracts={contracts} getCommercialClientContractDetail={getCommercialClientContractDetail}/>
              )}

              {current === 1 && contractView && (
                <CommercialContractDetails cancelLoan={cancelLoan} link={link} contracts={contracts} selectedContract={selectedContract} openContractDetailsPage={openContractDetailsPage}/>
              )}
            </div>
          </div>
          <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
            <button
              type="button"
              onClick={back}
              className="text-sm font-semibold leading-6 text-gray-900 mx-3"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
