import PreviewTable from "../previewTable";
import QuotationTabs from "../qoutationtab";
import MonthGrid from "../monthgrid";
import { FormatNumber } from "../../../util/util";

export default function QuotationPreview({
  generate,
  preview,
  changeVal,
  previewInfo,
  premiumMask,
  loading,
  tabs,
  setTabs,
  setPreview,
  type,
  setType,
  setInfo,
  setSummary,
  months,
  info,
  summary,
  setPreviewInfo,
  setMode
}) {
  return (
    <div style={{ minWidth: "52rem" }} className="px-4 sm:px-6 lg:px-8">
      <div className="w-full w-lg text-left">
        <div className="flex w-100 pt-5">
          <div>
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-base font-bold leading-6 text-gray-900">
                  Good Day, Please enter premium amount below.
                </h1>
                <div className="mb-6 mt-3">
                  <div className="text-sm font-semibold leading-7 text-gray-900">
                    Insurance Premium:
                  </div>
                  <div className="text-sm text-gray-500">
                    Total premium to be financed, including tax.
                  </div>
                  <div
                    style={{ minWidth: "42rem" }}
                    className="mt-2 w-100 flex row"
                  >
                    <input
                      onChange={(e) => {
                        changeVal(parseFloat(e.target.value), "premium");
                      }}
                      step="0.01"
                      type="number"
                      name="premium"
                      id="premium"
                      style={{ minWidth: "20rem" }}
                      className="block w-9/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Enter premium"
                    />
                    <select
                      onChange={(e) => {
                        setType(e.target.value);
                        setPreviewInfo([]);
                        setPreview(null);
                      }}
                      name="type"
                      id="type"
                      style={{ minWidth: "20rem" }}
                      className="block w-9/12 ml-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Select Contract Type"
                    >
                      <option value={null}>Select A Loan Type</option>
                      <option value={"retail"}>Retail</option>
                      <option value={"commercial"}>Commercial</option>
                    </select>
                    <button
                      onClick={() => {
                        generate();
                      }}
                      type="button"
                      class="inline-flex ml-3 items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150 cursor-pointer"
                      disabled=""
                    >
                      Generate
                    </button>
                  </div>
                  <p>Premium Value: {premiumMask}</p>
                </div>
                {previewInfo.length !== 0 && (
                  <p className="mt-2 text-sm text-gray-700">
                    Below is a break down showing all the premium at all
                    available policy lengths.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        {loading && (
          <div class="relative rounded-xl overflow-auto p-8">
            <div class="flex items-center justify-center">
              <button
                type="button"
                class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed"
                disabled=""
              >
                <svg
                  class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Processing...
              </button>
            </div>
          </div>
        )}
      </>
      <hr className="mt-5" />
      <div className="max-h-50 flex justify-center"></div>
      <>
        {!loading && preview !== null &&(
          <div>
            <QuotationTabs
              tabs={tabs}
              setTabs={setTabs}
              setPreview={setPreview}
              previewInfo={previewInfo}
            />
            {preview !== null && <PreviewTable preview={preview} type={type} />}
          </div>
        )}
      </>
    </div>
  );
}
