import { useEffect, useState } from "react";
import Identicon from "react-identicons";
import Http from "../../../util/http";
import AccessControl from "../../../util/accessControl";
import Swal from "sweetalert2";

function isEmpty(value) {
  return value == null || value.length === 0;
}

export default function Profile() {
  const [user, setUser] = useState({});
  const [change, setChange] = useState(false);
  const [passwordInfo, setPasswordInfo] = useState({});
  useEffect(() => {
    let data = JSON.parse(localStorage.user);
    setUser(data);
  }, []);
  function SetField(value, key) {
    let data = { ...user };
    data[key] = value;
    setUser(data);
  }
  function ToggleChange(e, value) {
    if (value === false) {
      setPasswordInfo({});
    }
    setChange(value);
  }
  function save(e) {
    // e.preventDefault();
    try {
      let payload = { ...user };
      if (
        change &&
        !isEmpty(passwordInfo.old) &&
        !isEmpty(passwordInfo.new1) &&
        !isEmpty(passwordInfo.new2)
      ) {
        if (passwordInfo.new1 !== passwordInfo.new2) {
          alert("New passwords are not the same.");
          return;
        }
        console.log("sending now");
        payload["passwordInfo"] = passwordInfo;
      } else {
      }

      delete payload._id;
      Http.request("admin", "users/profile/update", payload)
        .then((response) => {
          localStorage.user = JSON.stringify(response);
          Swal.fire({
            icon: "success",
            title: "Hurray!",
            text: "Profile information updated successfully.",
          });
          window.location.href = window.location.origin + "/dashboard";
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Failed to make request to servers.",
          });
        });
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <div className="flex justify-center ">
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 w-1/2">
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
            <div className="mb-5">
              <h1 className="text-4xl text-center font-semibold  leading-6 text-gray-900">
                (Profile Information)
              </h1>
            </div>

            <div className="px-4 py-6 sm:p-8">
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="md:flex md:justify-center mb-6 sm:col-span-6">
                  <div>
                    <button
                      className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      id="headlessui-menu-button-:Rqam:"
                      type="button"
                      aria-haspopup="menu"
                      aria-expanded="false"
                      data-headlessui-state=""
                    >
                      <Identicon
                        bg={"gray"}
                        size="60"
                        className="rounded-full"
                        string={user._id}
                      />
                    </button>
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    First name
                  </label>
                  <div className="mt-2">
                    <input
                      value={user.firstName}
                      onChange={(e) => {
                        SetField(e.target.value, "firstName");
                      }}
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Last name
                  </label>
                  <div className="mt-2">
                    <input
                      value={user.lastName}
                      onChange={(e) => {
                        SetField(e.target.value, "lastName");
                      }}
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email
                  </label>
                  <div className="mt-2">
                    <input
                      value={user.email}
                      onChange={(e) => {
                        SetField(e.target.value, "email");
                      }}
                      type="email"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Telephone
                  </label>
                  <div className="mt-2">
                    <input
                      value={user.phoneNumber}
                      onChange={(e) => {
                        SetField(e.target.value, "phoneNumber");
                      }}
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <AccessControl
                permission={"saveProfile"}
                fallback={<></>}
                target={["god"]}
              >
                <button
                  onClick={(e) => {
                    save(e);
                  }}
                  type="submit"
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Save
                </button>
              </AccessControl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
