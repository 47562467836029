
import { useEffect,useState } from 'react';

export default function GeoTags({announcement,setAnnouncement}) {
    const [geos, setGeos] = useState([]);
    useEffect(() => {
        try {
            let _geos = JSON.parse(localStorage.getItem('countries'));
            console.log(_geos,announcement);
            for (let i = 0; i < _geos.length; i++) {
                if (announcement?.locations?.includes(_geos[i]._id)) {
                    const _g = _geos[i];
                    _g["active"] = true;
                }
            }
            
            setGeos(_geos);
        } catch (error) {
            console.log(error)
        }
    },[announcement])
    const addLocation = (geo,index) => {
        const _g = [...geos];
        _g[index].active = !_g[index].active;
        setGeos(_g);
        const locations = announcement?.locations || [];
        const isLocation = locations.findIndex(location => location === geo._id);
        console.log({isLocation});
        if(isLocation === -1){
            locations.push(geo._id);
            setAnnouncement({...announcement,locations});
        }else{
            locations.splice(isLocation, 1);
            setAnnouncement({...announcement, locations});
        }
    }
    return (
        <>
            {geos.map((geo, index) => {
                return (
                    <span onClick={()=>{addLocation(geo,index)}} key={index} className={`mx-2 inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200  cursor-pointer`}>
                        <svg viewBox="0 0 6 6" aria-hidden="true" className={`h-1.5 w-1.5 ${geo.active ? "fill-green-500" : "fill-red-500"}`}>
                            <circle r={3} cx={3} cy={3} />
                        </svg>
                        {geo.title}
                    </span>
                );
            })}
        </>
    )
}