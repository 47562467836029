import {useEffect, useState} from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Http from "../util/http";
import Swal from "sweetalert2";


export default function WYSIWYGEditor({id,setContent}){
    const [editorState,setEditorState] = useState(EditorState.createEmpty())
    useEffect(() => {
        if(typeof id !== "undefined"){
            Http.request('admin','notifications/get-one',{id}).then((response)=>{
                const contentBlock = htmlToDraft(response.content);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    setEditorState(EditorState.createWithContent(contentState));
                }else{
                    setEditorState(EditorState.createEmpty())
                }
            }).catch((e)=>{
                console.log({e})
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'WYSIWYG content was not loaded from server.',
                })
            })
        }
    }, [id])

    function onEditorStateChange(e){
        setEditorState(e);
        setContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }

    return (
        <div>
            <Editor
                wrapperClassName="wrapper-class"
                editorClassName={{ border: "1px solid" }}
                toolbarClassName="toolbar-class"
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
            />
        </div>
    )
}