
import { useState,useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function SignIn() {
    const [username,setUsername] = useState('')
    const [password,setPassword] = useState('')
    const [reason,setReason] = useState('')
    const [style,setStyle] = useState({
        width: '100%',
        height: '50px',
        backgroundColor: 'red'
    })
    async function login(event){
        
        event.preventDefault();

        try
        {
            let response = await axios.post(`${process.env.REACT_APP_IDENTITY_API}/api`,{target: "login",payload:{email: username.toLowerCase(),password:password}})
            if(response.data.error){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please check credentials',
                })
            }else{
                localStorage.setItem('token',response.data.token)
                localStorage.setItem('role',response.data.role)
                localStorage.setItem('organization',JSON.stringify(response.data.organization))
                localStorage.setItem('user',JSON.stringify(response.data.user))
                localStorage.removeItem('impersonate')
                window.location.href = window.location.origin + '/dashboard'
            }
 
        }catch(e){
            console.log(e)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please check credentials',
            })
        }
    }
    async function newIP(event){
        
        event.preventDefault();
        try
        {
            let response = await axios.post(`${process.env.REACT_APP_IDENTITY_API}/api`,{target: "newip",payload:{email: username,password:password}})
            if(response.data.error){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Error Adding IP.Please check credentials',
                })
            }else{
                Swal.fire({
                    icon: 'success',
                    title: 'Hurray!!',
                    text: 'IP Added.Please wait for approval from admin',
                })
                window.location.href = window.location.origin + '/signin'
                
            }
 
        }catch(e){
            console.log(e)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Error Adding IP.Please check credentials',
            })
        }
    }
    useEffect(()=>{
        if(window.location.search){
            var search = window.location.search.substring(1);
            let payload = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
    
            if(payload.reason){
                setReason(payload.reason.replace('+',' '))
                toast.error(payload.reason.replace('+',' ').toUpperCase(), {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        }
    },[])
    return (
        <>
            <ToastContainer />
            <div style={{height: '100vh'}} className="flex min-h-full">
            <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
                <div>
                <img
                        style={{boarderRadius: '50%',margin: 'auto'}}
                        className="h-20 w-30"
                        src="/banner.jpg"
                        alt="Solis Credit"
                      />
                <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Sign in to your account</h2>
                </div>

                <div className="mt-8">

                <div className="mt-6">
                    <div className="space-y-6">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Email address
                        </label>
                        <div className="mt-2">
                        <input
                            onChange={(e)=>{setUsername(e.target.value)}}
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        </div>
                    </div>

                    <div className="space-y-1">
                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                        Password
                        </label>
                        <div className="mt-2">
                        <input
                            onChange={(e)=>{setPassword(e.target.value)}} value={password}
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        </div>
                    </div>
                    
                    <div className="flex items-center justify-between">
                        <div className="text-sm">
                            <a href="/activate" className="font-medium text-indigo-600 hover:text-indigo-500">
                                Activate your account
                            </a>
                        </div>

                        <div className="text-sm">
                            <a href="/reset" className="font-medium text-indigo-600 hover:text-indigo-500">
                                Forgot your password?
                            </a>
                        </div>
                    </div>

                    <div>
                        <button
                        onClick={(e)=>{login(e)}}
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                        Sign in
                        </button>
                    </div>
                    {reason === 'invalid ip' &&
                        <div>
                            <button
                            onClick={(e)=>{newIP(e)}}
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                            >
                                Request IP Access
                            </button>
                        </div>

                    }
                    </div>
                </div>
                </div>
                <div className={"mt-2"}>
                    <div className={"font-bold mb-2"}>Your sign-in instructions are as follows:</div>
                    <ol className={"ml-3"}>
                        <li className={"mb-2"}>1. You will be sent an email with an activation code.If you dont see this
                            email inside your inbox please check the spam folder.
                        </li>
                        <li className={"mb-2"}>2. Inside the email, there is a link that you should click this link will
                            take you to the platform where you should enter the activation code.
                        </li>
                        <li className={"mb-2"}>3. After entering the code, you will be prompted to enter your new
                            password twice.
                        </li>
                        <li className={"mb-2"}>4. After this, you can now sign into the platform using your email
                            address and password.
                        </li>
                        <li className={"mb-2 text-red-700"}>5. If your token has expired or is not working you can you the forgot password to request another.
                        </li>
                    </ol>
                </div>
            </div>
            </div>
                <div className="relative hidden w-0 flex-1 lg:block">
            <img
                className="absolute inset-0 h-full w-full object-cover"
                src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                alt=""
            />
            </div>
            </div>
        </>
    )
}
