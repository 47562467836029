import {FormatNumber} from "../../util/util";

export default function SlimSchedule({summary,type}){


    return (
        <>


            {summary.data.length > 0 && (
                <div style={{ minWidth: "750px" }} className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                        >
                                            Payment Date
                                        </th>
                                        {type !== "retail" &&
                                            <>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Principal
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Interest
                                                </th>
                                            </>

                                        }
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Total Instalment
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                        Balance
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                    {summary.data[0]?.dueDate !== "Invalid date" && (
                                        <>
                                            {summary.data.map((record, index) => (
                                                <tr key={index}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                        {record.dueDate}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        $
                                                        {FormatNumber(
                                                            parseFloat(record.instalment).toFixed(2)
                                                        )}
                                                    </td>
                                                    {type !== "retail" &&
                                                        <>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                $
                                                                {FormatNumber(
                                                                    parseFloat(record.interest).toFixed(2)
                                                                )}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                $
                                                                {FormatNumber(
                                                                    parseFloat(record.instalment).toFixed(2)
                                                                )}
                                                            </td>
                                                        </>

                                                    }
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        $
                                                        {FormatNumber(
                                                            parseFloat(record.balance).toFixed(2)
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}