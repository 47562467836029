import ApprovalModal from "../../components/dashboard/approvallog";
import { useState, useEffect } from "react";

export default function ApprovalList({ contracts }) {
  const [open, setOpen] = useState(false);
  const [contract, setContract] = useState(null);
  const statuses = {
    awaitingCancellation: "text-yellow-500 bg-yellow-50 ring-yellow-400/20",
    writtenOff: "text-gray-600 bg-gray-50 ring-gray-500/10",
    late: "text-red-700 bg-red-50 ring-red-600/10",
    cancelled: "text-red-700 bg-red-200 ring-red-600/10",
    paidUp: "text-teal-700 bg-teal-200 ring-teal-600/10",
    commercial: "text-cyan-700 bg-cyan-200 ring-cyan-600/10",
    retail: "text-teal-700 bg-teal-200 ring-teal-600/10",
    current: "text-green-700 bg-green-200 ring-green-600/10",
    paidOff: "text-cyan-700 bg-cyan-200 ring-cyan-600/10",
    //current: 'text-white bg-green-500 ring-green-900/10',
    pendingApproval: "text-yellow-700 bg-yellow-200 ring-yellow-600/10",
    approved: "text-green-700 bg-green-200 ring-green-600/10",
  };

  const fields = [
    {
      title: "Company Name",
      key: "meta.insuranceDetails.company_name",
      sorting: false,
    },
    { title: "Loan Number", key: "loan", sorting: false },
    { title: "Client", key: "name", sorting: false },
    { title: "Agent", key: "name", sorting: false },
    { title: "Type", key: "", sorting: false },
    { title: "Status", key: "status", sorting: false },
    { title: "Policy Number", key: "policyNum", sorting: false },
    { title: "Installments", key: "premium", sorting: false },
    { title: "Months", key: "premium", sorting: false },
    { title: "Premium Financed", key: "premium", sorting: false },
    {
      title: "Total Due",
      key: "loanData.meta.insuranceDetails.insurance_total_premium",
      sorting: false,
    },
    { title: "Action", key: "status", sorting: false },
  ];
  function nullTransform(val) {
    if (val === null || val === undefined) {
      return "";
    }
    return val;
  }
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  function FormatNumber(str) {
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function openModal(contract) {
    setContract(contract);
    setOpen(true);
  }
  return (
    <>
      <ApprovalModal open={open} setOpen={setOpen} contract={contract} setContract={setContract} />
      <div className="px-4 sm:px-6 lg:px-8 my-5">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      {fields.map((field, index) => {
                        return (
                          <th
                            key={index}
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6"
                          >
                            {field.title}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {contracts.map((contract, index) => (
                      <tr
                        // onClick={() => openLoan(contract)}
                        key={index}
                        className="bg-white cursor-pointer w-100"
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {nullTransform(
                            JSON.stringify(
                              contract?.meta?.insuranceDetails?.company_name ||
                                ""
                            ).replace(/^"(.*)"$/, "$1") || ""
                          )}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {nullTransform(contract?.loanNumber || "")}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {nullTransform(
                            contract?.client?.personal?.fullName || ""
                          )}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {nullTransform(contract?.createdBy?.fullName || "")}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <div
                            className={classNames(
                              statuses[contract?.purpose?.toLowerCase()],
                              "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset flex justify-center"
                            )}
                          >
                            <div>
                              {nullTransform(
                                contract?.purpose?.toLowerCase() || ""
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <div
                            className={classNames(
                              statuses[contract?.status],
                              "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset flex justify-center"
                            )}
                          >
                            <div>{nullTransform(contract?.status || "")}</div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {nullTransform(
                            contract?.meta?.insuranceDetails?.policy_number ||
                              ""
                          )}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          $
                          {nullTransform(
                            FormatNumber(
                              parseFloat(
                                contract?.instalment ||
                                  contract?.amortisation[0].instalment
                              ).toFixed(2) || "0"
                            )
                          )}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {nullTransform(contract?.time || "")}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          $
                          {nullTransform(
                            FormatNumber(
                              parseFloat(contract?.principal).toFixed(2) || "0"
                            )
                          )}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          $
                          {nullTransform(
                            FormatNumber(
                              parseFloat(contract?.balance?.total || 0).toFixed(
                                2
                              ) || "0"
                            )
                          )}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <span onClick={()=>{openModal(contract)}} className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                            Open Log
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
