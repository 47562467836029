/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  setDeepValue,
  getDeepValue,
} from "../../../../../util/util";
import http from "../../../../../util/http";
import React, { useEffect, useState } from "react";
import swal from "sweetalert2";

import {useRef } from "react";
import Swal from "sweetalert2";
import RetailContractsListView from "../../../../../components/dashboard/RetailContractsListView";
import RetailContractView from "../../../../../components/dashboard/RetailContractView";
import RetailClientContactView from "../../../../../components/dashboard/RetailClientContactView";
import RetailClientDetailsView from "../../../../../components/dashboard/RetailClientDetailsView";
import RetailClientEditModal from "../../../../../components/dashboard/RetailClientEditModal";
import RetailClientHeader from "../../../../../components/dashboard/RetailClientHeader";
export default function RetailProfile() {
  const [user, setUser] = useState({});
  const [user2, setUser2] = useState({});
  const [contact, setContact] = useState({});
  const [contact2, setContact2] = useState({});
  const [address, setAddress] = useState({});
  const [address2, setAddress2] = useState({});
  const [employment, setEmployment] = useState({});
  const [employment2, setEmployment2] = useState({});
  const [payment2, setPayment2] = useState({});
  const cancelButtonRef = useRef(null);
  const [curAction, setCurAction] = useState("");
  const [curField, setCurField] = useState("");
  const [payment, setPayment] = useState({});
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [current, setCurrent] = useState(0);
  const [contracts, setContracts] = useState(0);
  const [clientNumber, setClientNumber] = useState(0);
  const [tempIdType, setTempIdType] = useState(0);
  const [tempId, setTempId] = useState("");
  const [issuedDate, setIssuedDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [countries2, setCountries2] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [dirty, setDirty] = useState(false);
  const [show, setShow] = useState(false);
  const [contractView, setContractView] = useState(false);
  const [page, setPage] = useState(1);
  const [curCountry, setCurCountry] = useState({});
  const [countries, setCountries] = useState([]);
  const [currentContract, setCurrentContract] = useState({});

  const [link, setLink] = useState("");


  const [tabs, setTabs] = useState([
    { name: "Personal Details", current: true },
    { name: "Contact", current: false },
    { name: "Contracts", current: false },
  ]);
  const [politicallyExposed, setPoliticallyExposed] = useState(false);
  const [mortgage, setMortgage] = useState(false);

  function selectTab(index) {
    console.log(index);
    let tempTabs = tabs;
    tempTabs[index].current = true;
    tempTabs[current].current = false;
    setTabs(tempTabs);
    setCurrent(index);
  }

  function deepEqual(obj1, obj2) {
    if (obj1 === obj2) {
      return false;
    }

    if (
      typeof obj1 !== "object" ||
      typeof obj2 !== "object" ||
      obj1 === null ||
      obj2 === null
    ) {
      return true;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return true;
    }

    for (const key of keys1) {
      if (!keys2.includes(key)) {
        return true;
      }

      if (!deepEqual(obj1[key], obj2[key])) {
        return true;
      }
    }

    return false;
  }

  async function getContracts() {
    let url = window.location.href.split("/");

    try {
      let contracts = await http.request(
        "contracts",
        "contracts/clients/retail/get-contracts",
        {
          perPage: 5,
          page,
          clientId: url[url.length - 1],
        }
      );

      if (contracts.contracts.length > 0) {
        setContracts(contracts.contracts);
      }
    } catch (error) {
      console.log(error);
      swal.fire(
        "Oops",
        "An error has occurred trying to get contracts",
        "error"
      );
    }
  }


  async function getClient() {
    let dialog = swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        swal.showLoading();
      },
      willClose: () => {},
    });
    let url = window.location.href.split("/");

    let countries3 = await http.request("admin", "countries");
    setCountries2(countries3);

    let countryId = JSON.parse(localStorage.getItem("geolocation"))._id;
    let curCountry = countries3.filter(
      (country) => country._id == countryId
    )[0];
    setCurCountry(curCountry);

    let userData = await http.request("contracts", "clients/retail/get-one", {
      id: url[url.length - 1],
    });
    dialog.close();
    if (userData.error != null) {
      swal.fire("Oops", "An error has occured", "error");
    } else {
      console.log(userData);
      setUserInfo(userData);
      setUser(userData.personal);
      setUser2(userData.personal);
      setAddress(userData.address);
      setAddress2(userData.address);
      setContact(userData.contact);
      setContact2(userData.contact);
      setEmployment(userData.employment);
      setEmployment2(userData.employment);
      setPayment(userData.accounts);
      setPayment2(userData.accounts);
      setPoliticallyExposed(userData.personal.politicallyExposed);
      setMortgage(userData.personal.mortgage);
      setClientNumber(userData.clientNumber);
      let country = countries3.filter(
        (item) => item.code == userData.address.addressCountry
      )[0];

      setTempId(userData.personal.id);
      setTempIdType(userData.personal.idType);
      setExpiryDate(userData.personal.expiryDate);
      setIssuedDate(userData.personal.issuedDate);
    }
  }

  function setKey(key, value) {
    let object = { ...user };

    setDeepValue(object, value, key);
    setUser(object);
    setDirty(deepEqual(user, user2));
  }

  function setKeyContact(key, value) {
    let object = { ...contact };

    setDeepValue(object, value, key);
    setContact(object);
    setDirty(deepEqual(contact, contact2));
  }

  function setKeyAddress(key, value) {
    let object = { ...address };

    setDeepValue(object, value, key);
    setAddress(object);
    setDirty(deepEqual(address, address2));
  }

  function setStatus(key, value) {
    let object = { ...userInfo };

    setDeepValue(object, value, key);
    setUserInfo(object);
  }

  function setKeyEmployment(key, value) {
    let object = { ...employment };

    setDeepValue(object, value, key);
    setEmployment(object);
    setDirty(deepEqual(employment, employment2));
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function getContract(id) {
    if (id !== "" && id !== null && id !== undefined) {
      let c = contracts.filter((item) => item._id == id)[0];
      console.log({ c });
      setCurrentContract(c);
      genLink(c);
    } else {
      setContractView(false);
    }
  }

  function genLink(c) {
    let base = `https://portal.yoozit.today/quickpay/1/${getDeepValue(
      c,
      "loan.loanNumber"
    )}/0/${c.email}`;

    let geo = JSON.parse(localStorage.getItem("geolocation"));
    if (geo.code === "grd" || geo.code === "lca") {
      base = "https://dtu.linkuptt.com/linkupv2/index.php/welcome";
    }
    setLink(base);
  }
  async function cancelLoan(id) {
    try {
      Swal.fire({
        title: "Attention!",
        text: "Are you sure you want to cancel this loan\n!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, send it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let response = await http.request(
            "contracts",
            `contracts/cancel/${id}`
          );
          Swal.fire(
            "Success!",
            "Loan has been cancelled successfully.",
            "success"
          ).then(() => {
            window.location.reload();
          });
        }
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to cancel loan!",
      });
    }
  }

  function back() {
    if (dirty == true) {
      swal
        .fire({
          title: "Are you sure? You are leaving the page without saving.",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Leave Page",
        })
        .then((result) => {
          if (result.isConfirmed) {
            window.history.back();
          }
        });
    } else {
      window.history.back();
    }
  }

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  async function saveForm() {
    if (isValidEmail(contact.email)) {
      console.log("Valid email:", contact.email);
    } else {
      console.log("Invalid email:", contact.email);
      swal.fire({
        title: "Oops",
        text: "Please check the email address.",
        icon: "info",
        showConfirmButton: true,
      });
      return;
    }

    let dialog = swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        swal.showLoading();
      },
      willClose: () => {},
    });
    let url = window.location.href.split("/");

    const selectdDay = new Date(user.expiryDate).setHours(0, 0, 0, 0);
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    user["politicallyExposed"] = politicallyExposed;
    user["mortgage"] = mortgage;
    let retail = {
      status: userInfo.status,
      personal: user,
      address,
      employment,
      contact,
      accounts: payment,
      type: "retail",
      docId: url[url.length - 1],
    };
    await await http
      .request(`contracts`, "clients/retail/update-one", {
        id: window.location.href.split("/")[url.length - 1],
        retail,
      })
      .then((result) => {
        dialog.close();
        if (result.error) {
          swal.fire("Oops", "An error has occured", "error");
        } else {
          swal.fire("Client Information saved!", "", "success");
          setDirty(false);
        }
      })
      .catch((e) => {
        Swal.fire("Oops", "Failed to save client information", "error");
      });
    setOpen(false);
  }

  async function sendEmailInvite() {
    try {
      let url = window.location.href.split("/");
      let response = await http.request(
        "contracts",
        `contracts/clients/verify/email`,
        { id: `${window.location.href.split("/")[url.length - 1]}` }
      );
      swal.fire(
        "Hurray!",
        "Verification code has been sent to the clients email",
        "success"
      );
    } catch (e) {
      console.log(e);
      swal.fire("Oops", "Failed to send email verification code.", "error");
    }
  }

  useEffect(() => {
    getClient();
    setKeyAddress(
      "addressCountry",
      JSON.parse(localStorage.getItem("geolocation")).code
    );
    getContracts();
  }, []);

  return (
    <div className="flex justify-center ">
      <RetailClientEditModal
          curAction={curAction}
          curField={curField}
          open={open}
          setOpen={setOpen}
          user={user}
          setKey={setKey}
          address={address}
          setKeyAddress={setKeyAddress}
          employment={employment}
          setKeyEmployment={setKeyEmployment}
          contact={contact}
          setKeyContact={setKeyContact}
          userInfo={userInfo}
          setStatus={setStatus}
          saveForm={saveForm}
          getClient={getClient}
          cancelButtonRef={cancelButtonRef}
      />
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 w-full">
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
            <h1 className="text-4xl text-center font-semibold  leading-6 text-gray-900">
              Retail Client Profile
            </h1>
            <hr />
            <RetailClientHeader
                user={user}
                address={address}
                contact={contact}
                userInfo={userInfo}
                clientNumber={clientNumber}
                setOpen={setOpen}
                setCurField={setCurField}
                setCurAction={setCurAction}
                sendEmailInvite={sendEmailInvite}
            />

            <div className="border-b border-gray-200 pb-5 sm:pb-0 p-5">
              <div className="mt-3 sm:mt-4">
                <div className="sm:hidden">
                  <label htmlFor="current-tab" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="current-tab"
                    name="current-tab"
                    className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    defaultValue={tabs[current].name}
                    onChange={(e) => selectTab(e.target.value)}
                  >
                    {tabs.map((tab, index) => (
                      <option value={index}>{tab.name}</option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <nav className="-mb-px flex space-x-8">
                    {tabs.map((tab, index) => (
                      <a
                        key={tab.name}
                        onClick={() => selectTab(index)}
                        style={{ cursor: "pointer" }}
                        className={classNames(
                          tab.current
                            ? "border-indigo-500 text-indigo-600"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
                        )}
                        aria-current={tab.current ? "page" : undefined}
                      >
                        {tab.name}
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
            </div>

            <div className="pl-5 pr-5 pb-5">
              {current == 0 && (
                  <RetailClientDetailsView
                      setKey={setKey}
                      user={user}
                      setOpen={setOpen}
                      setCurField={setCurField}
                      setCurAction={setCurAction}
                      curCountry={curCountry}
                      countries2={countries2}
                      editing={editing}
                      setPoliticallyExposed={setPoliticallyExposed}
                      setKeyAddress={setKeyAddress}
                      address={address}
                      employment={employment}
                      politicallyExposed={politicallyExposed}
                      setKeyEmployment={setKeyEmployment}
                  />
              )}

              {current == 1 && (
                <RetailClientContactView
                  contact={contact}
                  setKeyContact={setKeyContact}
                  setCurAction={setCurAction}
                  setCurField={setCurField}
                  setOpen={setOpen}
                />
              )}

              {current == 2 && !contractView && (
                <RetailContractsListView
                  contracts={contracts}
                  setContractView={setContractView}
                  setCurrentContract={setCurrentContract}
                />
              )}

              {current == 2 && contractView && (
                <RetailContractView
                  contract={currentContract}
                  contracts={contracts}
                  setContractView={setContractView}
                  link={link}
                  getContract={getContract}
                  cancelLoan={cancelLoan}
                />
              )}
            </div>
          </div>
          <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
            <button
              type="button"
              onClick={back}
              className="text-sm font-semibold leading-6 text-gray-900 mx-3"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
