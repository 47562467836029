import {useState, useEffect} from 'react'
import {setDeepValue,formatPhoneNumber} from '../../../../util/util'
import Http from '../../../../util/http'
import AccessControl from '../../../../util/accessControl'
import Swal from 'sweetalert2'
import TelephoneInput from '../../../../components/dashboard/telephoneInput'
import swal from "sweetalert2";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function CommercialClientCreate() {

    const [commUser, setCommUser] = useState({
        businessPhoneNumberOne:'',
        businessPhoneNumberTwo:'',
        businessFaxNumber:'',
        businessEmailAddress:'',
        businessName: '',
        businessRegistrationNumber:'',
        businessClientCode:'',
        businessAddressFirstLine:'',
        businessAddressSecondLine:'',
        businessAddressCity:'',
        businessAddressState:'',
        businessAddressCountry:'',
        businessDescription: '',
        authorizerFirstName: '',
        authorizerLastName: '',
        paymentInfoType:'',
        paymentInfoBankName:'',
        paymentInfoAccountType:'',
        paymentInfoAccountNumber:''
    });
    const [isBank, setIsBank] = useState(false);
    const [defaultCountry, setDefaultCountry] = useState("jm");
    const [countries, setCountries] = useState([]);


    useEffect(() => {
        Http.request('admin', 'countries',[]).then((response) => {
            setCountries(response);
            let geo = JSON.parse(localStorage.getItem('geolocation')).code
            setKey('businessAddressCountry', geo)
        });
    }, [])

    function setKey(key, value) {
        let object = {...commUser}

        setDeepValue(object, value, key)
        setCommUser(object)
    }
    async function validateClient(email,phone){
        try{
          let response = await Http.request("contracts", "clients/verify",{email: email,phone,type: "commercial"});
          console.log({response});
          if(response.id){
            return {isValid: false,id: response.id};
          }else{
            return {isValid: true};
          }
        }catch(e){
          console.log(e);
          Swal.fire("Oops", "An error has occurred validating user emails", "error");
          return {isValid: false};
        }
      }

    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    async function save(e) {
        e.preventDefault();
        if (isValidEmail(commUser.businessEmailAddress)) {
            console.log('Valid email:', commUser.businessEmailAddress);
        } else {
            console.log('Invalid email:', commUser.businessEmailAddress);
            Swal.fire({
                title: 'Oops',
                text: 'Please check the email address.',
                icon: 'info',
                showConfirmButton: true
            })
            return
        }
        let result = await validateClient(commUser.businessEmailAddress,commUser.businessPhoneNumberOne);
        if(result.isValid === false && result.id === undefined){
            return
        }

        if(result.isValid === false){
            Swal.fire({
                title: 'Attention!',
                text: "A client has been found with this email address or telephone number. Would you like to view this client?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes!',
                cancelButtonText: 'No, cancel!',
            }).then((opt)=>{
                if(opt.isConfirmed){
                  window.location.href = `${window.location.origin}/dashboard/clients/commercial/profile/${result.id}`;
                }
            })
          return;
        }
        let dialog = Swal.fire({
            title: 'Loading...',
            timerProgressBar: true,
            allowOutsideClick: false, 
            didOpen: () => {
              Swal.showLoading()
            },
            willClose: () => {
            }
          })
        e.preventDefault();
        Http.request('contracts', 'client/commercial/create', commUser).then((response) => {
            Swal.fire({
                icon: 'success',
                title: 'Hurray!',
                text: 'Commercial client account created successfully',
            }).then(() => {
                dialog.close()
                let search = window.location.search.substring(1);
                search = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
                if(search.state === 'new'){
                    window.location = `${window.location.origin}/dashboard/contracts/commercial/create?id=${response._id}`
                }else{
                    window.location.href = `${window.location.origin}/dashboard/clients/commercial/profile/${response._id}`;
                }
            });
        }).catch((err) => {
            dialog.close()
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err || 'Failed to make request to servers.',
            })
        })
    }

    return (
        <div className="flex justify-center ">
            <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 w-full">
                <form onSubmit={(e) => {
                    save(e)
                }}>
                    <div className="shadow sm:overflow-hidden sm:rounded-md">
                        <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
                            <h1 className="text-4xl text-center font-semibold  leading-6 text-gray-900">
                                New Commercial Client
                            </h1>
                            <hr/>
                            <div className="p-7">
                                <div>
                                    <h2 className="text-2xl">Business and Officer</h2>
                                </div>

                                <div className="grid grid-cols-4 gap-4">
                                    <div className="col-span-1 sm:col-span-1">
                                        <input
                                            required
                                            type="text"
                                            onChange={(e) => {
                                                setKey('businessName', e.target.value)
                                            }}
                                            value={commUser.businessName}
                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                            Business Name
                                        </label>
                                    </div>
                                    <div className="col-span-1 sm:col-span-1">
                                        <input
                                            required
                                            type="text"
                                            onChange={(e) => {
                                                setKey('authorizerFirstName', e.target.value)
                                            }}
                                            value={commUser.authorizerFirstName}
                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                            Authorized Officer First Name
                                        </label>
                                    </div>
                                    <div className="col-span-1 sm:col-span-1">
                                        <input
                                            required
                                            type="text"
                                            onChange={(e) => {
                                                setKey('authorizerLastName', e.target.value)
                                            }}
                                            value={commUser.authorizerLastName}
                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                            Authorized Officer Last Name
                                        </label>
                                    </div>
                                    <div className="col-span-1 sm:col-span-1">
                                        <input
                                            required
                                            type="text"
                                            onChange={(e) => {
                                                setKey('businessRegistrationNumber', e.target.value)
                                            }}
                                            value={commUser.businessRegistrationNumber}
                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                            Registration Number
                                        </label>
                                    </div>
                                </div>
                            </div>

                           {/* <div className="p-7">
                                <div>
                                    <h2 className="text-2xl">Access Codes</h2>
                                </div>

                                <div className="grid grid-cols-4 gap-4">
                                    <div className="col-span-1 sm:col-span-1">
                                        <input
                                            required
                                            type="text"
                                            onChange={(e) => {
                                                setKey('businessRegistrationNumber', e.target.value)
                                            }}
                                            value={commUser.businessRegistrationNumber}
                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                            Registration Number
                                        </label>
                                    </div>
                                    <div className="col-span-1 sm:col-span-1">
                                        <input
                                            required
                                            type="text"
                                            onChange={(e) => {
                                                setKey('businessClientCode', e.target.value)
                                            }}
                                            value={commUser.businessClientCode}
                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                            Client Code
                                        </label>
                                    </div>
                                </div>
                            </div>*/}

                            <div className="p-7">
                                <div>
                                    <h2 className="text-2xl">Contact Info</h2>
                                </div>

                                <div className="grid grid-cols-3 gap-4">
                                    <div className="col-span-1 sm:col-span-1">
                                        <input
                                            required
                                            type="text"
                                            onChange={(e) => {
                                                setKey('businessEmailAddress', e.target.value)
                                            }}
                                            value={commUser.businessEmailAddress}
                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                            Email Address
                                        </label>
                                    </div>
                                </div>
                                <div className="grid grid-cols-3 gap-4 mt-5">
                                    <div className="col-span-1 sm:col-span-1">
                                        <TelephoneInput isRequired={true} setKey={setKey} path={'businessPhoneNumberOne'} value={commUser.businessPhoneNumberOne} className={"mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"}/>
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                            Business Phone Number (1)
                                        </label>
                                    </div>
                                    <div className="col-span-1 sm:col-span-1">
                                        <TelephoneInput setKey={setKey} path={'businessPhoneNumberTwo'} value={commUser.businessPhoneNumberTwo} className={"mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"}/>
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                            Business Phone Number (2)
                                        </label>
                                    </div>
                                    <div className="col-span-1 sm:col-span-1">
                                        
                                        <input
                                            type="text"
                                            onChange={(e) => {
                                                setKey('businessFaxNumber', e.target.value)
                                            }}
                                            value={commUser.businessFaxNumber}
                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                            Business Phone Number (Fax)
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="p-7">
                                <div>
                                    <h2 className="text-2xl">Address</h2>
                                </div>

                                <div className="grid grid-cols-3 gap-4">
                                    <div className="col-span-1 sm:col-span-1">
                                        <input
                                            required
                                            type="text"
                                            onChange={(e) => {
                                                setKey('businessAddressFirstLine', e.target.value)
                                            }}
                                            value={commUser.businessAddressFirstLine}
                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                            Address - First Line
                                        </label>
                                    </div>
                                    <div className="col-span-1 sm:col-span-1">
                                        <input
                                            type="text"
                                            onChange={(e) => {
                                                setKey('businessAddressSecondLine', e.target.value)
                                            }}
                                            value={commUser.businessAddressSecondLine}
                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                            Address - Second Line
                                        </label>
                                    </div>
                                    <div className="col-span-1 sm:col-span-1">
                                        <input
                                            required
                                            type="text"
                                            onChange={(e) => {
                                                setKey('businessAddressCity', e.target.value)
                                            }}
                                            value={commUser.businessAddressCity}
                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                            Address - City or Town
                                        </label>
                                    </div>
                                    <div className="col-span-1 sm:col-span-1">
                                        <input
                                            required
                                            type="text"
                                            onChange={(e) => {
                                                setKey('businessAddressState', e.target.value)
                                            }}
                                            value={commUser.businessAddressState}
                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                            Address - State or Province
                                        </label>
                                    </div>
                                    {/* <div className="col-span-1 sm:col-span-1">
                                        <select
                                            required
                                            id="current-tab"
                                            name="current-tab"
                                            onChange={(e) => {
                                                setKey('businessAddressCountry',e.target.value)
                                            }}
                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        >
                                            <option value=""> Select a Country </option>
                                            {countries.map(item => <option key={item.code} value={item.code}> {item.title} </option> )}
                                        </select>
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                            Address - Country
                                        </label>
                                    </div> */}
                                </div>
                            </div>

                            <div className="p-7">
                                <div>
                                    <h2 className="text-2xl">Business Description</h2>
                                </div>

                                <div className="grid grid-cols-1 gap-4">
                                    <div className="col-span-1 sm:col-span-1">
                                        <textarea
                                            required
                                            rows="10"
                                            onChange={(e) => {
                                                setKey('businessDescription', e.target.value)
                                            }}
                                            value={commUser.businessDescription}
                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>

                           {/* <div className="p-7">
                                <div>
                                    <h2 className="text-2xl">Payment Information</h2>
                                </div>

                                <div className="grid grid-cols-3 gap-4">
                                    <div className="col-span-1 sm:col-span-1">
                                        <select
                                            required
                                            id="type"
                                            name="type"
                                            autoComplete="type"
                                            onChange={(e) => {
                                                setIsBank((e.target.value === "bank"));
                                                setKey('paymentInfoType', e.target.value)
                                            }}
                                            className="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        >
                                            <option value=""> Select a Payment Type</option>
                                            <option value="cash"> Cash </option>
                                            <option value="bank"> Bank </option>
                                        </select>
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                            Payment Type
                                        </label>
                                    </div>
                                    {isBank && (
                                        <div className="col-span-1 sm:col-span-1">
                                            <input
                                                required
                                                type="text"
                                                onChange={(e) => {
                                                    setKey('paymentInfoBankName', e.target.value)
                                                }}
                                                value={commUser.paymentInfoBankName}
                                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                                Bank Name
                                            </label>
                                        </div>
                                    )}
                                </div>
                                {isBank &&(
                                    <div className="grid grid-cols-3 gap-4 mt-5">
                                        <div className="col-span-1 sm:col-span-1">
                                            <input
                                                required
                                                type="text"
                                                onChange={(e) => {
                                                    setKey('paymentInfoAccountNumber', e.target.value)
                                                }}
                                                value={commUser.paymentInfoAccountNumber}
                                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                                Bank Account Number
                                            </label>
                                        </div>
                                        <div className="col-span-1 sm:col-span-1">
                                            <select
                                                required
                                                id="type"
                                                name="type"
                                                autoComplete="type"
                                                onChange={(e) => {
                                                    setKey('paymentInfoAccountType', e.target.value)
                                                }}
                                                className="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            >
                                                <option value="savings"> Savings </option>
                                                <option value="chequering"> Chequings </option>
                                            </select>
                                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                                Account Type
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </div>*/}
                        </div>
                        <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
                            <a href='/dashboard/clients' type="button"
                               className="text-sm font-semibold leading-6 text-gray-900 mx-3">Cancel</a>
                            <AccessControl permission={'addCommercialClient'} fallback={<></>} target={['god']}>
                                <button
                                    type="submit"
                                    className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Save & Continue
                                </button>
                            </AccessControl>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}


