/* eslint-disable jsx-a11y/no-redundant-roles */
import {
  nullTransform,
  getDeepValue,
  FormatNumber,
  getStatus,
} from "../../../util/util";
import { CurrencyDollarIcon } from "@heroicons/react/20/solid";
import DetailedSchedule from "../DetailedSchedule";
import AccessControl from "../../../util/accessControl";
import ViewContract from "../viewContract";

export default function CommercialContractDetails({
  contracts,
  selectedContract,
  openContractDetailsPage,
  link,
  cancelLoan,
}) {
  return (
    <div className="container">
      <div className="grid grid-cols-6 mb-10">
        <div className="col-span-1">
          <p className="text-center align-text-middle">Select a Contract:</p>
        </div>
        <div className="col-span-3">
          <select
            required
            id="current-tab"
            name="current-tab"
            value={selectedContract._id}
            onChange={(e) => {
              openContractDetailsPage(e.target.value);
            }}
            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option value="" style={{ textAlign: "center" }}>
              {" "}
              Select Contract{" "}
            </option>
            {contracts.map((contract) => (
              <option
                key={contract._id}
                value={contract._id}
                style={{ textAlign: "center" }}
              >
                {" "}
                {nullTransform(
                  getDeepValue(contract, "loan.loanNumber") || ""
                )}{" "}
                - {contract?.loan?.meta?.insuranceDetails?.company_name}{" "}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex w-11/12 my-5">
        <div className="w-4/12">
          <p className="text-2xl font-bold">
            Loan ID:{" "}
            {nullTransform(
              getDeepValue(selectedContract, "loan.loanNumber") || ""
            )}
          </p>
        </div>
        <div className="flex w-4/12">
          <div className="w-4/12"></div>
          <div className="w-4/12">
            <span
              className={
                getStatus(selectedContract.loan.status).style +
                " justify-self-center"
              }
            >
              {getStatus(selectedContract.loan.status).name}
            </span>
          </div>
          <div className="w-4/12"></div>
        </div>
        <div className="w-4/12">
          <span className="isolate inline-flex rounded-md shadow-sm">
            <a
              target="_blank"
              href={link}
              className="relative -ml-px inline-flex items-center bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
              rel="noreferrer"
            >
              <CurrencyDollarIcon
                className="-ml-0.5 h-5 w-5"
                aria-hidden="true"
              />
              Apply Payment
            </a>
            {["current", "late"].includes(selectedContract?.loan?.status) && (
              <AccessControl
                permission={"instantCancelLoan"}
                fallback={<></>}
                target={["god"]}
              >
                <button
                  onClick={() => {
                    cancelLoan(selectedContract?.loan?.loanNumber);
                  }}
                  type="button"
                  className="relative -ml-px inline-flex items-center bg-red-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-500 focus:z-10"
                >
                  Cancel
                </button>
              </AccessControl>
            )}
            <ViewContract contract={selectedContract} />
          </span>
        </div>
      </div>

      <div className="grid grid-cols-12 mb-10">
        <div className="col-span-4">
          <div className="mb-5">
            <h2 className="text-xl text-cyan-900 font-bold">
              Insurance Company:
            </h2>
            <div className="grid grid-cols-2 font-extralight">
              <p>
                {selectedContract?.loan?.meta?.insuranceDetails?.company_name}
              </p>
            </div>
          </div>
          <div className="mb-5">
            <h2 className="text-xl text-cyan-900 font-bold">
              Total Premium Financed:
            </h2>
            <div className="grid grid-cols-2 font-extralight">
              <p>
                $
                {FormatNumber(
                  parseFloat(selectedContract?.loan?.principal || 0).toFixed(2)
                )}
              </p>
            </div>
          </div>
          <div className="mb-2">
            <h2 className="text-xl text-cyan-900 font-bold">Current Balance</h2>
            <div className="grid grid-cols-2 font-extralight">
              <p>
                $
                {FormatNumber(
                  nullTransform(
                    parseFloat(
                      selectedContract?.loan?.balance?.total || 0
                    ).toFixed(2)
                  )
                )}
              </p>
            </div>
          </div>
          <div className="mb-5">
            <h2 className="text-xl text-cyan-900 font-bold">Disbursed At:</h2>
            <div className="grid grid-cols-2 font-extralight">
              <p>{selectedContract?.loan?.disbursedAt?.substring(0, 10)}</p>
            </div>
          </div>
          {selectedContract.loan.status === "late" && (
            <div className="mb-5">
              <h2 className="text-xl text-cyan-900 font-bold">Late Days</h2>
              <div className="grid grid-cols-2 font-extralight">
                <p>{selectedContract?.loan?.lateDays} Days</p>
              </div>
            </div>
          )}
        </div>
        <div className="col-span-4">
          <div className="mb-5">
            <h2 className="text-xl text-cyan-900 font-bold">
              Policy Start Date:
            </h2>
            <div className="grid grid-cols-2 font-extralight">
              <p>{selectedContract.policyStartDate.substring(0, 10)}</p>
            </div>
          </div>
          <div className="mb-5">
            <h2 className="text-xl text-cyan-900 font-bold">
              Policy End Date:
            </h2>
            <div className="grid grid-cols-2 font-extralight">
              <p>{selectedContract.policyEndDate.substring(0, 10)}</p>
            </div>
          </div>
          <div className="mb-5">
            <h2 className="text-xl text-cyan-900 font-bold">Disbursed At:</h2>
            <div className="grid grid-cols-2 font-extralight">
              <p>{selectedContract?.loan?.disbursedAt?.substring(0, 10)}</p>
            </div>
          </div>
          <div className="mb-2">
            <h2 className="text-xl text-cyan-900 font-bold">Arrears Due:</h2>
            <div className="grid grid-cols-2 font-extralight">
              <p>
                $
                {FormatNumber(
                  parseFloat(
                    selectedContract?.loan?.due?.totalAllDue || 0
                  ).toFixed(2) || "0"
                )}
              </p>
            </div>
          </div>
          <div className="mb-2">
            <h2 className="text-xl text-cyan-900 font-bold">Current Balance</h2>
            <div className="grid grid-cols-2 font-extralight">
              <p>
                $
                {FormatNumber(
                  nullTransform(
                    parseFloat(
                      selectedContract?.loan?.balance?.total || 0
                    ).toFixed(2)
                  )
                )}
              </p>
            </div>
          </div>

          <div className="mb-5">
            <h2 className="text-xl text-cyan-900 font-bold">
              Scheduled Payment Date:
            </h2>
            <div className="grid grid-cols-2 font-extralight">
              <p>{selectedContract.scheduledPaymentDate}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-10">
        <div className="mb-5">
          <p className="text-3xl">Policies - Breakdown</p>
        </div>
        <ul role="list" className="divide-y divide-y-8 divide-white">
          {selectedContract.policyBreakdown.map((polBr, index) => (
            <li key={index} className="flex bg-stone-200">
              <div className="p-5 grid grid-cols-5">
                <div className="col-span-1">
                  <h1 className="text-lg">Policy Number</h1>
                  <h2 className="text-sm">{polBr.policyNumber}</h2>
                </div>
                <div className="col-span-2 mx-2">
                  <h1 className="text-lg">Coverage Type</h1>
                  <h2 className="text-sm">{polBr.coverageType}</h2>
                </div>
                <div className="col-span-1">
                  <h1 className="text-lg">Premium</h1>
                  <h2 className="text-sm">{polBr.premium}</h2>
                </div>
                <div className="col-span-1">
                  <h1 className="text-lg">Insurance Company</h1>
                  <h2 className="text-sm">{polBr.insuranceCompany}</h2>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <DetailedSchedule currentContract={selectedContract} />
    </div>
  );
}
