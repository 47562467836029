import { useState, useEffect } from "react";
import { setDeepValue } from "../../../util/util";
import Http from "../../../util/http";
import Swal from "sweetalert2";
import AccessControl from "../../../util/accessControl";

function isEmpty(value) {
  return value == null || value.length === 0;
}

export default function RolesEdit() {
  const [role, setRole] = useState({
    description: "",
    name: "",
    permissions: {
      permissions: {},
      permissionsList: [],
    },
  });

  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    let dialog = Swal.fire({
      title: 'Loading...',
      timerProgressBar: true,
      allowOutsideClick: false, 
      didOpen: () => {
        Swal.showLoading()
      },
      willClose: () => {
      }
    })
    Http.request("admin", "roles/get-one", {
      id: window.location.pathname.split("/")[3],
    })
      .then((role) => {
        setRole(role);
        console.log(role)
        Http.request("admin", "permissions/all")
          .then((response) => {
            for (var i = 0; i < role.permissions.permissionsList.length; i++) {
              let index = response.findIndex(
                (x) => x._id === role.permissions.permissionsList[i]._id
              );
              if (index !== -1) {
                response[index].valid = true
              }
            }
            setPermissions(response);
            dialog.close()
          })
          .catch((e) => {
            console.log(e);
            dialog.close()
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to make request to servers.",
            });
          });
      })
      .catch((e) => {
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to make request to servers.",
        });
      });
  }, []);

  function setKey(key, value) {
    console.log({ key: key, value: value });
    let object = { ...role };

    setDeepValue(object, value, key);
    setRole(object);
  }
  function save(e) {
    try {
      let payload = { ...role };
      if (isEmpty(payload.name && payload.description)) {
        return alert(
          "Please check that name and description fields are not empty"
        );
      }
      delete payload._id;
      Http.request("admin", "roles/update-one", { id: role._id, role: payload })
        .then((response) => {
          console.log(response);
          window.location = `${window.location.origin}/dashboard/roles`;
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text:
              `${err}`.replace("Error: ", "") ||
              "Failed to make request to servers.",
          });
        });
    } catch (err) {
      console.log(err);
    }
  }

  // function add(permission, idx) {
  //   console.log({ permission });
  //   let missions = JSON.parse(JSON.stringify(permissions));
  //   let data = { ...role };
  //   let index = data.permissions.permissionsList.findIndex(
  //     (x) => x._id === permission._id
  //   );
  //   if (index === -1) {
  //     data.permissions.permissionsList.push(permission);
  //     missions.splice(idx, 1);
  //     setRole(data);
  //     setPermissions(missions);
  //   }
  // }
  // function remove(permission, idx) {
  //   console.log("removing permission", idx);
  //   let missions = JSON.parse(JSON.stringify(permissions));
  //   console.log({ permission, idx });
  //   let index = role.permissions.permissionsList.findIndex(
  //     (x) => x._id === permission._id
  //   );
  //   let data = { ...role };
  //   if (index !== -1) {
  //     data.permissions.permissionsList.splice(idx, 1);
  //     missions.push(permission);
  //     setRole(data);
  //     setPermissions(missions);
  //   }
  // }

  function checkAvailable (permission) {
    let data = {...role}
    console.log( data.permissions.permissionsList)
    data.permissions.permissionsList.map(item => {
    
      if(item._id == permission._id) {
        return true
      }
    })

    return false;
  }

  function add(permission,idx){
   
    let missions = JSON.parse(JSON.stringify(permissions));
    missions[idx].valid = true;
    setPermissions(missions);
    let data = {...role}
    data.permissions.permissionsList.push(permission)
    setRole(data)

  }
  function remove(permission,idx){
    let missions = JSON.parse(JSON.stringify(permissions));
    missions[idx].valid = false;
    setPermissions(missions);
    let data = {...role}
    data.permissions.permissionsList.map((item,index) => {
      if(item.name == permission.name) {
        data.permissions.permissionsList.splice(index, 1)
      }
    })
    setRole(data)
    
  }

  function checker(permission, idx, event) {
    console.log(event.target.checked)
    if(event.target.checked) {
      add(permission, idx)
      console.log('add')
     // event.target.checked = true;
    } else {
      remove(permission, idx)
      console.log('remove')
     // event.target.checked = false;
    }
    console.log(role)
  }

  function deleteRole(e) {
    e.preventDefault();
    try {
      Swal.fire({
        title: "Remove Role!",
        text: "This action is not reversible. Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Delete It!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          Http.request("admin", "roles/remove-one", { id: role._id })
            .then((response) => {
              console.log(response);
              Swal.fire({
                icon: "success",
                title: "Hurray!",
                text: "Role removed successfully",
              }).then(() => {
                window.location = `${window.location.origin}/dashboard/roles`;
              });
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to make request to servers.",
              });
            });
        }
      });
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <div className="shadow sm:overflow-hidden sm:rounded-md  ">
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <div>
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
              <div>
                <h2 className="text-base font-semibold leading-6 text-gray-900">
                  Role Information
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  Please ensure all fields are filled in correctly.
                </p>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Name
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setKey("name", e.target.value);
                    }}
                    value={role.name}
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="col-span-6">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Description
                  </label>
                  <textarea
                    rows={3}
                    type="text"
                    name="description"
                    id="description"
                    value={role.description}
                    onChange={(e) => {
                      setKey("description", e.target.value);
                    }}
                    autoComplete="description"
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* <div style={{height: '230px',marginBottom: '20px'}} className="">
                <h1>List of role permissions.</h1>
                <div className='flex'>
                  <div style={{height: '230px',overflowY: 'scroll',borderRadius: '5px'}} className='w-full ring-1 ring-inset ring-gray-300'>
                    {
                      role.permissions.permissionsList.map((permission,index) =>{
                       
                        return (
                          <PermissionListItem index={index} remove={remove} key={index} permission={permission}/>
                        )
                      })
                    }
                  </div>
                </div>

              </div> */}

              {/* <div style={{height: '230px',marginBottom: '20px'}} className="">
                <h1>Please choose from list of permissions below.</h1>
                <div className='flex'>
                  <div style={{height: '230px',overflowY: 'scroll',borderRadius: '5px'}} className='w-full ring-1 ring-inset ring-gray-300'>
                    {
                      permissions.map((permission,index) =>{
                       
                        return (
                          <PermissionListItem add={add} index={index} key={index} permission={permission}/>
                        )
                      })
                    }
                  </div>
                </div>

              </div> */}

              <div class="grid grid-cols-5 gap-5" style={{ width: "100%" }}>
                {permissions.map((permission, index) => {
                  return (
                    // <PermissionListItem add={add} index={index} key={index} permission={permission}/>

                    <div class="grid grid-cols-7" key={index}>
                      <div className="col-span-1">
                        <input
                        type="checkbox"
                        className="mt-2"
                        checked={permission.valid}
                        style={{ width: "70%",height: "70%" }}
                        onChange={(event) => checker(permission, index, event)}
                      /></div>
                      <div className="col-span-6 text-base mt-2">{permission.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
              <a
                href="/dashboard/roles"
                type="button"
                className="text-sm font-semibold leading-6 text-gray-900 mx-3"
              >
                Cancel
              </a>
              <AccessControl
                fallback={<></>}
                permission={"removeRole"}
                target={["god"]}
              >
                <button
                  onClick={(e) => {
                    deleteRole(e);
                  }}
                  type="submit"
                  className="mx-2 inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                >
                  Remove
                </button>
              </AccessControl>
              <AccessControl
                permission={"updateRole"}
                fallback={<></>}
                target={["god"]}
              >
                <button
                  onClick={(e) => {
                    save(e);
                  }}
                  type="submit"
                  className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Save
                </button>
              </AccessControl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

