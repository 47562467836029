/* eslint-disable jsx-a11y/anchor-is-valid */
"use client";

import { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";


export default function InvitationTile({ document, index,removeDocument }) {
    const [show, setShow] = useState(true);
    const [marginTop,setMarginTop] = useState(0);
  
    // Dynamically calculate margin-top based on the index
     // Adjust the value '80' as needed for spacing
    useEffect(() => {
        setMarginTop(index * 10)
    }, [index]);
    return (
      <>
        {/* Global notification live region, render this permanently at the end of the document */}
        <div
          aria-live="assertive"
          className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
          style={{ marginTop: `${marginTop}rem` }} // Apply dynamic margin-top
        >
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <Transition show={show}>
              <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black/5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
                <div style={{ minWidth: "20vw" }} className="p-4">
                  <div className="flex items-start">
                    <div className="shrink-0">
                      {/* Green "file-signature" icon */}
                      <i
                        className="fas fa-file-signature text-blue-300"
                        style={{ fontSize: "24px" }}
                      ></i>
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        Contract to Sign [{document.loanNumber}]
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        You have a contract awaiting your signature. Please review
                        and sign it.
                      </p>
                      <div className="mt-3 flex space-x-7">
                        <a
                          href={`${document.inviteUrl}`}
                          target="_blank"
                          type="button"
                          className="rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          rel="noreferrer"
                        >
                          View
                        </a>
                        <button
                          onClick={() => {
                           // setShow(false);
                            removeDocument(document._id);
                          }}
                          type="button"
                          className="rounded-md bg-white text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Dismiss
                        </button>
                      </div>
                    </div>
                    <div className="ml-4 flex shrink-0">
                      <button
                        type="button"
                        onClick={() => {
                         // setShow(false);
                          removeDocument(document._id);
                        }}
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon aria-hidden="true" className="size-5" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </>
    );
  }
  
