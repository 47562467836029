
import Pagination from '../../../components/pagination'
import { useState,useEffect,Fragment } from 'react'
import Http from '../../../util/http'
import {getDeepValue} from '../../../util/util'
import Swal from 'sweetalert2'
import moment from "moment"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function isEmpty(value){
  return (value == null || value.length === 0);
}

export default function IPSIndex(props) {
    const [users,setUsers] = useState([])
    const [ips,setIPS] = useState([])
    const [paging,setPaging] = useState({text: '',sort: '',total: 0,page: 1,count: 0,perPage: 10,organization: '',user: ''})
    const [organizations,setOrganizations] = useState([])
    const [organization,setOrganization] = useState({})
    
    const [showSelect,setShowSelect] = useState(true)
    
    async function search (field){
      let pagingData = {...paging}
      pagingData[field.key] = field.value
      pagingData.page = parseInt(pagingData.page)
      setPaging(pagingData)
      let url = window.location.origin + window.location.pathname +'?' +new URLSearchParams(pagingData)
      window.history.replaceState( {} , 'ips', url)
      //getUsers(paging.organization || '')
      getData(pagingData)
      
    }
    useEffect(() => {

      let user  =  JSON.parse(localStorage.getItem('user'))
      if(user.position !== 'master' && user.position !== 'god'){
        console.log('do not show')
        setShowSelect(false)
      }
      let org = JSON.parse(localStorage.getItem('organization'))
      setOrganization(org)
      let pagingData = {}
      if(window.location.search !== ''){
        pagingData = JSON.parse('{"' + decodeURI(window.location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
        pagingData.text = pagingData.text.replaceAll("+", " ")
        pagingData.page = parseInt(pagingData.page)
        pagingData.perPage = parseInt(pagingData.perPage)
        setPaging(pagingData)
      }else{
        
        pagingData = {... paging}
        pagingData.page = parseInt(pagingData.page)
        pagingData.perPage = parseInt(pagingData.perPage)
      }
      Http.request('admin','organizations/get-all').then(async (response)=>{
        setOrganizations(response.organizations)
        pagingData.organization = response.organizations[0]._id || ''
        
        let urs  = await getUsers(pagingData.organization)
        if(isEmpty(pagingData.user)){
          pagingData.user = urs[0]._id || ''
        }
        setPaging({...pagingData,organization: response.organizations[0]._id || ''})
        
        await getData(pagingData,true)
        
      }).catch((e)=>{
        console.log(e)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to make request to servers.',
        })
      })
    }, [])

    const fields = [
      {title: 'Range Start', key: 'address',sorting: false},
      {title: 'Range End', key: 'maxAddress',sorting: false},
      {title: 'Status', key: 'status',sorting: false},
      {title: 'Requested BY', key: 'requested_BY',sorting: false},
      {title: 'Created', key: 'createdAt',sorting: false},
    ]
    function getUsers(org){
      return new Promise((resolve, reject) =>{
        Http.request('admin','users/get-all',{text: '',sort: '',total: 0,page: 1,count: 0,perPage: 10000,organization: org}).then((response)=>{
          setUsers(response.users)
          resolve(response.users)
      }).catch((e)=>{
        console.log(e)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to make request to servers.',
        })
        resolve()
      })
      })
    }
    function getData(pagingData,showloader){
      let dialog
      if(showloader){
        dialog = Swal.fire({
          title: 'Loading...',
          timerProgressBar: true,
          allowOutsideClick: false, 
          didOpen: () => {
            Swal.showLoading()
          },
          willClose: () => {
          }
        })
      }
        Http.request('admin','ips/get-all',pagingData).then((response)=>{
          response.ips.forEach(element => {
            element.createdAt = moment(new Date(element.createdAt)).format('MMMM Do YYYY, h:mm:ss a')
          });
          setIPS(response.ips)
          let newPagingData = {...pagingData}
          delete newPagingData.text
          setPaging({...newPagingData,total: response.total,count: response.ips.length,perPage: response.perPage})
          if(showloader){ dialog.close()}
      }).catch((e)=>{
        console.log(e)
        if(showloader){ dialog.close()}
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to make request to servers.',
        })
      })
      }
    async function selectOrganization(id){
      let pagingData = {...paging}
      pagingData.organization = id
      //pagingData.user = ''

      let usrs = await  getUsers(id)
      if(!isEmpty(usrs)){
        pagingData.user = usrs[0]._id || ''
      }else{
        pagingData.user = ''
      }
      let url = window.location.origin + window.location.pathname +'?' +new URLSearchParams(pagingData)
      window.history.replaceState( {} , 'users', url)
      getData(pagingData)
      
    }
    function selectUser(id){
      let pagingData = {...paging}
      pagingData.user = id
      let url = window.location.origin + window.location.pathname +'?' +new URLSearchParams(pagingData)
      window.history.replaceState( {} , 'users', url)
      getData(pagingData)
    }
    return (
      <div className="px-4 mt-7 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
          <div className="mt-6 flex gap-x-4" action="#">
            <div className="min-w-0 flex-1">
              <label className="sr-only">Search</label>
              <div className="relative rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd"></path>
                  </svg>
                </div>
                <input value={paging.text} onChange={(e)=>{search({key: 'text',value: e.target.value})}} type="search" name="search" id="search" className="block w-full rounded-md border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Search"/>
              </div>
            </div>
           {showSelect &&
             <div className="w-1/4">
             <label className="sr-only">Select Organization</label>
             <div className="relative rounded-md shadow-sm">
                 <select
                     id="type"
                     value={paging.organization}
                     onChange={(e)=>{selectOrganization(e.target.value)}} 
                     name="type"
                     autoComplete="type"
                     className="block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                   >
                     {organizations.map((org,index)=>{
                       return (
                         <option key={index} value={org._id}>{org.name}</option>
                       )
                     })}
                 </select>
             </div>
             </div>
           }
           {paging.organization !== '' && paging.organization !== undefined &&
            <div className="w-1/4">
             <label className="sr-only">Select User</label>
             <div className="relative rounded-md shadow-sm">
                 <select
                     id="type"
                     value={paging.user}
                     onChange={(e)=>{selectUser(e.target.value)}} 
                     name="type"
                     autoComplete="type"
                     className="block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                   >
                    <option value={''}>Please select a user</option>
                     {users.map((user,index)=>{
                       return (
                         <option key={index} value={user._id}>{user.firstName} {user.lastName}</option>
                       )
                     })}
                 </select>
             </div>
             </div>
           }
            <a
              href='/dashboard/ips/create'
              //onClick={()=>{window.location.href = window.location.href + ''}}
              type="button"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              New IP
            </a>
          </div>
        </div>


        {/* IP table*/}
        <div className="hidden sm:block">
                <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                  <div className="mt-2 flex flex-col">
                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className='bg-gray-50'>
                          <tr>
                            {fields.map((field,index)=>{
                              return(
                                <th key={index} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                  {field.title}
                                </th>
                              )
                            })

                            }
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {ips.map((ip,index) => (
                            <tr onClick={() => window.location.replace(`${window.location.pathname}/${ip._id}?org=${organizations.find(o => o._id === paging.organization).name || 'error'}`)} key={index} className="bg-white cursor-pointer">
                                {fields.map((field,i) =>(
                                    <td key={i} className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                      {getDeepValue(ip,field.key).substring(0,70)} 
                                      {getDeepValue(ip,field.key).length  > 70 &&
                                        <>...</>
                                      }
                                      
                                    </td>
                                  ))
                                }
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <a href={`${window.location.pathname}/${ip._id}?org=${organizations.find(o => o._id === paging.organization).name || 'error'}`} className="text-indigo-600 hover:text-indigo-900">
                                    Edit<span className="sr-only">, {ip.name}</span>
                                  </a>
                                </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/* Pagination */}
                      <Pagination getData={getData} paging={paging}/>
                    </div>
                  </div>
                </div>
        </div>
      </div>
    )
}

