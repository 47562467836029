import { useState, useEffect, Fragment } from "react";
import Http from "../../../util/http";
import Swal from "sweetalert2";
import LoanCancellationTable from "../../../components/LoanCancellationTable";
import AccessControl from "../../../util/accessControl";
import { json2csv } from "json-2-csv";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Cancellations(props) {
  const [paging, setPaging] = useState({
    text: "",
    company: "",
    broker: "",
    type: 0,
    page: 1,
    sort: "",
    total: 0,
    count: 0,
    perPage: 5,
    cancellationStatus: null,
    exportCsv: false,
    exportExcel: false,
  });
  const [searchText, setSearchText] = useState("");
  const [contractsMaster, setcontractsMaster] = useState([]);
  const [contractsUnder30, setContractsUnder30] = useState([]);
  const [contractsUnder60, setContractsUnder60] = useState([]);
  const [contractsUnder90, setContractsUnder90] = useState([]);
  const [contractsOver90, setContractsOver90] = useState([]);
  const [contractAgeTotals, setContractAgeTotals] = useState({
    thirty: 0,
    sixty: 0,
    ninety: 0,
    ninetyplus: 0,
  });
  const [contractInitState, setContractInitState] = useState({
    master: true,
    under30: true,
    under60: true,
    under90: true,
    over90: true,
  });
  const [brokers, setBrokers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [tabs, setTabs] = useState([
    { name: "Master", current: true, total: "master" },
    { name: "Under 30", current: false, total: "thirty" },
    { name: "Under 60", current: false, total: "sixty" },
    { name: "Under 90", current: false, total: "ninety" },
    { name: "Over 90", current: false, total: "ninetyplus" },
  ]);
  const [totals, setTotals] = useState({
    premiumFinanced: 0,
    totalDueNow: 0,
    balance: 0,
    bfttlTimeOnRisk: 0,
    projectedEstimatedFreeInsurance: 0,
    expectedNetPremiumSettlement: 0,
  });
  const [current, setCurrent] = useState(0);

  function setKeyword(value) {
    const pagingData = { ...paging };
    pagingData.text = value;
    pagingData.page = 1;
    pagingData.type = current;
    setPaging(pagingData);
    //getData(pagingData);
    //search({key:'text', value: value})
  }
  function setStatus(value) {
    const pagingData = { ...paging };
    pagingData.cancellationStatus = value;
    pagingData.page = 1;
    pagingData.type = current;
    setPaging(pagingData);
    //getData(pagingData);
    //search({key:'text', value: value})
  }

  function setCompany(value) {
    const pagingData = { ...paging };
    pagingData.company = value;
    pagingData.page = 1;
    pagingData.type = current;
    setPaging(pagingData);
    //getData(pagingData);
    //search({key:'company', value: value})
  }
  function setBroker(value) {
    const pagingData = { ...paging };
    pagingData.broker = value;
    pagingData.page = 1;
    pagingData.type = current;
    setPaging(pagingData);
    //getData(pagingData);
    //search({key:'broker', value: value})
  }
  function filter() {
    const pagingData = { ...paging };
    //pagingData.page = 1//parseInt(pagingData.page)
    //pagingData.type = current
    getTotals(pagingData);
    getData(pagingData);
  }
  function resetFilter() {
    const pagingData = { ...paging };
    pagingData.page = 1;
    pagingData.type = current;
    pagingData.text = "";
    pagingData.company = "";
    pagingData.broker = "";
    getData(pagingData);
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const modelTitle = (str) => {
    var text = [];
    for (var i = 0; i < str.length; i++) {
      let char = "";
      if (str.charAt(i) === str.charAt(i).toUpperCase()) {
        char = ` ${str.charAt(i)}`;
      } else {
        char = str.charAt(i);
      }
      text.push(char);
    }
    text = text.join(",");
    while (text.includes(",")) {
      text = text.replace(",", "");
    }
    return capitalizeFirstLetter(text);
  };
  function renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map((key) => {
      const newKey = newKeys[key] || key;
      return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
  }
  function updateFields(items) {
    for (var i = 0; i < items.length; i++) {
      delete items[i].contractClient;
      delete items[i].contractId;
      let oldKeys = Object.keys(items[i]);
      let newKeys = {};

      for (let j = 0; j < oldKeys.length; j++) {
        newKeys[oldKeys[j]] = modelTitle(oldKeys[j]);
      }

      items[i] = renameKeys(items[i], newKeys);
      console.log(items[i]);
    }
    return items;
  }

  async function exportCsv() {
    await Swal.fire({
      title: "CSV Export!",
      text: "All records for the selected age period will be downloaded to a csv file.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Download It!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const selectedTab = tabs.filter((e) => e.current);
          if (selectedTab.length === 0) return;
          const dateName =
            new Date().toDateString().replaceAll(" ", "_") +
            "_" +
            new Date().toTimeString().replaceAll(" ", "_");
          const pagingData = { ...paging };
          pagingData.page = parseInt(pagingData.page);
          pagingData.type = current;
          pagingData.exportCsv = true;
          Http.request(
            "contracts",
            "contracts/clients/export/get-all",
            pagingData
          )
            .then((response) => {
              if (response.contracts.length === 0) {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "No records found to be downloaded.",
                });
              } else {
                response.contracts = updateFields(response.contracts);
                json2csv(response.contracts, {
                  emptyFieldValue: ""
                }).then((jsonData) => {
                  const blob = new Blob([jsonData], { type: "text/csv" });
                  const url = window.URL.createObjectURL(blob);
                  const a = document.createElement("a");
                  a.setAttribute("href", url);
                  a.setAttribute(
                  "download",
                  `${dateName}_csv_export_${selectedTab[0].total}.csv`
                  );
                  a.click();
                });
              }
            })
            .catch((e) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: e.message || "Failed to make request to servers.",
              });
            });
        } catch (e) {
          await Swal.fire({
            icon: "error",
            title: "Oops...",
            text: e.message || "Failed to refund selected loan",
          });
        }
      }
    });
  }
  async function exportExcel() {
    await Swal.fire({
      title: "Excel Export!",
      text: "All records for the selected age period will be downloaded to an excel file.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Download It!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const selectedTab = tabs.filter((e) => e.current);
          if (selectedTab.length === 0) return;
          const dateName =
            new Date().toDateString().replaceAll(" ", "_") +
            "_" +
            new Date().toTimeString().replaceAll(" ", "_");
          const pagingData = { ...paging };
          pagingData.page = parseInt(pagingData.page);
          pagingData.type = current;
          pagingData.exportExcel = true;
          Http.request(
            "contracts",
            "contracts/clients/export/get-all",
            pagingData
          )
            .then((response) => {
              const url = window.URL.createObjectURL(
                new Blob([new Uint8Array(response.buffer.data)])
              );
              const a = document.createElement("a");
              a.setAttribute("href", url);
              a.setAttribute(
                "download",
                `${dateName}_excel_export_${selectedTab[0].total}.xlsx`
              );
              a.click();
            })
            .catch((e) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: e.message || "Failed to make request to servers.",
              });
            });
        } catch (e) {
          await Swal.fire({
            icon: "error",
            title: "Oops...",
            text: e.message || "Failed to refund selected loan",
          });
        }
      }
    });
  }
  function selectTab(index) {
    let tempTabs = tabs;
    tempTabs[index].current = true;
    tempTabs[current].current = false;
    setTabs(tempTabs);
    if (index !== current) {
      const pagingData = { ...paging };
      pagingData.page = 1;
      pagingData.type = index;
      /* pagingData.text = ''
            pagingData.company = ''
            pagingData.broker = ''
            pagingData.sort = ''*/
      getData(pagingData);
    }
    setCurrent(index);
  }
  function getData(pagingData, initLoad = false, showloader) {
    let dialog;
    if (showloader) {
      dialog = Swal.fire({
        title: "Loading...",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {},
      });
    }
    Http.request("contracts", "contracts/clients/get-all", pagingData)
      .then((response) => {
        try {
          let _totals = {
            premiumFinanced: 0,
            totalDueNow: 0,
            principalBalance: 0,
            bfttlTimeOnRisk: 0,
            projectedEstimatedFreeInsurance: 0,
            expectedNetPremiumSettlement: 0,
          };

          for (var i = 0; i < response.contracts.length; i++) {
            _totals.premiumFinanced =
              parseFloat(response.contracts[i].premiumFinanced) +
              parseFloat(_totals.premiumFinanced);
            _totals.bfttlTimeOnRisk =
              parseFloat(response.contracts[i].bfttlTimeOnRisk) +
              parseFloat(_totals.bfttlTimeOnRisk);
            _totals.totalDueNow =
              parseFloat(response.contracts[i].totalDueNow || 0) +
              parseFloat(_totals.totalDueNow || 0);
            _totals.projectedEstimatedFreeInsurance =
              parseFloat(
                response.contracts[i].projectedEstimatedFreeInsurance
              ) + parseFloat(_totals.projectedEstimatedFreeInsurance);
            _totals.expectedNetPremiumSettlement =
              parseFloat(response.contracts[i].expectedNetPremiumSettlement) +
              parseFloat(_totals.expectedNetPremiumSettlement);
            _totals.principalBalance =
              parseFloat(response.contracts[i].principalBalance || 0) +
              parseFloat(_totals.principalBalance || 0);
          }
          setTotals(response.totals);

          switch (pagingData.type) {
            case 0: {
              setcontractsMaster(response.contracts);
              const init = { ...contractInitState };
              init.under30 = false;
              setContractInitState(init);
              if (!initLoad) {
                const totals = { ...contractAgeTotals };
                totals.thirty = response.total;
                //setContractAgeTotals(totals);
              }
              break;
            }
            case 1: {
              setContractsUnder30(response.contracts);
              const init = { ...contractInitState };
              init.under30 = false;
              setContractInitState(init);
              if (!initLoad) {
                const totals = { ...contractAgeTotals };
                totals.thirty = response.total;
                //setContractAgeTotals(totals);
              }
              break;
            }
            case 2: {
              setContractsUnder60(response.contracts);
              const init = { ...contractInitState };
              init.under60 = false;
              setContractInitState(init);
              if (!initLoad) {
                const totals = { ...contractAgeTotals };
                totals.sixty = response.total;
                //setContractAgeTotals(totals);
              }
              break;
            }
            case 3: {
              setContractsUnder90(response.contracts);
              const init = { ...contractInitState };
              init.under90 = false;
              setContractInitState(init);
              if (!initLoad) {
                const totals = { ...contractAgeTotals };
                totals.ninety = response.total;
                //setContractAgeTotals(totals);
              }
              break;
            }
            case 4: {
              setContractsOver90(response.contracts);
              const init = { ...contractInitState };
              init.over90 = false;
              setContractInitState(init);
              if (!initLoad) {
                const totals = { ...contractAgeTotals };
                totals.ninetyplus = response.total;
                //setContractAgeTotals(totals);
              }
              break;
            }
          }

          setPaging({
            ...pagingData,
            total: response.total,
            count: response.contracts.length,
            perPage: response.perPage,
          });
          if (showloader) {
            dialog.close();
          }
        } catch (e) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: e.message || "Failed to make request to servers.",
          });
        }
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: e.message || "Failed to make request to servers.",
        });
      });
  }

  function getSortedData(name, direction) {
    let sortDirection = "RESET";
    switch (direction) {
      case 1: {
        sortDirection = "ASC";
        break;
      }
      case 2: {
        sortDirection = "DESC";
        break;
      }
    }
    const pagingData = { ...paging };
    pagingData.page = parseInt(pagingData.page);
    pagingData.type = current;
    pagingData.sort = `${name}|${sortDirection}`;
    getData(pagingData);
  }

  function getOrganizations() {
    Http.request("admin", "organizations/get-all", {
      text: "",
      page: 1,
      sort: "",
      total: 0,
      count: 0,
      perPage: 100000,
      companyType: "",
      limit: 100000,
    })
      .then((response) => {
        let orgs = response.organizations;
        let _brokers = [];
        let _companies = [];
        orgs.forEach((org) => {
          if (org.type.includes("Broker")) {
            _brokers.push({ value: org.name, text: org.name, _id: org._id });
          } else {
            _companies.push({ value: org.name, text: org.name, _id: org._id });
          }
        });
        setBrokers(_brokers);
        setCompanies(_companies);
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to make request to servers.",
        });
      });
  }

  function getTotals(pagingData) {
    Http.request("contracts", "contracts/clients/get-totals", paging)
      .then((response) => {
        setContractAgeTotals(response);
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: e.message || "Failed to make request to servers.",
        });
      });
  }

  useEffect(() => {
    let pagingData = {};
    if (window.location.search !== "") {
      pagingData = JSON.parse(
        '{"' +
          decodeURI(window.location.search.substring(1))
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      pagingData.text = pagingData.text.replaceAll("+", " ");
      pagingData.page = 1;
      pagingData.perPage = 5;
      pagingData.exportCsv = false;
      pagingData.exportExcel = false;
      pagingData.type = 0;
      setPaging(pagingData);
    } else {
      pagingData = { ...paging };
      pagingData.page = parseInt(pagingData.page);
      pagingData.type = current;
    }
    getData(pagingData, true, true);
    getOrganizations();
    getTotals();
  }, []);

  return (
    <div className="flex justify-center ">
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 w-full">
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
            <div className="mb-20">
              <h1 className="text-4xl text-center font-semibold  leading-6 text-gray-900">
                Contracts (Loans)
              </h1>
            </div>

            <div className="mt-6 flex gap-x-4" action="#">
              <AccessControl
                fallback={<></>}
                permission={"extraCancellationOptions"}
                target={["god"]}
              >
                <div className="min-w-0 flex-1">
                  <label className="sr-only">Search By Company</label>
                  <div
                    className="relative rounded-md shadow-sm"
                    style={{ height: "100%" }}
                  >
                    {
                      <select
                        name="contractAge"
                        id="contractAge"
                        className="block h-full w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={paging.company}
                        onChange={(e) => {
                          setCompany(e.target.value);
                        }}
                      >
                        <option value="">Select a Company</option>
                        {companies.map((company) => {
                          return (
                            <option key={company.value} value={company._id}>
                              {company.text}
                            </option>
                          );
                        })}
                      </select>
                    }
                  </div>
                </div>
                <div className="min-w-0 flex-1">
                  <label className="sr-only">Search By Broker</label>
                  <div
                    className="relative rounded-md shadow-sm"
                    style={{ height: "100%" }}
                  >
                    {
                      <select
                        name="contractBroker"
                        id="contractBroker"
                        className="block h-full w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={paging.broker}
                        onChange={(e) => {
                          setBroker(e.target.value);
                        }}
                      >
                        <option value="">Select Broker</option>
                        {brokers.map((broker) => {
                          return (
                            <option key={broker.value} value={broker._id}>
                              {broker.text}
                            </option>
                          );
                        })}
                      </select>
                    }
                  </div>
                </div>
              </AccessControl>
              <div className="min-w-0 flex-1">
                <label className="sr-only">Search</label>
                <div className="relative rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    value={paging.text}
                    onChange={(e) => {
                      setKeyword(e.target.value);
                    }}
                    type="search"
                    name="search"
                    id="search"
                    className="block w-full rounded-md border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Loan number, Client Number, Loan Id etc..."
                  />
                </div>
              </div>
              <div className="min-w-0 flex-1">
                <label className="sr-only">Search By Broker</label>
                <div
                  className="relative rounded-md shadow-sm"
                  style={{ height: "100%" }}
                >
                  <select
                    name="cancellationStatus"
                    id="cancellationStatus"
                    className="block h-full w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={paging.cancellationStatus}
                    onChange={(e) => {
                        
                      setStatus(e.target.value);
                    }}
                  >
                    <option value={null}>Select a Cancellation Status</option>
                    <option value="awaitingCancellation">
                      Awaiting Cancellation
                    </option>
                    <option value="noticeCancellation">
                      Notice of Cancellation
                    </option>
                  </select>
                </div>
              </div>

              <span className="isolate inline-flex rounded-md shadow-sm">
                <AccessControl
                  fallback={<></>}
                  permission={"extraCancellationOptions"}
                  target={["god"]}
                >
                  <button
                    onClick={() => {
                      filter();
                    }}
                    type="button"
                    className="relative inline-flex items-center rounded-l-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
                  >
                    Filter
                  </button>
                  <button
                    onClick={() => {
                      resetFilter();
                    }}
                    type="button"
                    className="relative -ml-px inline-flex items-center bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
                  >
                    Reset
                  </button>
                </AccessControl>

                <button
                  onClick={() => {
                    exportCsv();
                  }}
                  type="button"
                  className="relative -ml-px inline-flex items-center bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
                >
                  Export CSV
                </button>
                <button
                  onClick={() => {
                    exportExcel();
                  }}
                  type="button"
                  className="relative -ml-px inline-flex items-center rounded-r-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
                >
                  Export Excel
                </button>
              </span>
            </div>

            <div className="border-b border-gray-200 pb-5 px-20 sm:pb-0 p-5">
              <div className="mt-3 sm:mt-4">
                <div className="sm:hidden">
                  <label htmlFor="current-tab" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="current-tab"
                    name="current-tab"
                    className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    defaultValue={tabs[current].name}
                  >
                    {tabs.map((tab) => (
                      <option key={tab.name}>{tab.name}</option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <nav className="-mb-px flex space-x-8">
                    {tabs.map((tab, index) => (
                      <a
                        style={{ cursor: "pointer" }}
                        key={tab.name}
                        onClick={() => selectTab(index)}
                        className={classNames(
                          tab.current
                            ? "border-indigo-500 text-indigo-600"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
                        )}
                        aria-current={tab.current ? "page" : undefined}
                      >
                        {tab.name}
                        &nbsp;
                        <span className="inline-flex items-center rounded-full bg-indigo-600 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-purple-700/10">
                          {contractAgeTotals[tab.total]}
                        </span>
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
            </div>

            <div className="m-1">
              {current === 0 && (
                <LoanCancellationTable
                  totals={totals}
                  contracts={contractsMaster}
                  getData={getData}
                  paging={paging}
                  doSorting={getSortedData}
                  initState={contractInitState.master}
                ></LoanCancellationTable>
              )}
              {current === 1 && (
                <LoanCancellationTable
                  totals={totals}
                  contracts={contractsUnder30}
                  getData={getData}
                  paging={paging}
                  doSorting={getSortedData}
                  initState={contractInitState.under30}
                ></LoanCancellationTable>
              )}

              {current === 2 && (
                <LoanCancellationTable
                  totals={totals}
                  contracts={contractsUnder60}
                  getData={getData}
                  paging={paging}
                  doSorting={getSortedData}
                  initState={contractInitState.under60}
                ></LoanCancellationTable>
              )}

              {current === 3 && (
                <LoanCancellationTable
                  totals={totals}
                  contracts={contractsUnder90}
                  getData={getData}
                  paging={paging}
                  doSorting={getSortedData}
                  initState={contractInitState.under90}
                ></LoanCancellationTable>
              )}

              {current === 4 && (
                <LoanCancellationTable
                  totals={totals}
                  contracts={contractsOver90}
                  getData={getData}
                  paging={paging}
                  doSorting={getSortedData}
                  initState={contractInitState.over90}
                ></LoanCancellationTable>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
