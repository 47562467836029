import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import AccessControl from "../../util/accessControl";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function openLoan(contract){
    let purpose = contract.purpose?.toLowerCase()
    if(purpose === "insurance-premium-financing"){
        purpose = "retail"
    }
    window.location.replace(
        `${window.location.origin}/dashboard/clients/${purpose}/profile/${contract.clientId}/${contract.contractId}`
    )
}
export default function CancellationRoomOptions(props) {
  return (
    <Menu  as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          Options
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items style={{top: "-100px"}} className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
          <Menu.Item>
                            {({ active }) => (
                              <div
                              onClick={() => openLoan(props.loan)}
                                href="#"
                                className={classNames(
                                  active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                View Loan
                              </div>
                            )}
                          </Menu.Item>
            {!props.loan.noc && (
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => {
                      props.requestCancelLoan(props.loan.loanNumber);
                    }}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Notice Of Cancellation
                  </div>
                )}
              </Menu.Item>
            )}
            {props.loan.status !== "cancelled" && props.loan.cancellationStatus !== "cancelled" &&
                          <AccessControl fallback={<></>} permission={'instantCancelLoan'} target={['god']}>
                          <Menu.Item>
                            {({ active }) => (
                              <div
                              onClick={() => {
                                props.cancelLoan(props.loan.loanNumber);
                              }}
                                href="#"
                                className={classNames(
                                  active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                Cancel Loan
                              </div>
                            )}
                          </Menu.Item>
                        </AccessControl>
            }
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
