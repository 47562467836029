
export default function QuotationSend({setInfo,info}){


    return(
        <div className="mt-3 ">
        <div class="w-full w-lg">
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-first-name"
              >
                Client Name
              </label>
              <input
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                id="grid-first-name"
                type="text"
                placeholder="Add client name"
                onChange={(e) => {
                  setInfo({ ...info, name: e.target.value });
                }}
                value={info.name}
              />
            </div>
            <div class="w-1/2 px-3">
              <label
                class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Email
              </label>
              <input
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                id="grid-first-name"
                type="email"
                placeholder="Add client email"
                onChange={(e) => {
                  setInfo({ ...info, email: e.target.value });
                }}
                value={info.email}
              />
            </div>
          </div>
        </div>
      </div>
    )
}