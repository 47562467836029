import { useState, useEffect } from "react";
import Http from "../../../util/http";
import Swal from "sweetalert2";

export default function MicroArrearsPage() {
  const [records, setRecords] = useState([]);
  const [archives, setArchives] = useState([]);
  const [currentArchive, setCurrentArchive] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [companyType, setCompanyType] = useState('all');

  function FormatNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function nullTransform(value) {
    return value === null || value === undefined ? "" : value;
  }

  const tabs = [
    { name: "All Late > 30 Days", min: 30, max: null },
    { name: "30-60 Days Late", min: 30, max: 60 },
    { name: "60-90 Days Late", min: 60, max: 90 },
    { name: "90-180 Days Late", min: 90, max: 180 }
  ];
  

  const fetchData = (min, max) => {
    Swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading(),
    });

    Http.request("contracts", "contract/arrears/micro-report", { 
      minLateDays: min,
      maxLateDays: max,
      companyType 
    })
      .then((response) => {
        setRecords(response.data);
        setCurrentArchive(null);
        Swal.close();
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to fetch data.",
        });
      });
  };

  

  const fetchArchives = () => {
    Http.request("contracts", "contract/arrears/archives/list", {})
      .then((response) => {
        setArchives(response.data);
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to fetch archives.",
        });
      });
  };

  const loadArchive = (archiveId) => {
    Swal.fire({
      title: "Loading Archive...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading(),
    });

    Http.request("contracts", "contract/arrears/archives/load", { archiveId })
      .then((response) => {
        setRecords(response.data);
        setCurrentArchive(archiveId);
        Swal.close();
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to load archive.",
        });
      });
  };

  useEffect(() => {
    fetchData(tabs[currentTab].min, tabs[currentTab].max);
    fetchArchives();
  }, [currentTab, companyType]);

  
  

  // Calculate totals
  const totals = records.reduce(
    (acc, record) => {
      acc.totalLoans += record.totalLoans || 0;
      acc.totalLateBalance += record.totalLateBalance || 0;
      acc.awaitingCancellationCount += record.awaitingCancellationCount || 0;
      acc.awaitingCancellationBalance += record.awaitingCancellationBalance || 0;
      acc.awaitingRefundCount += record.awaitingRefundCount || 0;
      acc.awaitingRefundBalance += record.awaitingRefundBalance || 0;
      return acc;
    },
    {
      totalLoans: 0,
      totalLateBalance: 0,
      awaitingCancellationCount: 0,
      awaitingCancellationBalance: 0,
      awaitingRefundCount: 0,
      awaitingRefundBalance: 0,
    }
  );

  return (
    <div className="flex justify-center">
      <div className="w-full">
        <div className="bg-white px-4 py-6 shadow rounded-md">
          <h1 className="text-4xl text-center font-semibold">Micro Arrears</h1>

          {/* Tabs and Archive Dropdown */}
          <div className="mt-6 flex items-center justify-between">
            <nav className="flex space-x-4">
              {tabs.map((tab, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentTab(index)}
                  className={`px-4 py-2 rounded ${
                    currentTab === index
                      ? "bg-indigo-600 text-white"
                      : "bg-gray-200 text-gray-600"
                  }`}
                >
                  {tab.name}
                </button>
              ))}
            </nav>

            <div className="flex items-center gap-4">
            <label htmlFor="companyType" className="font-semibold text-gray-700">
                Company Type:
              </label>
              <select
                id="companyType"
                value={companyType}
                onChange={(e) => setCompanyType(e.target.value)}
                className="rounded-md border border-gray-300 p-2"
              >
                <option value="all">All Companies</option>
                <option value="broker">Brokers</option>
                <option value="insurance">Insurance Companies</option>
              </select>
              <label htmlFor="archiveSelect" className="font-semibold text-gray-700">
                Select Archive:
              </label>
              <select
  id="archiveSelect"
  value={currentArchive || ""}
  onChange={(e) => loadArchive(e.target.value)}
  className="rounded-md border border-gray-300 p-2 min-w-[200px] w-full max-w-xs"
>
  <option value="">Select an Archive</option>
  {archives.map((archive) => (
    <option key={archive._id} value={archive._id}>
      {archive.name}
    </option>
  ))}
</select>
            </div>
          </div>

          {/* Data Table */}
          <div className="mt-6">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Company Name</th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Total Loans</th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Total Late Balance</th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500"># Awaiting Cancellation</th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Balance Awaiting Cancellation</th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500"># Awaiting Refund</th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Balance Awaiting Refund</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {records.map((record, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4">{record.organizationName}</td>
                    <td className="px-6 py-4">{record.totalLoans}</td>
                    <td className="px-6 py-4">
                      ${nullTransform(
                        FormatNumber(
                          parseFloat(record.totalLateBalance || 0).toFixed(2)
                        )
                      )}
                    </td>
                    <td className="px-6 py-4">{record.awaitingCancellationCount}</td>
                    <td className="px-6 py-4">
                      ${nullTransform(
                        FormatNumber(
                          parseFloat(record.awaitingCancellationBalance || 0).toFixed(2)
                        )
                      )}
                    </td>
                    <td className="px-6 py-4">{record.awaitingRefundCount}</td>
                    <td className="px-6 py-4">
                      ${nullTransform(
                        FormatNumber(
                          parseFloat(record.awaitingRefundBalance || 0).toFixed(2)
                        )
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className="bg-gray-100">
                  <td className="px-6 py-4 font-semibold">Totals</td>
                  <td className="px-6 py-4 font-semibold">{totals.totalLoans}</td>
                  <td className="px-6 py-4 font-semibold">
                    ${nullTransform(FormatNumber(totals.totalLateBalance.toFixed(2)))}
                  </td>
                  <td className="px-6 py-4 font-semibold">{totals.awaitingCancellationCount}</td>
                  <td className="px-6 py-4 font-semibold">
                    ${nullTransform(FormatNumber(totals.awaitingCancellationBalance.toFixed(2)))}
                  </td>
                  <td className="px-6 py-4 font-semibold">{totals.awaitingRefundCount}</td>
                  <td className="px-6 py-4 font-semibold">
                    ${nullTransform(FormatNumber(totals.awaitingRefundBalance.toFixed(2)))}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
