import Swal from "sweetalert2";
import AccessControl from "../../util/accessControl";
import Http from "../../util/http";

export default function ViewContract({contract}) {

    const pullDocument = async () => {
        try {
          let response = await Http.request("contracts", "contracts/pull-document", {
            loanNumber: contract.contractNumber,
          });
          if(!response.url){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Document not found!',
            });
          }else{
            window.open(response.url, response.url);
          }
        } catch (error) {
          console.log(error);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          });
        }
      }
    return (
            <AccessControl
              permission={"pullContract"}
              fallback={<></>}
              target={["god"]}
            >
              <button
                onClick={() => {
                  pullDocument(contract?.loan?.loanNumber);
                }}
                type="button"
                className="relative -ml-px inline-flex items-center bg-green-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-green-500 focus:z-10"
              >
                View Contract
              </button>
            </AccessControl>
    )
}