import { useState, useEffect } from "react";
import { getDeepValue } from "../../../util/util";
import Http from "../../../util/http";
import Pagination from "../../../components/pagination";
import Swal from "sweetalert2";

export default function RolesIndex() {
  const [roles, setRoles] = useState([]);

  const [paging, setPaging] = useState({
    text: "",
    sort: "",
    total: 0,
    page: 1,
    count: 0,
    perPage: 10,
  });

  async function search(field) {
    let pagingData = { ...paging };
    pagingData[field.key] = field.value;
    pagingData.page = parseInt(pagingData.page);
    setPaging(pagingData);
    let url =
      window.location.origin +
      window.location.pathname +
      "?" +
      new URLSearchParams(pagingData);
    window.history.replaceState({}, "users", url);
    getData(pagingData);
  }

  useEffect(() => {
    let pagingData = {};
    if (window.location.search !== "") {
      pagingData = JSON.parse(
        '{"' +
          decodeURI(window.location.search.substring(1))
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      pagingData.text = pagingData.text.replaceAll("+", " ");
      pagingData.page = parseInt(pagingData.page);
      setPaging(pagingData);
    } else {
      pagingData = { ...paging };
      pagingData.page = parseInt(pagingData.page);
    }
    getData(pagingData, true);
  }, []);
  const fields = [
    { title: "Name", key: "name", sorting: false },
    { title: "Description", key: "description", sorting: false },
    { title: "Permissions", key: "count", sorting: false },
    //{title: 'Action', field: 'action'}
  ];

  function resetFilter() {
    search({ key: "text", value: "" });
  }

  function getData(pagingData, showloader) {
    let dialog;
    if (showloader) {
      dialog = Swal.fire({
        title: "Loading...",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {},
      });
    }
    Http.request("admin", "roles/get-all", pagingData)
      .then((response) => {
        response.roles.forEach((role) => {
          role.count = role.permissions.permissionsList.length;
        });
        setRoles(response.roles);
        let newPagingData = { ...pagingData };
        delete newPagingData.text;
        setPaging({
          ...newPagingData,
          total: response.total,
          count: response.roles.length,
          perPage: response.perPage,
        });
        if (showloader) {
          dialog.close();
        }
      })
      .catch((err) => {
        if (showloader) {
          dialog.close();
        }
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.message || "Failed to make request to servers.",
        });
      });
  }

  return (
    <div className="flex justify-center ">
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 w-full">
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
            <div className="mb-10">
              <h1 className="text-4xl text-center font-semibold  leading-6 text-gray-900">
                (Roles)
              </h1>
            </div>
            <div className="px-4 mt-7">
              <div className="mx-auto  px-4 mb-4">
                <div className="mt-6 flex gap-x-4" action="#">
                  <div className="min-w-0 flex-1">
                    <label className="sr-only">Search</label>
                    <div className="relative rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <svg
                          className="h-5 w-5 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <input
                        value={paging.text}
                        onChange={(e) => {
                          search({ key: "text", value: e.target.value });
                        }}
                        type="search"
                        name="search"
                        id="search"
                        className="block w-full rounded-md border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <span className="isolate inline-flex rounded-md shadow-sm">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        resetFilter();
                      }}
                      type="button"
                      className="relative inline-flex items-center rounded-l-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
                    >
                      Reset Filter
                    </button>

                    <button
                      onClick={() => {
                        window.location.href = `${window.location.origin}/dashboard/roles/create`;
                      }}
                      type="button"
                      className="relative -ml-px inline-flex items-center rounded-r-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
                    >
                      New Roles
                    </button>
                  </span>
                </div>
              </div>

              {/* Roles table*/}
              <div className="hidden sm:block">
                <div className="mx-auto  px-4 ">
                  <div className="mt-2 flex flex-col">
                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            {fields.map((field, index) => {
                              return (
                                <th
                                  key={index}
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6"
                                >
                                  {field.title}
                                </th>
                              );
                            })}
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {roles.map((permission, index) => (
                            <tr
                              onClick={() =>
                                window.location.replace(
                                  `${window.location.pathname}/${permission._id}`
                                )
                              }
                              key={index}
                              className="bg-white cursor-pointer"
                            >
                              {fields.map((field, i) => (
                                <td
                                  key={i}
                                  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                                >
                                  {getDeepValue(permission, field.key)}
                                </td>
                              ))}
                              <td className="relative whitespace-nowrap py-4 pl-7 text-sm font-medium sm:pr-6 ">
                                <a
                                  href={`${window.location.pathname}/${permission._id}`}
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  Edit
                                  <span className="sr-only">
                                    , {permission.name}
                                  </span>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="shadow sm:overflow-hidden sm:rounded-b-md">
                      <Pagination getData={getData} paging={paging} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
