import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { useState,useEffect } from 'react'

function remove_duplicates_es6(arr) {
  let s = new Set(arr);
  let it = s.values();
  return Array.from(it);
}

export default function Pagination(props) {
  
  const [start, setStart] = useState(0)
  const [pages,setPages] = useState([])
  useEffect(() => {
   // console.log({props})
      let x = (props.paging.page - 1) * props.paging.perPage
     // console.log({x})
      if(x === 0 && props.paging.total > 0){
        x = 1
      }else{
        x = (props.paging.page - 1) * props.paging.perPage
      }

      let numPages = Math.ceil(props.paging.total / props.paging.perPage)
     // console.log({numPages})
      if(numPages < 5){
        //paging.total.page
        let subPages = []
        for(let i = 0; i < numPages;i++){
          subPages.push(i +1)
        }
        setPages(subPages)
      }else{
        let subPages = []
        if(props.paging.page < (numPages - 3)){
          for(let i = 0; i < 3;i++){
            subPages.push(props.paging.page + i)
          }
          
        }
        for(let i = (numPages - 3); i <= (numPages);i++){
          subPages.push(i)
        }

        if(subPages[0] > 5) {
          subPages.unshift(1)
        }

        subPages = remove_duplicates_es6(subPages)
        if(subPages.length > 6){
          subPages.pop()
        }
        // console.log('subpages')
        // console.log(subPages)
        
        setPages(subPages)

      }
      
      setStart(x)
  }, [props])
  function prevPage(){
      let paging =  {...props.paging}
      if (paging.page > 1) {
          paging.page--;
          props.getData(paging)
          //let url = window.location.origin + window.location.pathname +'?' +new URLSearchParams(paging)
          //window.history.replaceState( {} , 'users', url)
      }
  }

  function nextPage(){
      let paging =  {...props.paging}
      if (((props.paging.page - 1) * props.paging.perPage +  props.paging.count) < props.paging.total) {
          paging.page++;
          props.getData(paging)
          //let url = window.location.origin + window.location.pathname +'?' +new URLSearchParams(paging)
          //window.history.replaceState( {} , 'users', url)
      }
  }
  function fetchData(page){
    props.getData({...props.paging,page: page})
  }
  return (
    <div className="flex items-center justify-between px-4 py-3 sm:px-6 bg-gray-100">
     
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{start}</span> to <span className="font-medium">{(props.paging.page - 1) * props.paging.perPage +  props.paging.count}</span> of{' '}
            <span className="font-medium">{props.paging.total}</span> results
          </p>
        </div>
        <div>
          <nav style={{cursor: 'pointer'}} className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <a
              onClick={()=>{prevPage()}}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
             
            </a>
            {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
            {
              pages.map((page,index)=>{
                let item = null;
                if(page === props.paging.page){
                  return (
                    <a key={index} onClick={()=>{props.getData({...props.paging,page: page})}} className="relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">{page}</a>
                  )

                }
                if(index === 2 && pages.length  > 3){
                  return (
                    <div key={index}>
                      <a  onClick={()=>{fetchData(page)}} className="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">{page}</a>
                      <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                      ...
                      </span>
                    </div>
                  )
                }else{
                  return (
                    <a key={index} onClick={()=>{props.getData({...props.paging,page: page})}} className="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">{page}</a>
                  )
                }
              })
            }
            <a
              onClick={()=>{nextPage()}}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  )
}
