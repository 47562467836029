import { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

export default function SignIn() {
    const [text,setText] = useState('')
    const [token,setToken] = useState(null)
    const [passwordInfo,setPasswordInfo] = useState({})
    const [title,setTitle] = useState('Enter activation token')
    async function getToken(event){
        //event.preventDefault();
        try{
            let response = await axios.post(`${process.env.REACT_APP_IDENTITY_API}/api`,{target: "get-token",payload:{text: text}})
            console.log({response})
            if(!response.data){
                alert('No token found')
            }else{
                setToken(response.data)
                setTitle('Enter new password')
            }
        }catch (e) {
            console.log(e)
        }
    }
    async function savePassword(event){
        //event.preventDefault();
        try{
            let response = await axios.post(`${process.env.REACT_APP_IDENTITY_API}/api`,{target: "set-password",payload:{tokenId: token._id,passwordInfo:passwordInfo}})
            console.log({response})
            
            window.location.href = window.location.origin +'/signin'
        }catch(e){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Error making request.',
            })
        }
    }

    return (
        <>
            <div style={{height: '100vh'}} className="flex min-h-full">
            <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <img
                            style={{boarderRadius: '50%', margin: 'auto'}}
                            className="h-20 w-30"
                            src="/banner.jpg"
                            alt="Solis Credit"
                        />
                        <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">{title}</h2>
                    </div>

                    <div className="mt-8">
                        {!token &&
                            <div className="mt-6">
                                <div className="space-y-6">
                                    <div>
                                        <label htmlFor="token"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Token
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                value={text}
                                                onChange={(e) => {
                                                    setText(e.target.value)
                                                }}
                                                id="token"
                                                name="token"
                                                type="text"
                                                autoComplete="token"
                                                required
                                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            onClick={(e) => {
                                                getToken(e)
                                            }}
                                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            Confirm Token
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                        {token &&
                            <div className="mt-6">
                                <div className="space-y-6">
                                    <div>
                                        <label htmlFor="token"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Password
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                value={passwordInfo.password1}
                                                onChange={(e) => {
                                                    setPasswordInfo({...passwordInfo, password1: e.target.value})
                                                }}

                                                name="token"
                                                type="password"
                                                required
                                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="token"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Confirm Password
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                value={passwordInfo.password2}
                                                onChange={(e) => {
                                                    setPasswordInfo({...passwordInfo, password2: e.target.value})
                                                }}
                                                name="token"
                                                type="password"
                                                required
                                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            onClick={(e) => {
                                                savePassword(e)
                                            }}
                                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            Save Password
                                        </button>
                                    </div>

                                </div>
                            </div>
                        }
                    </div>
                    <div className={"mt-2"}>
                        <div className={"font-bold mb-2"}>Your sign-in instructions are as follows:</div>
                        <ol className={"ml-3"}>
                            <li className={"mb-2"}>1. You will be sent an email with an activation code.If you dont see
                                this
                                email inside your inbox please check the spam folder.
                            </li>
                            <li className={"mb-2"}>2. Inside the email, there is a link that you should click this link
                                will
                                take you to the platform where you should enter the activation code.
                            </li>
                            <li className={"mb-2"}>3. After entering the code, you will be prompted to enter your new
                                password twice.
                            </li>
                            <li className={"mb-2"}>4. After this, you can now sign into the platform using your email
                                address and password.
                            </li>
                            <li className={"mb-2 text-red-700"}>5. If your token has expired or is not working you can
                                you the forgot password to request another.
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="flex items-center justify-between pt-3">
                    <div className="text-sm">
                        <a href="/signin" className="font-medium text-indigo-600 hover:text-indigo-500">
                            Return to login
                        </a>
                    </div>
                </div>
            </div>
                <div className="relative hidden w-0 flex-1 lg:block">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                        alt=""
                    />
                </div>
            </div>
        </>
    )
}
