import { useState, useEffect } from "react";
import { setDeepValue } from "../../../util/util";
import Http from "../../../util/http";
import AccessControl from "../../../util/accessControl";
import Swal from "sweetalert2";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const delay = (delayInms) => {
  return new Promise((resolve) => setTimeout(resolve, delayInms));
};

export default function UserEdit() {
  const [user, setUser] = useState({
    telephone: "",
    email: "",
    firstName: "",
    lastName: "",
    position: "",
    registrationNumber: Date.now(),
  });
  const [organizations, setOrganizations] = useState([]);
  const [selectOrganization, setSelectOrganization] = useState({});
  const [branches, setBranches] = useState([]);
  const [adding, setAdding] = useState(false);
  const [roles, setRoles] = useState([]);
  const [current, setCurrent] = useState([]);
  const [returnUrl, setReturnUrl] = useState("");
  const [branchInfo, setBranchInfo] = useState({
    title: "",
    address: "",
    email: "",
    contactPerson: "",
    telephone: "",
    organization: "",
  });
  const [organizationInfo, setOrganizationInfo] = useState({
    name: "",
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (!queryParams.has("branch")) {
      window.location.href = `${window.location.origin}/dashboard/organizations`;
    }
    setReturnUrl(
      `${window.location.origin}/dashboard/users?organization=${queryParams.get(
        "organization"
      )}&branch=${queryParams.get("branch")}`
    );
    try {
      async function getData() {
        let dialog = Swal.fire({
          title: "Loading...",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
          willClose: () => {},
        });
        let userData = await Http.request("admin", "users/get-one", {
          id: window.location.pathname.split("/")[3],
        });
        var search = window.location.search.substring(1);
        search = JSON.parse(
          '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
          function (key, value) {
            return key === "" ? value : decodeURIComponent(value);
          }
        );
        if(!userData){
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "User was not found.",
          }).then(async (result)=>{
            await delay(1000);
            window.location.href = `${window.location.origin}/dashboard/organizations`;
          });
          
        }else{
          if (!userData.roles) {
            userData.roles = [];
          }
          if (typeof userData.telephone === "undefined") {
            userData.telephone = userData.phoneNumber;
          }
  
          let response = await Http.request("admin", "roles/get-all", {
            total: 0,
            page: 1,
            count: 0,
            perPage: 100000,
          });
  
          response.roles.forEach((role) => {
            userData.roles.forEach((id) => {
              if (role._id === id) {
                role.checked = true;
              }
            });
          });
          
         
          setUser(userData);

          setBranches(search.branch);
          let org = await Http.request('admin', 'organizations/get-one',{id: search.organization});
          if(org.type !== 'BFTTL Administration'){
             let idx = response.roles.findIndex(x => x.name === 'Master');
             response.roles.splice(idx, 1);
             setUser({...userData,position: 'regular'});
          }else{
              let idx = response.roles.findIndex(x => x.name === 'Master');
              response.roles = [response.roles[idx]];
              if(user.position !== 'god'){
                  setUser({...userData,position: 'master'});
              }
          }
          setSelectOrganization(org);
          setRoles(response.roles);
          dialog.close();
        }

      }

      getData();
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to make request to servers.",
      });
    }
    getBranchInfo(queryParams.get("branch"));
    getOrganizationInfo(queryParams.get("organization"));
  }, []);

  function setKey(key, value) {
    console.log({ key: key, value: value });
    let object = { ...user };

    setDeepValue(object, value, key);
    setUser(object);
  }

  /* function changeOrganization(value) {
        const selectedOrganization = organizations[value];
        setSelectOrganization(selectedOrganization)
        setBranches(selectedOrganization.branches);
        setKey('organization', value);
    }*/

  function redirect() {
    window.location.href = returnUrl;
  }

  function save(e) {
    //e.preventDefault();
    let dialog = Swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {},
    });
    try {
      let payload = { ...user };
      delete payload._id;
      Http.request("admin", "users/update-one", { id: user._id, user: payload })
        .then((response) => {
          dialog.close();
          Swal.fire({
            icon: "success",
            title: "Hurray!",
            text: "User updated successfully.",
          }).then(() => {
            redirect();
          });
        })
        .catch((err) => {
          dialog.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err || "Failed to make request to servers.",
          });
        });
    } catch (err) {
      console.log(err);
    }
  }

  function reset(e) {
    try {
      Http.request("admin", "users/send-reset", { id: user._id })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Hurray!",
            text: `Account reset information sent.\n Token sent is [ ${response.token} ]`,
          }).then(() => {
            //alert(response.token || "")
            //redirect();
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Failed to make request to servers.",
          });
        });
    } catch (err) {
      console.log(err);
    }
  }
  function invite(e) {
    try {
      Swal.fire({
        icon: "info",
        title: "Attention!",
        text: "Do you want to send out an account invitation?",
        showCancelButton: true,
        confirmButtonText: "Send",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Http.request("admin", "users/send-invite", { id: user._id })
            .then((response) => {
              Swal.fire({
                icon: "success",
                title: "Hurray!",
                text: `Account invitation information sent successfully.\nToken sent is [ ${response.token} ]`,
              }).then(() => {
                //alert(response.token || "")
                //redirect();
              });
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to make request to servers.",
              });
            });
        }
      });
    } catch (err) {
      console.log(err);
    }
  }
  function remove(e) {
    e.preventDefault();
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          Http.request("admin", "users/remove-one", { id: user._id })
            .then((response) => {
              console.log(response);
              Swal.fire({
                icon: "success",
                title: "Hurray!",
                text: "Permission removed successfully",
              }).then(() => {
                redirect();
              });
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err || "Failed to make request to servers.",
              });
            });
        }
      });
    } catch (err) {
      console.log("last", err);
    }
  }

  function getBranchInfo(id, showloader = true) {
    let dialog;
    if (showloader) {
      dialog = Swal.fire({
        title: "Loading...",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {},
      });
    }

    Http.request("admin", "branches/get-one", { id })
      .then((response) => {
        setBranchInfo(response);
        if (showloader) {
          dialog.close();
        }
      })
      .catch((e) => {
        if (showloader) {
          dialog.close();
        }
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to make request to servers.",
        });
      });
  }

  function getOrganizationInfo(id, showloader = true) {
    let dialog;
    if (showloader) {
      dialog = Swal.fire({
        title: "Loading...",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {},
      });
    }

    Http.request("admin", "organizations/get-one", { id })
      .then((response) => {
        setOrganizationInfo({ name: response.name });
        if (showloader) {
          dialog.close();
        }
      })
      .catch((e) => {
        if (showloader) {
          dialog.close();
        }
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to make request to servers.",
        });
      });
  }

  function assignRoles(role, index) {
    let data = { ...user };
    console.log({ role });
    let includes = user.roles.includes(role._id);
    if (!includes) {
      data.roles.push(role._id);
      let r = JSON.parse(JSON.stringify(roles));
      r[index].checked = true;
      setRoles(r);
      setUser(data);
    } else {
      let r = JSON.parse(JSON.stringify(roles));
      r[index].checked = false;
      setRoles(r);
      let idx = data.roles.indexOf(role._id);
      if (idx !== -1) {
        data.roles.splice(idx, 1);
      }
      setUser(data);
    }
  }

  const impersonate = async () => {
    try {
      let session = 30;
      if (session != null && session < 31) {
        let response = await Http.request("admin", "users/impersonate", {
          id: user._id,
          session,
        });
        let userbackup = {
          user: JSON.parse(localStorage.getItem("user")),
          token: localStorage.getItem("token"),
          geolocation: localStorage.getItem("geolocation"),
          organization: JSON.parse(localStorage.getItem("organization")),
          role: localStorage.getItem("role"),
        };
        localStorage.setItem("userbackup", JSON.stringify(userbackup));
        localStorage.setItem("role",response.role);
        localStorage.setItem("token", response.token);
        localStorage.setItem(
          "organization",
          JSON.stringify(response.organization)
        );
        localStorage.setItem("user", JSON.stringify(response.user));
        localStorage.setItem("impersonate", true);

        Swal.fire({
          icon: "success",
          title: "Hurray!",
          text: `Impersonation session has been established. You have ${session} minutes in the session.`,
        }).then(() => {
          window.location.href = window.location.origin + "/dashboard";
        });
      } else {
        Swal.fire({
          icon: "info",
          title: "Attention!",
          text: "Please check the length of your session",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <nav
        className="flex border-b border-gray-200 bg-white mb-5"
        aria-label="Breadcrumb"
      >
        <ol
          role="list"
          className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8"
        >
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <span className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                <b>Organization Name: </b>
                {organizationInfo?.name}
              </span>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <span className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                <b>Branch Name: </b>
                {branchInfo?.title}
              </span>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <span className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                <b>Branch Address: </b>
                {branchInfo?.address}
              </span>
            </div>
          </li>
        </ol>
      </nav>
      <div className="flex justify-center ">
        <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 w-full">
          <div>
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
                <div>
                  <h2 className="text-base font-semibold leading-6 text-gray-900">
                    User Information
                  </h2>
                  <p className="mt-1 text-sm text-gray-500">
                    Please ensure all fields are filled in correctly.
                  </p>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      First Name
                    </label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setKey("firstName", e.target.value);
                      }}
                      value={user.firstName}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Last Name
                    </label>
                    <input
                      type="text"
                      value={user.lastName}
                      onChange={(e) => {
                        setKey("lastName", e.target.value);
                      }}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="email-address"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email address
                    </label>
                    <input
                      value={user.email}
                      onChange={(e) => {
                        setKey("email", e.target.value?.toLowerCase());
                      }}
                      type="email"
                      name="email-address"
                      id="email-address"
                      autoComplete="email"
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="telephone"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Telephone
                    </label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setKey("telephone", e.target.value);
                      }}
                      value={user.telephone}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Status
                    </label>
                    <select
                      id="type"
                      name="type"
                      autoComplete="type"
                      value={user.status}
                      onChange={(e) => {
                        setKey("status", e.target.value);
                      }}
                      className="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      <option value={"active"}>Active</option>
                      <option value={"disabled"}>Disabled</option>
                    </select>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      User Role
                    </label>
                    <select
                      id="type"
                      name="type"
                      value={user?.roles?.[0]}
                      onChange={(e) => {
                        setKey("roles", [e.target.value]);
                      }}
                      className="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      {roles.map((role, index) => {
                        return <option value={role._id}>{role.name}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    redirect();
                  }}
                  type="button"
                  className="text-sm font-semibold leading-6 text-gray-900 mx-3"
                >
                  Cancel
                </a>
                <AccessControl
                  permission={"inviteUser"}
                  fallback={<></>}
                  target={["god"]}
                >
                  <button
                    onClick={(e) => {
                      invite(e);
                    }}
                    className="rounded-md mr-2 bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  >
                    Send Invite
                  </button>
                </AccessControl>
                {user.position !== "master" && user.position !== "god" && (
                  <AccessControl
                    permission={"impersonateUser"}
                    fallback={<></>}
                    target={["god"]}
                  >
                    <button
                      onClick={(e) => {
                        impersonate(e);
                      }}
                      className="rounded-md mr-2 bg-violet-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
                    >
                      Impersonate
                    </button>
                  </AccessControl>
                )}
                <AccessControl
                  fallback={<></>}
                  permission={"removeUser"}
                  target={["god"]}
                >
                  <button
                    onClick={(e) => {
                      remove(e);
                    }}
                    type="submit"
                    className="mx-2 inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                  >
                    Remove
                  </button>
                </AccessControl>
                <AccessControl
                  permission={"resetUser"}
                  fallback={<></>}
                  target={["god"]}
                >
                  <button
                    onClick={(e) => {
                      reset(e);
                    }}
                    className="rounded-md mr-2 bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                  >
                    Send Password Reset Link
                  </button>
                </AccessControl>

                <AccessControl
                  permission={"saveUser"}
                  fallback={<></>}
                  target={["god"]}
                >
                  <button
                    onClick={(e) => {
                      save(e);
                    }}
                    className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Save
                  </button>
                </AccessControl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
