import { useEffect, useState } from "react";
import Http from "../../../util/http";
import Swal from "sweetalert2";
import InvitationTile from "./tile";

export default function InvitationList() {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    Http.request("contracts", `contracts/invites`, {})
      .then((response) => {
        setDocuments(response); // Set the documents data after fetching
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An error occurred while fetching the data!",
        });
      });
  }, []);

  const removeDocument = (id) => {
    console.log("Removing document with id: ", id);
    setDocuments((prevDocuments) => prevDocuments.filter((doc) => doc._id !== id));
  };

  return (
    <div>
      {
        <>
          {documents.map((document, index) => (
            <InvitationTile key={index} document={document} index={index} removeDocument={removeDocument} />
          ))}
        </>
      }
    </div>
  );
}
