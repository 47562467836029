import moment from "moment";

function FormatNumber(str) {
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function nullTransform(val) {
  if (val === null || val === undefined) {
    return "";
  }
  return val;
}
export default function PreviewTable({ preview, type }) {
  return (
    <div className="mt-8 flow-root">
      <div className="mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="grid w-4/5 py-2 text-left sm:px-6 lg:px-8">
          <div>
            <div className="text-2xl font-bold leading-7 text-gray-900">
              Policy Break Down
            </div>
            {type === "commercial" && (
              <>
                <div className="grid pt-3">
                  <div className="text-lg leading-6 text-gray-900 w-100">
                    Interest Rate:
                    <span className="text-lg font-semibold leading-6  px-2 text-gray-900 w-100">
                      {preview.headers.interest || 0}%
                    </span>
                  </div>
                </div>
                <div className="grid pt-2">
                  <div className="text-lg leading-6 text-gray-900 w-100">
                    Interest Amount:
                    <span className="text-lg font-semibold leading-6  px-2 text-gray-900 w-100">
                      $
                      {FormatNumber(
                        nullTransform(
                          parseFloat(
                            preview.headers.totalInterest || 0
                          ).toFixed(2)
                        )
                      )}
                    </span>
                  </div>
                </div>
                <div className="grid pt-2">
                  <div className="text-lg leading-6 text-gray-900 w-100">
                    First Installment Amount:
                    <span className="text-lg font-semibold leading-6  px-2 text-gray-900 w-100">
                      {preview.data.length > 0 && (
                        <>
                          $
                          {FormatNumber(
                            nullTransform(
                              parseFloat(preview.data[0].instalment).toFixed(
                                2
                              ) || ""
                            )
                          )}
                        </>
                      )}
                      {preview.data.length === 0 && <>$0</>}
                    </span>
                  </div>
                </div>
              </>
            )}
            <div className="grid pt-2 mb-4">
              <div className="text-lg leading-6 text-gray-900 w-100">
                Monthly Installment:
                <span className="text-lg font-semibold leading-6  px-2 text-gray-900 w-100">
                  {preview.data.length > 0 && (
                    <>
                      $
                      {FormatNumber(
                        nullTransform(
                          parseFloat(preview.data[1].instalment).toFixed(2) ||
                            ""
                        )
                      )}
                    </>
                  )}
                  {preview.data.length === 0 && <>$0 starting</>}
                </span>
              </div>
            </div>
            <div className="grid">
                  <div className="text-lg leading-6 text-gray-900 w-100">
                    Total Premium Financed:
                    <span className="text-lg font-semibold leading-6  px-2 text-gray-900 w-100">
                      $
                      {FormatNumber(
                        nullTransform(
                          parseFloat(preview.headers.total || 0).toFixed(2)
                        )
                      )}
                    </span>
                  </div>
            </div>
          </div>
        </div>
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Payment Date
                  </th>
                  {type === "commercial" && (
                    <>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Principal
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Interest
                      </th>
                    </>
                  )}
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Total Installment
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Balance
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {preview?.data.map((p, i) => (
                  <tr key={i}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-6">
                      {moment(p.dueDate).format().substring(0, 10)}
                    </td>
                    {type === "commercial" && (
                      <>
                        <td className="whitespace-nowrap px-3 py-4 text-left text-sm text-gray-500">
                          ${FormatNumber(parseFloat(p.principal).toFixed(2))}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-left text-sm text-gray-500">
                          ${FormatNumber(parseFloat(p.interest).toFixed(2))}
                        </td>
                      </>
                    )}
                    <td className="whitespace-nowrap px-3 py-4 text-left text-sm text-gray-500">
                      ${FormatNumber(parseFloat(p.instalment).toFixed(2))}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-left text-sm text-gray-500">
                      ${FormatNumber(parseFloat(p.balance).toFixed(2))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
