import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import {setDeepValue} from "../util/util";
import Http from "../util/http";
import AccessControl from "../util/accessControl";
import * as React from "react";
import Swal from "sweetalert2";


export default function InAppAnnouncementModal({ open, setOpen, id, type }) {
    const [modalTheme, setModalTheme] = useState({
        dismissBtn:""
    });
    const [detail,setDetail] = useState({title:"", content:""})
    const detailId = id;
    async function getInformation(){
        try {
            let details = await Http.request('admin', 'notifications/get-details', {id:detailId})
            setDetail(details);
        }
        catch(err){
            await Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err || 'Failed to make request to servers.',
            })
        }
    }

    const cancelButtonRef = useRef(null)
    useEffect(() => {
        switch (type) {
            case "info": {
                setModalTheme({
                    dismissBtn: "mt-3 inline-flex w-full justify-center rounded-md bg-blue-800 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-blue-600 sm:col-start-1 sm:mt-0"
                });
                break;
            }
            case "success": {
                setModalTheme({
                    dismissBtn: "mt-3 inline-flex w-full justify-center rounded-md bg-green-800 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-green-600 sm:col-start-1 sm:mt-0"
                });
                break;
            }
            case "warning": {
                setModalTheme({
                    dismissBtn: "mt-3 inline-flex w-full justify-center rounded-md bg-yellow-800 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-yellow-600 sm:col-start-1 sm:mt-0"
                });
                break;
            }
            case "critical": {
                setModalTheme({
                    dismissBtn: "mt-3 inline-flex w-full justify-center rounded-md bg-red-800 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-600 sm:col-start-1 sm:mt-0"
                });
                break;
            }
        }
        getInformation().then(() => {

        });
    }, [])

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <svg className="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg"
                                         fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                                    </svg>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h1" className="text-2xl font-semibold leading-6 text-gray-900">
                                            {detail.title}
                                        </Dialog.Title>
                                        <div className="mt-10">
                                            <div dangerouslySetInnerHTML={{__html:detail.content}} className="col-span-6 sm:col-span-3">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <button
                                        type="button"
                                        className={modalTheme.dismissBtn}
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}