/* eslint-disable jsx-a11y/no-redundant-roles */
import { Fragment, useState, useEffect } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { XMarkIcon, BellIcon } from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon, InboxIcon } from "@heroicons/react/20/solid";
import Http from "../../util/http";
import NotificationTile from "./notifcationTile";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Notifications() {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(2);
  const [notifications, setNotifications] = useState([]);
  const [current, setCurrent] = useState("new");
  const [tabs, setTabs] = useState([
    { name: "New", href: "#", current: true },
   // { name: "Actioned", href: "#", current: false },
  ]);

  async function getNotifications() {
    try {
      let results = await Http.request("admin", "get-alerts", {});
       setNotifications(results.alerts);
       return;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async function dismiss(index) {
    try {
      let results = await Http.request("admin", "dismiss-alerts", {
        id: notifications[index]._id,
      });
      let noti = [...notifications];
      noti.splice(index, 1);
      setNotifications(noti);
    } catch (e) {}
  }
  function tabClick(index) {}

  useEffect(() => {
    //Implementing the setInterval method
    getNotifications();

    //Clearing the interval
  }, []);

  return (
    <>
      <button
        onClick={() => {
          setOpen(true);
        }}
        type="button"
        className="relative -m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
      >
        <span className="sr-only">View notifications</span>
        <BellIcon className="h-6 w-6" aria-hidden="true" />
        {notifications.length > 0 && (
          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            {notifications.length}
          </span>
        )}
      </button>
      <Transition show={open}>
        <Dialog className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="p-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Notifications
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="border-b border-gray-200">
                        <div className="px-6">
                          <nav className="-mb-px flex space-x-6">
                            {tabs.map((tab, index) => (
                              <a
                                key={tab.name}
                                href={tab.href}
                                onClick={() => {
                                  setCurrent(tab.name.toLowerCase());
                                }}
                                className={classNames(
                                  tab.name.toLowerCase() === current
                                    ? "border-indigo-500 text-indigo-600"
                                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                  "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
                                )}
                              >
                                {tab.name}
                              </a>
                            ))}
                          </nav>
                        </div>
                      </div>
                      <ul
                          style={{maxHeight: "100vh",    msOverflowStyle: 'none', scrollbarWidth: 'none',}}
                        role="list"
                        className="flex-1 divide-y divide-gray-200 overflow-y-auto min-h-4"
                      >
                        {current === "new" && (
                          <>
                            {notifications
                              .filter((x) => x.isActive === true)
                              .map((notification, index) => (
                                <NotificationTile
                                    key={index}
                                  notification={notification}
                                  index={index}
                                  dismiss={dismiss}
                                />
                              ))}
                          </>
                        )}
                        {current === "actioned" && (
                          <>
                            {notifications
                              .filter((x) => x.isActive === false)
                              .map((notification, index) => (
                                <NotificationTile
                                  notification={notification}
                                  index={index}
                                  key={index}
                                  dismiss={dismiss}
                                  hideActions={true}
                                />
                              ))}
                          </>
                        )}
                      </ul>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
