import { useEffect, useState, Fragment } from "react";
import PaymentDateModal from "./paymentDateModal";

export default function TableRow({ pySch, index, currentContract }) {
  const [overdraft, setOverdraft] = useState(false);
  const [record, setRecordInfo] = useState({});
  const [prepaid, setPrePaid] = useState(false);
  const [due, setDue] = useState(0);


  useEffect(() => {
    if (
      currentContract?.loan?.installments &&
      currentContract?.loan?.installments.length > 0
    ) {
      let instalment = currentContract?.loan?.installments[index];

      if (!instalment.paid) {
        let _due =
          instalment.principalDue +
          instalment.interestDue +
          instalment.feeDue +
          instalment.penaltyDue;

        if (["refunded", "scheduled"].includes(instalment.status)) {
          setDue(instalment.instalment);
        } else {
          setDue(_due);
        }
      }
      if (!["scheduled", "paid"].includes(instalment.status)) {
        console.log({ instalment });
        let paid = instalment.principalPaid + instalment.interestPaid;

        if (paid < instalment.instalment) {
          setOverdraft(true);
          setRecordInfo(instalment);
          console.log("overdue found", pySch.dueDate.substring(0, 10));
        }
      } else if (
        instalment.status === "scheduled" &&
        instalment.totalPaid > 0
      ) {
        setPrePaid(true);
        setRecordInfo(instalment);
      }
    }
  }, []);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function getPaymentScheduleStatus(status) {
    const mainStyle =
      "inline-flex items-center rounded-full px-7 py-1 text-xs text-white";
    console.log(status);
    const st = {
      paid: {
        name: "Paid",
        style: mainStyle + " bg-lime-500",
      },
      late: {
        name: "Late",
        style: mainStyle + " bg-red-600",
      },
      due: {
        name: "Due",
        style: mainStyle + " bg-cyan-600",
      },
      scheduled: {
        name: "Scheduled",
        style: "inline-flex items-center text-sm text-gray-500",
      },
    };
    return st[status]
      ? st[status]
      : {
          name: "Scheduled",
          style: "inline-flex items-center text-sm text-gray-500",
        };
  }
  function nullTransform(val) {
    if (val === null || val === undefined) {
      return "";
    }
    return val;
  }
  function FormatNumber(str) {
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      <tr className="even:bg-gray-50 border-t-2">
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 flex">
          {pySch.dueDate.substring(0, 10)}
          <PaymentDateModal index={index} contract={currentContract} installment={currentContract?.loan?.installments[index]}/>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          $
          {FormatNumber(
            nullTransform(parseFloat(pySch.principal || 0).toFixed(2))
          )}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          $
          {FormatNumber(
            nullTransform(parseFloat(pySch.interest || 0).toFixed(2))
          )}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          $
          {currentContract?.loan?.installments !== undefined &&
            currentContract?.loan?.installments.length > 0 && (
              <>
                {FormatNumber(
                  nullTransform(
                    parseFloat(
                      currentContract?.loan?.installments[index]?.fee || 0
                    ).toFixed(2)
                  )
                )}
              </>
            )}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          $
          {currentContract?.loan?.installments !== undefined &&
            currentContract?.loan?.installments.length > 0 && (
              <>
                {FormatNumber(
                  nullTransform(
                    parseFloat(
                      currentContract?.loan?.installments[index]?.penalty || 0
                    ).toFixed(2)
                  )
                )}
              </>
            )}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          $
          {currentContract?.loan?.installments !== undefined &&
            currentContract?.loan?.installments.length > 0 && (
              <>
                {FormatNumber(
                  nullTransform(
                    parseFloat(
                      currentContract?.loan?.installments[index]
                        ?.principalPaid +
                        currentContract?.loan?.installments[index]
                          ?.interestPaid +
                        currentContract?.loan?.installments[index]?.feePaid +
                        currentContract?.loan?.installments[index]
                          ?.penaltyPaid || 0
                    ).toFixed(2)
                  )
                )}
              </>
            )}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          $
          {currentContract?.loan?.installments !== undefined &&
            currentContract?.loan?.installments.length > 0 && (
              <>
                <>
                  { currentContract.loan?.installments[index].paid === true &&
                    <>
                      {FormatNumber(
                            nullTransform(
                              parseFloat(
                                currentContract?.loan?.installments[index]
                                  ?.totalDue || 0).toFixed(2)
                            )
                      )}
                    </>
                  }
                </>
                {currentContract.loan?.installments[index].paid === false && (
                  <>
                    {FormatNumber(
                      nullTransform(parseFloat(overdraft=== false ? (due|| 0): 0).toFixed(2))
                    )}
                  </>
                )}
              </>
            )}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          $
          {currentContract?.loan?.installments !== undefined &&
            currentContract?.loan?.installments.length > 0 && (
              <>
                {FormatNumber(
                  nullTransform(parseFloat(pySch.balance || 0).toFixed(2))
                )}
              </>
            )}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm">
          {currentContract?.loan?.installments !== undefined &&
            currentContract?.loan?.installments.length > 0 && (
              <>
                <span
                  className={
                    getPaymentScheduleStatus(
                      currentContract?.loan?.installments[index]?.status
                    ).style
                  }
                >
                  {currentContract?.loan?.installments[index]?.status}
                </span>
              </>
            )}
        </td>
      </tr>
      {!currentContract?.loan?.installments[index]?.paid && (
        <>
          {overdraft && (
            <Fragment key={index}>
              <tr className={"border-gray-300 bg-red-500 border-t font-bold"}>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                  (Instalment OverDue)
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                  $
                  {FormatNumber(
                    nullTransform(
                      parseFloat(record.principalDue || 0).toFixed(2)
                    )
                  )}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                  $
                  {FormatNumber(
                    nullTransform(
                      parseFloat(record.interestDue || 0).toFixed(2)
                    )
                  )}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                  $
                  {FormatNumber(
                    nullTransform(parseFloat(record.feeDue).toFixed(2))
                  )}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                  $
                  {FormatNumber(
                    nullTransform(parseFloat(record.penaltyDue).toFixed(2))
                  )}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                  ${FormatNumber(nullTransform(parseFloat(0).toFixed(2)))}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                  $
                  {FormatNumber(
                    nullTransform(
                      parseFloat(
                        currentContract?.loan?.installments[index]?.totalDue ||
                          0
                      ).toFixed(2)
                    )
                  )}
                </td>
                <td
                  colSpan={1}
                  className="whitespace-nowrap px-3 py-4 text-sm text-white"
                ></td>
                <td className="whitespace-nowrap px-3 py-4 text-sm">
                  <span colSpan={1}></span>
                </td>
              </tr>
            </Fragment>
          )}
          {prepaid && (
            <Fragment key={index}>
              <tr className={"border-gray-300 border-t border-b"}>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  (Paid)
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  $
                  {FormatNumber(
                    nullTransform(
                      parseFloat(record.principalPaid || 0).toFixed(2)
                    )
                  )}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  $
                  {FormatNumber(
                    nullTransform(
                      parseFloat(record.interestPaid || 0).toFixed(2)
                    )
                  )}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  $
                  {FormatNumber(
                    nullTransform(parseFloat(record.feePaid).toFixed(2))
                  )}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  $
                  {FormatNumber(
                    nullTransform(parseFloat(record.penaltyPaid).toFixed(2))
                  )}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  ${FormatNumber(nullTransform(parseFloat(0).toFixed(2)))}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  $
                  {FormatNumber(
                    nullTransform(parseFloat(record.totalPaid).toFixed(2))
                  )}
                </td>
                <td
                  colSpan={1}
                  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                ></td>
                <td className="whitespace-nowrap px-3 py-4 text-sm">
                  <span colSpan={1}></span>
                </td>
              </tr>
              <tr className={"border-gray-300 border-b font-bold"}>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  (Balance not-due)
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  $
                  {FormatNumber(
                    nullTransform(
                      parseFloat(record.principalBalance || 0).toFixed(2)
                    )
                  )}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  $
                  {FormatNumber(
                    nullTransform(
                      parseFloat(record.interestBalance || 0).toFixed(2)
                    )
                  )}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  $
                  {FormatNumber(
                    nullTransform(parseFloat(record.feeBalance).toFixed(2))
                  )}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  $
                  {FormatNumber(
                    nullTransform(parseFloat(record.penaltyBalance).toFixed(2))
                  )}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  ${FormatNumber(nullTransform(parseFloat(0).toFixed(2)))}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  $
                  {FormatNumber(
                    nullTransform(
                      parseFloat(record.totalBalance || 0).toFixed(2)
                    )
                  )}
                </td>
                <td
                  colSpan={1}
                  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                ></td>
                <td className="whitespace-nowrap px-3 py-4 text-sm">
                  <span colSpan={1}></span>
                </td>
              </tr>
            </Fragment>
          )}
        </>
      )}
    </>
  );
}
