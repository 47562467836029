import { useEffect } from "react";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (){

    useEffect(() => {
        // function addInfoBip(){
        //     (function(I,n,f,o,b,i,p){ I[b]=I[b]||function(){(I[b].q=I[b].q||[]).push(arguments)};
        //     I[b].t=1*new Date();i=n.createElement(f);i.async=1;i.src=o;
        //     p=n.getElementsByTagName(f)[0];p.parentNode.insertBefore(i,p)})
        //     (window,document,'script','https://livechat.infobip.com/widget.js','liveChat');
        //         // eslint-disable-next-line no-undef
        //         liveChat('init', 'dc7fb0d1-2d2a-4256-9a85-21e12ff466c9');
        // }
        // addInfoBip();
    }, [])
    return (
        <div>
        </div>
    )
}