import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Http from "../util/http";

export default function Verify() {
    const [code, setCode] = useState("");
    const [title, setTitle] = useState("Attempting to verify your email...");
    const [verified, setVerified] = useState(false);

    async function confirm() {
        try {
            let response = await axios.post(
                `${process.env.REACT_APP_IDENTITY_API}/api`,
                {
                    target: "clients/verify",
                    payload: { code: window.location.search.replace("?code=", "") },
                }
            );
            setTitle("Email verification successful.");
            setVerified(true);
        } catch (e) {
            console.log(e);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "An error occurred while trying to reset account.",
            });
        }
    }

    useEffect(() => {
        try {
            if (!verified) {
                confirm();
            }
        } catch (e) {
            console.log(e);
            Swal.fire(
                "Error!",
                "An error occurred while trying to verify your email.",
                "error"
            );
        }
    }, []);
    return (
        <>
            <div style={{ height: "100vh" }} className="flex min-h-full">
                <div className="flex px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <img
                                style={{ boarderRadius: "50%", margin: "auto" }}
                                className="h-20 w-30"
                                src="/banner.jpg"
                                alt="Solis Credit"
                            />
                            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                                {title}
                            </h2>
                        </div>

                        <div className="mt-8"></div>
                    </div>
                </div>
                <div className="relative hidden w-0 flex-1 lg:block">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                        alt=""
                    />
                </div>
            </div>
        </>
    );
}