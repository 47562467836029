import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  UsersIcon,
  MapIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook } from "@fortawesome/free-solid-svg-icons";
import Http from "../../util/http";


export default function EasyAddress(props) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [address, setAddress] = useState(null);
  const [addresses, setAddresses] = useState([]);

function search (text){
    setQuery(text);
    try
    {
        let body ={
            text: text,
        }
        Http.request("admin", "get-address", body).then((response)=>{
            //console.log({response})
            setAddresses(response);
        })
    }catch(e){
        console.log(e);
    }
}
  return (
    <>
    {/*
          <button
        onClick={() => {
          setOpen(true);
        }}
        type="button"
        className="ml-3 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Easy Address
        <FontAwesomeIcon
          icon={faAddressBook}
          className="font-medium text-2xl leading-7"
          style={{ color: "white" }}
        />
      </button> */

    }

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel style={{maxWidth: "52rem"}} className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <MapIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-2xl font-semibold leading-6 text-gray-900"
                      >
                        Easy Address
                      </Dialog.Title>
                      <div>
                        {/*                        <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Search candidates
                        </label> */}
                        <div className="mt-4 flex rounded-md shadow-sm">
                          <div className="relative flex flex-grow items-stretch focus-within:z-10">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <MagnifyingGlassIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </div>
                            <input
                              value={query}
                              onChange={(e)=>search(e.target.value)}
                              type="text"
                              name="text"
                              id="text"
                              className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder="Enter address here"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="py-3">
                        <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          <ul
                            style={{overflowY: "scroll",maxHeight: "180px"}}
                            role="list"
                            className="divide-y divide-gray-100 rounded-md border border-gray-200"
                          >
                            {
                                addresses.map((item,index)=>{
                                   return(
                                    <li onClick={()=>{
                                        props.setEasyAddress(item);
                                        setOpen(false);
                                    }} className="flex items-center justify-between py-2 text-sm leading-6">
                                    <div className="flex w-0 flex-1 items-center">
                                      <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                        <span
                                          style={{ cursor: "pointer" }}
                                          className="truncate font-medium text-sm"
                                        >
                                          {item.shortFormattedAddress}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                   )
                                })
                            }
                          </ul>
                        </dd>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-slate-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
