import { useState,useEffect } from 'react'
import {setDeepValue} from '../../../util/util'
import Http from '../../../util/http'
import Swal from 'sweetalert2'
import AccessControl from '../../../util/accessControl'
import moment from 'moment'
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function isEmpty(value){
    return (value == null || value.length === 0);
  }

export default function AuditEdit() {

  const [audit,setAudit] =  useState({
    description: '',
    name: '',
    text: '',
    status: 'active',
  })

  useEffect(() => {
    let dialog = Swal.fire({
      title: 'Loading...',
      timerProgressBar: true,
      allowOutsideClick: false, 
      didOpen: () => {
        Swal.showLoading()
      },
      willClose: () => {
      }
    })
    Http.request('admin','audits/get-one',{id: window.location.pathname.split("/")[3]}).then((response)=>{
        response.org = window.location.search.replace('?org=','')
        setAudit(response)
        dialog.close()
    }).catch((e)=>{
      console.log(e)
      dialog.close()
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Failed to make request to servers.',
      })
    })
  }, [])

  function setKey(key,value) {
    console.log({key:key,value:value})
    let object = {... audit}

    setDeepValue(object,value,key)
    setAudit(object)
  }

  return (
    <div className="shadow sm:overflow-hidden sm:rounded-md  ">
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <div >
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
              <div>
                <h2 className="text-base font-semibold leading-6 text-gray-900">Audit Information</h2>
                <p className="mt-1 text-sm text-gray-500">Information is readonly.</p>
              </div>

              <div className="grid grid-cols-6 gap-10">
                <div className="col-span-6">
                  <label  className="block text-sm font-medium leading-6 text-gray-900">
                    User
                  </label>
                  <input
                    type="text"
                    disabled
                    onChange={(e)=>{setKey('userName', e.target.value)}}
                    value={audit.userName}
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <div className="col-span-6">
                  <label  className="block text-sm font-medium leading-6 text-gray-900">
                    Title
                  </label>
                  <textarea
                    rows={2}
                    type="text"
                    disabled
                    onChange={(e)=>{setKey('title', e.target.value)}}
                    value={audit.title}
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>



                <div className="col-span-6">
                  <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                    Sub-Title
                  </label>
                  <textarea
                    rows={3}
                    type="text"
                    name="description"
                    id="description"
                    disabled
                    value={audit.subtitle}
                    onChange={(e)=>{setKey('subtitle', e.target.value)}}
                    autoComplete="subtitle"
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label  className="block text-sm font-medium leading-6 text-gray-900">
                    Organization
                  </label>
                  <input
                    type="text"
                    disabled
                    value={audit.org}
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label  className="block text-sm font-medium leading-6 text-gray-900">
                    Created At
                  </label>
                  <input
                    type="text"
                    disabled
                    value={moment(new Date(audit.createdAt)).format('MMMM Do YYYY, h:mm:ss a')}
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
              <a href='/dashboard/audits'  type="button" className="text-sm font-semibold leading-6 text-gray-900 mx-3">Cancel</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


