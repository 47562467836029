
export default function Teams({team}) {
  return (
    <div className="mx-auto mt-8 max-w-5xl px-4 pb-12 sm:px-6 lg:px-8">
    <h2 className="text-sm font-medium text-gray-500">Team members</h2>
    <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
      {team.map((person) => (
        <div
          key={person.handle}
          className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400"
        >
          <div className="shrink-0">
            <img alt="" src={person.imageUrl} className="size-10 rounded-full" />
          </div>
          <div className="min-w-0 flex-1">
            <a href="#" className="focus:outline-none">
              <span aria-hidden="true" className="absolute inset-0" />
              <p className="text-sm font-medium text-gray-900">{person.name}</p>
              <p className="truncate text-sm text-gray-500">{person.role}</p>
            </a>
          </div>
        </div>
      ))}
    </div>
  </div>
  );
}