"use client";
import React, { useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Dialog } from "@headlessui/react";

export default function ImageCropper({ open, setOpen, preview,croppedImage, setCroppedImage,handleUpload }) {
  const cropperRef = useRef(null);
  // Handler for cropping the image
  const handleCrop = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const img = cropper.getCroppedCanvas().toDataURL(); // Get the cropped image as a base64 string
      alert("Image cropped successfully!");
      console.log("Cropped Image Data:", img);
      setCroppedImage(img);
    }
  };

  async function onClose() {
    setOpen(false);
    setCroppedImage(null);
  }

  return (
    <Dialog open={open} onClose={onClose} className="relative z-10">
      <Dialog.Backdrop
        transition="true"
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Dialog.Panel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-2xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div
                className="mx-auto flex items-center justify-center rounded-full bg-green-100"
                style={{ width: "3rem", height: "3rem" }}
              >
                <i
                  className="fas fa-crop text-green-600"
                  style={{ fontSize: "1.5rem" }}
                ></i>
              </div>

              <div className="mt-3 text-center sm:mt-5">
                <Dialog.Title
                  as="h3"
                  className="text-base font-semibold text-gray-900"
                >
                  Crop Your Image
                </Dialog.Title>
                <div className="mt-2">
                  {!croppedImage && (
                    <Cropper
                      ref={cropperRef}
                      src={preview} // Example image URL
                      style={{ height: 400, width: "100%" }}
                      aspectRatio={NaN} // Allow free resizing
                      guides={true} // Show guides while cropping
                    />
                  )}
                  {croppedImage && (
                    <img src={croppedImage} style={{ maxHeight: "100%",margin: 'auto' }} />
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              {!croppedImage && (
                <button
                  type="button"
                  onClick={handleCrop}
                  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                >
                  Crop
                </button>
              )}
               {croppedImage && (
                <button
                  type="button"
                  onClick={handleUpload}
                  className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:col-start-2"
                >
                  Upload
                </button>
              )}
              <button
                type="button"
                data-autofocus
                onClick={() => setOpen(false)}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              >
                Cancel
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
