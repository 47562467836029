import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import {setDeepValue} from "../util/util";
import Http from "../util/http";
import AccessControl from "../util/accessControl";
import TelephoneInput from './dashboard/telephoneInput';


export default function CommercialClientEditModal({open, setOpen, action, commUser, setCommUser, save }) {
    //const [open, setOpen] = useState(true)

    const cancelButtonRef = useRef(null)
    const [countries, setCountries] = useState([]);
    useEffect(() => {
        Http.request('admin', 'countries',[]).then((response) => {
            setCountries(response);
            setKey('businessAddressCountry', JSON.parse(localStorage.getItem('geolocation')).code )
        });
    }, [])

    function setKey(key, value) {
        let object = {...commUser}

        setDeepValue(object, value, key)
        setCommUser(object)
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                        <PencilSquareIcon className="h-6 w-6 text-white-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Manage Commercial Client
                                        </Dialog.Title>
                                        <div className="mt-10">
                                            <div className="col-span-6 sm:col-span-3">
                                                {action === 1 && (
                                                    <div
                                                        className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Authorized Officer First Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => {
                                                                setKey('authorizerFirstName', e.target.value)
                                                            }}
                                                            value={commUser.authorizerFirstName}
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Authorized Officer Last Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => {
                                                                setKey('authorizerLastName', e.target.value)
                                                            }}
                                                            value={commUser.authorizerLastName}
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                )}

                                                {action === 2 && (
                                                    <div
                                                        className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Company Registration Number
                                                        </label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => {
                                                                setKey('businessRegistrationNumber', e.target.value)
                                                            }}
                                                            value={commUser.businessRegistrationNumber}
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                )}

                                                {action === 3 && (
                                                    <div
                                                        className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Description
                                                        </label>
                                                        <textarea
                                                            onChange={(e) => {
                                                                setKey('businessDescription', e.target.value)
                                                            }}
                                                            value={commUser.businessDescription} cols="30" rows="10"
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        >
                                                        </textarea>
                                                    </div>
                                                )}

                                                {action === 4 && (
                                                    <div
                                                        className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                        <div className="col-span-1 sm:col-span-1">
                                                            <input
                                                                required
                                                                type="text"
                                                                onChange={(e) => {
                                                                    setKey('businessAddressFirstLine', e.target.value)
                                                                }}
                                                                value={commUser.businessAddressFirstLine}
                                                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            />
                                                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                                                Address - First Line
                                                            </label>
                                                        </div>
                                                        <div className="col-span-1 sm:col-span-1">
                                                            <input
                                                                type="text"
                                                                onChange={(e) => {
                                                                    setKey('businessAddressSecondLine', e.target.value)
                                                                }}
                                                                value={commUser.businessAddressSecondLine}
                                                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            />
                                                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                                                Address - Second Line
                                                            </label>
                                                        </div>
                                                        <div className="col-span-1 sm:col-span-1">
                                                            <input
                                                                required
                                                                type="text"
                                                                onChange={(e) => {
                                                                    setKey('businessAddressCity', e.target.value)
                                                                }}
                                                                value={commUser.businessAddressCity}
                                                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            />
                                                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                                                Address - City or Town
                                                            </label>
                                                        </div>
                                                        <div className="col-span-1 sm:col-span-1">
                                                            <input
                                                                required
                                                                type="text"
                                                                onChange={(e) => {
                                                                    setKey('businessAddressState', e.target.value)
                                                                }}
                                                                value={commUser.businessAddressState}
                                                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            />
                                                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                                                Address - State or Province
                                                            </label>
                                                        </div>
                                                        {/* <div className="col-span-1 sm:col-span-1">
                                                            <select
                                                                required
                                                                id="current-tab"
                                                                name="current-tab"
                                                                onChange={(e) => {
                                                                    setKey('businessAddressCountry',e.target.value)
                                                                }}
                                                                value={commUser.businessAddressCountry}
                                                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            >
                                                                <option value=""> Select a Country </option>
                                                                {countries.map(item => <option key={item.code} value={item.code}> {item.title} </option> )}
                                                            </select>
                                                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                                                Address - Country
                                                            </label>
                                                        </div> */}
                                                    </div>
                                                )}

                                                {action === 5 && (
                                                    <div
                                                        className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Email Address
                                                        </label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => {
                                                                setKey('businessEmailAddress', e.target.value)
                                                            }}
                                                            value={commUser.businessEmailAddress}
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                )}

                                                {action === 6 && (
                                                    <div
                                                        className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Business Phone # 1
                                                        </label>
                                                        <TelephoneInput setKey={setKey} path={'businessPhoneNumberOne'} value={commUser.businessPhoneNumberOne} className={"mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"}/>
                                                    </div>
                                                )}

                                                {action === 7 && (
                                                    <div
                                                        className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Business Phone # 2
                                                        </label>
                                                        <TelephoneInput setKey={setKey} path={'businessPhoneNumberTwo'} value={commUser.businessPhoneNumberTwo} className={"mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"}/>
                                                    </div>
                                                )}

                                                {action === 8 && (
                                                    <div
                                                        className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Business Fax Phone
                                                        </label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => {
                                                                setKey('businessFaxNumber', e.target.value)
                                                            }}
                                                            value={commUser.businessFaxNumber}
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                )}

                                                {action === 9 && (
                                                    <div
                                                        className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Payment Type
                                                        </label>
                                                        <select
                                                            id="type"
                                                            name="type"
                                                            autoComplete="type"
                                                            onChange={(e) => {
                                                                setKey('paymentInfoType', e.target.value)
                                                            }}
                                                            value={commUser.paymentInfoType}
                                                            className="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        >
                                                            <option value="bank"> -- BANK --</option>
                                                            <option value="cash"> -- CASH --</option>
                                                        </select>
                                                    </div>
                                                )}

                                                {action === 10 && (
                                                    <div
                                                        className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Bank Account Type
                                                        </label>
                                                        <select
                                                            id="type"
                                                            name="type"
                                                            autoComplete="type"
                                                            onChange={(e) => {
                                                                setKey('paymentInfoAccountType', e.target.value)
                                                            }}
                                                            value={commUser.paymentInfoAccountType}
                                                            className="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        >
                                                            <option value="savings"> -- SAVINGS --</option>
                                                            <option value="chequering"> -- CHEQUERING --</option>
                                                        </select>
                                                    </div>
                                                )}

                                                {action === 11 && (
                                                    <div
                                                        className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Bank Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => {
                                                                setKey('paymentInfoBankName', e.target.value)
                                                            }}
                                                            value={commUser.paymentInfoBankName}
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                )}

                                                {action === 12 && (
                                                    <div
                                                        className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Bank Account Number
                                                        </label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => {
                                                                setKey('paymentInfoAccountNumber', e.target.value)
                                                            }}
                                                            value={commUser.paymentInfoAccountNumber}
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                )}

                                                {action === 13 && (
                                                    <div
                                                        className="block rounded-lg bg-gray-200 p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Status
                                                        </label>
                                                        <select
                                                            id="type"
                                                            name="type"
                                                            autoComplete="type"
                                                            onChange={(e) => {
                                                                setKey('status', e.target.value)
                                                            }}
                                                            value={commUser.status}
                                                            className="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        >
                                                            <option value="active"> -- Active --</option>
                                                            <option value="disabled"> -- Disabled --</option>
                                                        </select>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <AccessControl permission={'updateCommercialClient'} fallback={<></>} target={['god']}>
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                            onClick={() => save(action,commUser)}
                                        >
                                            Save
                                        </button>
                                    </AccessControl>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
