import React, {Fragment, useEffect, useState} from 'react'
import { Menu, Transition } from '@headlessui/react'
import {
    ArchiveBoxIcon,
    ArrowRightCircleIcon,
    ChevronDownIcon,
    DocumentDuplicateIcon,
    HeartIcon,
    PencilSquareIcon,
    TrashIcon,
    UserPlusIcon,
} from '@heroicons/react/20/solid'
import Swal from "sweetalert2";
import Http from "../util/http";
import AccessControl from "../util/accessControl";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function LoanCancellationActions({id, contractId, contractType, contractClient }) {
    async function cancel(loanId){
        await Swal.fire({
            title: 'Loan Cancellation Requested!',
            text: "This action will cancel the current loan selected. Are you sure?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Cancel It!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {

                    let response = await Http.request('contracts', 'loan/cancel', {id:loanId});
                    Swal.fire({
                        icon: 'success',
                        title: 'Hurray!',
                        text: 'Loan cancelled successfully',
                    }).then(() => {
                        window.location.reload();
                    });
                }
                catch(e){
                    await Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Failed to cancel selected loan',
                    })
                }
            }
        })
    }

    async function refund(loanId){
        await Swal.fire({
            title: 'Loan Refund Requested!',
            text: "This action will mark the loan as refund issued. Are you sure?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Refund It!'
        }).then(async (result) => {
            if(result.isConfirmed){
                try {
                    let {value} = await Swal.fire({
                        title: 'Enter Total You will like to refund',
                        input: 'text',
                        inputLabel: 'Enter Total You will like to refund',
                        inputPlaceholder: 'Enter Total You will like to refund'
                    })
                    value = parseFloat(value)
                    if(value === 0){
                        return alert('Refund total cannot be 0')
                    }
                    let response = await Http.request('contracts', `loans/refund/${loanId}`, {amount: value});
                    Swal.fire({
                        icon: 'success',
                        title: 'Hurray!',
                        text: 'Loan refunded successfully',
                    }).then(() => {
                        window.location.reload();
                    });
                }
                catch(e){
                    await Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Failed to refund selected loan',
                    })
                }
            }
        })
    }
    function openLoan(contract){
        let purpose = contractType
        if(purpose === "insurance-premium-financing"){
            purpose = "retail"
        }

        window.location.href = `${window.location.origin}/dashboard/clients/${purpose}/profile/${contractClient}/${contractId}`;
    }
    useEffect(() => {

    },[]);

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    Options
                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items style={{top: "-100px"}} className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {/*
                                                <Menu.Item>
                            {({ active }) => (
                                <AccessControl permission={'cancelLoan'} fallback={<></>} target={['god']}>
                                    <a
                                        onClick={() => {
                                            cancel(id);
                                        }}
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'group flex items-center px-4 py-2 text-sm'
                                        )}
                                    >
                                        <PencilSquareIcon
                                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                            aria-hidden="true"
                                        />
                                        Cancel
                                    </a>
                                </AccessControl>
                            )}
                        </Menu.Item>
                        */

                        }
                        <Menu.Item>
                            {({ active }) => (
                                <AccessControl permission={'refundLoan'} fallback={<></>} target={['god']}>
                                    <a
                                        onClick={() => {
                                            refund(id);
                                        }}
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'group flex items-center px-4 py-2 text-sm'
                                        )}
                                    >
                                        <PencilSquareIcon
                                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                            aria-hidden="true"
                                        />
                                        Refund
                                    </a>
                                </AccessControl>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    onClick={() => {
                                        openLoan()}}
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'group flex items-center px-4 py-2 text-sm'
                                    )}
                                >
                                    <PencilSquareIcon
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                    />
                                    View Contract
                                </a>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}