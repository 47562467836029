import AccessControl from '../../../util/accessControl';
import {formatPhoneNumber} from '../../../util/util';
import Flag from 'react-world-flags';
import { EnvelopeIcon } from '@heroicons/react/20/solid';

export default function CommercialClientHeader({commUser, openModal, sendEmailInvite}) {


    return(
        <div className="mb-5 px-20">
        <div className="grid grid-cols-10">
          <div
            className="w-1/12"
            style={{
              border: "1px solid black",
              borderRadius: "12%",
              height: "64px",
              width: "64px",
            }}
          >
            <img style={{}} src="/avatar4.png" alt="Solis Credit" />
          </div>
          <div className="col-span-9">
            <h2 className="text-2xl">{commUser.businessName}</h2>
            <div className="flex w-full">
              <div className="w-1/5">
                <div className="grid grid-cols-5">
                  <div className="col-span-1">
                    <div className="" style={{ width: "35px" }}>
                      <Flag code={commUser.businessAddressCountry} />
                    </div>
                  </div>
                  <div className="col-span-1">
                    <span className="text-sm  mt-2 font-bold">
                      {commUser.businessClientCode}
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-2/5">
                <b>Email: </b>
                {commUser.businessEmailAddress}
              </div>
              <div className="w-1/5 text-sm">
                <b>Registration: </b>{" "}
                {commUser.businessRegistrationNumber}
              </div>
              <div className="w-1/5 text-sm">
                <b>Phone: </b>{" "}
                {formatPhoneNumber(commUser.businessPhoneNumberOne)}
              </div>
            </div>
            <div className="flex">
              <p className="col-span-1 mr-2">Status:</p>
              {commUser.status == "active" && (
                <>
                  <p className="col-span-2 text-green-700 bg-green-200 ring-green-600/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset">
                    {commUser.status}
                  </p>
                  <span>
                    <AccessControl
                      permission={"changeClientStatus"}
                      fallback={<></>}
                      target={["god"]}
                    >
                      <svg
                        onClick={() => {
                          openModal(13);
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        className="grid-cols-1 ml-3 mt-1 h-4 w-4 cursor-pointer hover:red-500"
                        viewBox="0 0 512 512"
                      >
                        <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                      </svg>
                    </AccessControl>
                  </span>
                </>
              )}
              {commUser.status == "disabled" && (
                <>
                  <p className="col-span-2 text-red-700 bg-red-200 ring-red-600/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset">
                    {commUser.status}
                  </p>
                  <span>
                    <AccessControl
                      permission={"changeClientStatus"}
                      fallback={<></>}
                      target={["god"]}
                    >
                      <svg
                        onClick={() => {
                          openModal(13);
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        className="grid-cols-1 ml-3 mt-1 h-4 w-4 cursor-pointer hover:red-500"
                        viewBox="0 0 512 512"
                      >
                        <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                      </svg>
                    </AccessControl>
                  </span>
                </>
              )}
            </div>
            {process.env.REACT_APP_USE_SMS === "true" && (
              <div className="flex mt-2">
                <p className="col-span-1 mr-2">Verified:</p>
                <>
                  <span className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                    <svg
                      className={
                        "h-1.5 w-1.5 " +
                        (commUser?.smsVerified === true
                          ? "fill-green-500"
                          : "fill-red-500")
                      }
                      viewBox="0 0 6 6"
                      aria-hidden="true"
                    >
                      <circle cx={3} cy={3} r={3} />
                    </svg>
                    Telephone
                  </span>
                  <span className="mx-2 inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                    <svg
                      className={
                        "h-1.5 w-1.5 " +
                        (commUser?.emailVerified === true
                          ? "fill-green-500"
                          : "fill-red-500")
                      }
                      viewBox="0 0 6 6"
                      aria-hidden="true"
                    >
                      <circle cx={3} cy={3} r={3} />
                    </svg>
                    Email
                  </span>
                  <span>
                    {commUser?.emailVerified !== true && (
                      <AccessControl
                        permission={"sendEmailVerification"}
                        fallback={<></>}
                        target={["god"]}
                      >
                        <button
                          onClick={() => sendEmailInvite()}
                          type="button"
                          className="inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                          Verify Email
                          <EnvelopeIcon
                            aria-hidden="true"
                            className="-mr-0.5 h-5 w-5"
                          />
                        </button>
                      </AccessControl>
                    )}
                  </span>
                </>
              </div>
            )}
          </div>
        </div>
      </div>
    )
}