/* eslint-disable jsx-a11y/no-redundant-roles */
import { nullTransform,getDeepValue,FormatNumber,getStatus } from "../../../util/util";

export default function CommercialContractList({contracts,getCommercialClientContractDetail}) {

    return (
        <ul role="list" className="divide-y divide-gray-100">
        {contracts.length > 0 && (
          <>
            {contracts.map((contract) => (
              <li
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  getCommercialClientContractDetail(
                    contract._id
                  ).then(() => {});
                }}
                key={contract._id}
                className="relative justify-between gap-x-6 py-5"
              >
                <div className="border border-solid">
                  <div className="grid grid-cols-4 px-5 py-5">
                    <div className="col-span-1">
                      <span className="whitespace-nowrap py-4 pl-4 pr-3 text-2xl  text-gray-900">
                        {nullTransform(
                          getDeepValue(contract, "loan.loanNumber") ||
                            ""
                        )}
                      </span>
                      <br />
                      <br />
                      <span className="whitespace-nowrap py-4 pl-4 pr-3 text-md font-medium text-gray-900">
                        Balance:{" "}
                        <b>
                          $
                          {nullTransform(
                            FormatNumber(
                              `${
                                parseFloat(
                                  getDeepValue(
                                    contract,
                                    "loan.balance.total"
                                  )
                                ).toFixed(2) || 0
                              }`
                            )
                          )}
                        </b>
                      </span>
                    </div>
                    <div className="col-span-2">
                      <span className="whitespace-nowrap px-3 py-4 font-extrabold text-lg text-cyan-700">
                        {contract.insuranceCompany}
                      </span>
                      <br />
                      <br />
                      <span className="whitespace-nowrap px-3 py-4 text-md font-medium text-gray-900">
                        Policies: <b>{contract.policies.length}</b>
                      </span>
                      <span className="whitespace-nowrap px-3 py-4 text-md font-medium text-gray-900">
                        Months: <b>({nullTransform(contract.months || "")})</b>
                      </span>
                    </div>
                    <div className="col-span-1">
                      <span
                        className={getStatus(contract.status).style}
                      >
                        {getStatus(contract.status).name}
                      </span>
                      <br />
                      <br />
                      <span className="whitespace-nowrap py-4 text-md font-medium text-gray-900">
                        Premium Financed:{" "}
                        <b>
                          $
                          {nullTransform(
                            FormatNumber(
                              `${parseFloat(
                                getDeepValue(
                                  contract,
                                  "loan.principal"
                                ).toFixed(2) || 0
                              )}`
                            )
                          )}
                        </b>
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </>
        )}
      </ul>
    )
}