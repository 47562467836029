import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import {setDeepValue} from "../util/util";
import Http from "../util/http";
import AccessControl from "../util/accessControl";


export default function CommercialClientInfoVerifyModal({open, setOpen, commUser, setCommUser, save }) {

    const cancelButtonRef = useRef(null)
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        Http.request('admin', 'countries',[]).then((response) => {
            setCountries(response);
        });
    }, [])

    function setKey(key, value) {
        let object = {...commUser}

        setDeepValue(object, value, key)
        setCommUser(object)
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                        <PencilSquareIcon className="h-6 w-6 text-white-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Verify Commercial Client Information
                                        </Dialog.Title>
                                        <div className="mt-10">
                                            <div className="p-7">
                                                <div>
                                                    <h2 className="text-2xl">Business and Officer</h2>
                                                </div>

                                                <div className="grid grid-cols-4 gap-4">
                                                    <div className="col-span-1 sm:col-span-1">
                                                        <input
                                                            required
                                                            type="text"
                                                            onChange={(e) => {
                                                                setKey('businessName', e.target.value)
                                                            }}
                                                            value={commUser.businessName}
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Business Name
                                                        </label>
                                                    </div>
                                                    <div className="col-span-1 sm:col-span-1">
                                                        <input
                                                            required
                                                            type="text"
                                                            onChange={(e) => {
                                                                setKey('authorizerFirstName', e.target.value)
                                                            }}
                                                            value={commUser.authorizerFirstName}
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Authorized Officer First Name
                                                        </label>
                                                    </div>
                                                    <div className="col-span-1 sm:col-span-1">
                                                        <input
                                                            required
                                                            type="text"
                                                            onChange={(e) => {
                                                                setKey('authorizerLastName', e.target.value)
                                                            }}
                                                            value={commUser.authorizerLastName}
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Authorized Officer Last Name
                                                        </label>
                                                    </div>
                                                    <div className="col-span-1 sm:col-span-1">
                                                        <input
                                                            required
                                                            type="text"
                                                            onChange={(e) => {
                                                                setKey('businessRegistrationNumber', e.target.value)
                                                            }}
                                                            value={commUser.businessRegistrationNumber}
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Registration Number
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-7">
                                                <div>
                                                    <h2 className="text-2xl">Contact Info</h2>
                                                </div>

                                                <div className="grid grid-cols-3 gap-4">
                                                    <div className="col-span-1 sm:col-span-1">
                                                        <input
                                                            required
                                                            type="text"
                                                            onChange={(e) => {
                                                                setKey('businessEmailAddress', e.target.value)
                                                            }}
                                                            value={commUser.businessEmailAddress}
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Email Address
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-3 gap-4 mt-5">
                                                    <div className="col-span-1 sm:col-span-1">
                                                        <input
                                                            required
                                                            type="text"
                                                            onChange={(e) => {
                                                                setKey('businessPhoneNumberOne', e.target.value)
                                                            }}
                                                            value={commUser.businessPhoneNumberOne}
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Business Phone Number (1)
                                                        </label>
                                                    </div>
                                                    <div className="col-span-1 sm:col-span-1">
                                                        <input
                                                            type="text"
                                                            onChange={(e) => {
                                                                setKey('businessPhoneNumberTwo', e.target.value)
                                                            }}
                                                            value={commUser.businessPhoneNumberTwo}
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Business Phone Number (2)
                                                        </label>
                                                    </div>
                                                    <div className="col-span-1 sm:col-span-1">
                                                        <input
                                                            type="text"
                                                            onChange={(e) => {
                                                                setKey('businessFaxNumber', e.target.value)
                                                            }}
                                                            value={commUser.businessFaxNumber}
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Business Phone Number (Fax)
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-7">
                                                <div>
                                                    <h2 className="text-2xl">Address</h2>
                                                </div>

                                                <div className="grid grid-cols-3 gap-4">
                                                    <div className="col-span-1 sm:col-span-1">
                                                        <input
                                                            required
                                                            type="text"
                                                            onChange={(e) => {
                                                                setKey('businessAddressFirstLine', e.target.value)
                                                            }}
                                                            value={commUser.businessAddressFirstLine}
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Address - First Line
                                                        </label>
                                                    </div>
                                                    <div className="col-span-1 sm:col-span-1">
                                                        <input
                                                            type="text"
                                                            onChange={(e) => {
                                                                setKey('businessAddressSecondLine', e.target.value)
                                                            }}
                                                            value={commUser.businessAddressSecondLine}
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Address - Second Line
                                                        </label>
                                                    </div>
                                                    <div className="col-span-1 sm:col-span-1">
                                                        <input
                                                            required
                                                            type="text"
                                                            onChange={(e) => {
                                                                setKey('businessAddressCity', e.target.value)
                                                            }}
                                                            value={commUser.businessAddressCity}
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Address - City or Town
                                                        </label>
                                                    </div>
                                                    <div className="col-span-1 sm:col-span-1">
                                                        <input
                                                            required
                                                            type="text"
                                                            onChange={(e) => {
                                                                setKey('businessAddressState', e.target.value)
                                                            }}
                                                            value={commUser.businessAddressState}
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Address - State or Province
                                                        </label>
                                                    </div>
                                                    <div className="col-span-1 sm:col-span-1">
                                                        <select
                                                            required
                                                            id="current-tab"
                                                            name="current-tab"
                                                            onChange={(e) => {
                                                                setKey('businessAddressCountry',e.target.value)
                                                            }}
                                                            value={commUser.businessAddressCountry}
                                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        >
                                                            <option value=""> Select a Country </option>
                                                            {countries.map(item => <option key={item.code} value={item.code}> {item.title} </option> )}
                                                        </select>
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Address - Country
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-7">
                                                <div>
                                                    <h2 className="text-2xl">Business Description</h2>
                                                </div>

                                                <div className="grid grid-cols-1 gap-4">
                                                    <div className="col-span-1 sm:col-span-1">
                                        <textarea
                                            required
                                            rows="10"
                                            onChange={(e) => {
                                                setKey('businessDescription', e.target.value)
                                            }}
                                            value={commUser.businessDescription}
                                            className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <AccessControl permission={'verifyCommercialClientInfo'} fallback={<></>} target={['god']}>
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                            onClick={() => save(commUser)}
                                        >
                                            Continue
                                        </button>
                                    </AccessControl>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}