// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea,
select,
input[type]:not([type=search]):not([type=url]):not([type=hidden]) {
padding-left: 10px;
padding-right: 10px;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;AAGA,kBAAkB;AAClB,mBAAmB;AACnB","sourcesContent":["textarea,\nselect,\ninput[type]:not([type=search]):not([type=url]):not([type=hidden]) {\npadding-left: 10px;\npadding-right: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
