import { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

export default function SignIn() {
    const [email,setEmail] = useState('')

    async function reset(event){
        event.preventDefault();
        try{
            if(email.length > 8){
                let response = await axios.post(`${process.env.REACT_APP_IDENTITY_API}/api`,{target: "reset",payload:{email: email.toLocaleLowerCase()}})
                if(response.data.error){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please check credentials',
                    })
                }
                window.location.href = `${window.location.origin}/activate`
            }
        }catch(e) {
            console.log(e);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'An error occurred while trying to reset account.',
            })
        }
    }

    return (
        <>
            <div style={{height: '100vh'}} className="flex min-h-full">
            <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
                <div>
                <img
                        style={{boarderRadius: '50%',margin: 'auto'}}
                        className="h-20 w-30"
                        src="/banner.jpg"
                        alt="Solis Credit"
                      />
                <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Reset your passwords</h2>
                </div>

                <div className="mt-8">

                <div className="mt-6">
                    <form onSubmit={(e)=>{reset(e)}} className="space-y-6">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Email address
                        </label>
                        <div className="mt-2">
                        <input
                            onChange={(e)=>{setEmail(e.target.value)}}
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        </div>
                    </div>

                    <div>
                        <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                        Reset Password
                        </button>
                    </div>
                    </form>
                    <div className="flex items-center justify-between pt-3">
                        <div className="text-sm">
                            <a href="/signin" className="font-medium text-indigo-600 hover:text-indigo-500">
                                Return to login
                            </a>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div className="relative hidden w-0 flex-1 lg:block">
            <img
                className="absolute inset-0 h-full w-full object-cover"
                src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                alt=""
            />
            </div>
            </div>
        </>
    )
}
