
'use client'

import { useState } from 'react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import {
  FaceFrownIcon,
  FaceSmileIcon,
  FireIcon,
  HandThumbUpIcon,
  HeartIcon,
  PaperClipIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import moment from 'moment';

// const activity = [
//   { id: 1, type: 'created', person: { name: 'Chelsea Hagon' }, date: '7d ago', dateTime: '2023-01-23T10:32' },
//   { id: 2, type: 'edited', person: { name: 'Chelsea Hagon' }, date: '6d ago', dateTime: '2023-01-23T11:03' },
//   { id: 3, type: 'sent', person: { name: 'Chelsea Hagon' }, date: '6d ago', dateTime: '2023-01-23T11:24' },
// ]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ApprovalFeed({activity}) {
return (
    <>
        <ul role="list" className="space-y-6">
            {activity.map((activityItem, activityItemIdx) => (
                <li key={activityItem._id} className="relative flex gap-x-4">
                    <div
                        className={classNames(
                            activityItemIdx === activity.length - 1 ? 'h-6' : '-bottom-6',
                            'absolute left-0 top-0 flex w-6 justify-center',
                        )}
                    >
                        <div className="w-px bg-gray-200" />
                    </div>
                    <>
                        <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                            {activityItem.type === 'paid' ? (
                                <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-indigo-600" />
                            ) : (
                                <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                            )}
                        </div>
                        <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                            {activityItem.reason}
                        </p>
                        <time
                            dateTime={activityItem.createdAt}
                            className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                        >
                            {moment(activityItem.createdAt).fromNow()}
                        </time>
                    </>
                </li>
            ))}
        </ul>
    </>
);
}
