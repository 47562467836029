import { EllipsisVerticalIcon, InboxIcon } from "@heroicons/react/20/solid";

export default function NotificationTile({
  notification,
  dismiss,
  index,
  hideActions,
}) {
  return (
    <li>
      <div className="pointer-events-auto w-full overflow-hidden bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <InboxIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">
                {notification.title}
              </p>
              <p className="mt-1 text-sm text-gray-500">{notification.body}</p>
              {hideActions !== true && (
                <div className="mt-3 flex space-x-7">
                  <button
                    onClick={(e) => {
                      dismiss(index);
                    }}
                    type="button"
                    className="rounded-md bg-white text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Dismiss
                  </button>
                  {notification.actions.map((link, index) => {
                    return (
                      <a
                        key={index}
                        href={link.link}
                        type="button"
                        className="rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        {link.name}
                      </a>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="ml-4 flex flex-shrink-0"></div>
          </div>
        </div>
      </div>
    </li>
  );
}
