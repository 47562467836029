
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
  export default function QuotationTabs({tabs,setTabs,setPreview,previewInfo}) {

    function clickTab(index){
        let _tabs = [...tabs];
        _tabs.forEach((tab) => tab.current = false);
        _tabs[index].current = true;
        setPreview(previewInfo[index])
        setTabs(_tabs);
    }
    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            defaultValue={tabs.find((tab) => tab.current).name}
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav aria-label="Tabs" className="-mb-px flex">
              {tabs.map((tab,index) => (
                <div
                  key={tab.name}
                  aria-current={tab.current ? 'page' : undefined}
                  onClick={()=>{clickTab(index)}}
                  className={classNames(
                    tab.current
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'w-1/3 border-b-2 px-1 py-4 text-center text-sm font-medium cursor-pointer',
                  )}
                >
                  {tab.name}
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>
    )
  }
  