import { useEffect, useState } from "react";
import moment from "moment";
import ApprovalList from "../../../components/dashboard/approvallist";
import Http from "../../../util/http";
import Swal from "sweetalert2";
import Pagination from "../../../components/pagination";

export default function ApprovalPage() {
  const [contracts, setContracts] = useState([]);
  const [paging, setPaging] = useState({
    text: "",
    sort: "",
    total: 0,
    page: 1,
    date: moment().format("YYYY-MM-DD"),
    count: 0,
    perPage: 10,
    type: "",
    status: "",
  });
  const statuses = [
    { key: "approved", value: "Approved" },
    { key: "cancelled", value: "Cancelled" },
    { key: "current", value: "Current" },
    { key: "late", value: "Late" },
    { key: "new", value: "New" },
    { key: "paidOff", value: "Paid Off" },
    { key: "paidUp", value: "Paid Up" },
    { key: "pendingApproval", value: "Pending Approval" },
    { key: "refunded", value: "Refunded" },
    //{key: 'rescheduled', value: 'Rescheduled'},
    { key: "withdrawn", value: "Withdrawn" },
    { key: "writtenOff", value: "Written Off" },
    { key: "late", value: "Late" },
  ];
  function selectStatus(id) {
    let pagingData = { ...paging };
    pagingData.status = id;
    pagingData.page = 1;
    pagingData.count = 0;
    // let url = window.location.origin + window.location.pathname +'?' +new URLSearchParams(pagingData)
    // window.history.replaceState( {} , 'users', url)
    setPaging(pagingData);
  }

  function getData(pagingData, showloader) {
    let dialog;
    if (showloader) {
      dialog = Swal.fire({
        title: "Loading...",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {},
      });
    }
    console.log(pagingData);
    Http.request("contracts", "contract/approval/get-all", pagingData)
      .then((response) => {
        setContracts(response.contracts);
        let newPagingData = { ...pagingData };
        setPaging({
          ...newPagingData,
          total: response.total,
          count: response.contracts.length,
          perPage: response.perPage,
          text: document.getElementById("search").value || "",
          status: document.getElementById("status").value || "",
          type: document.getElementById("date").value || "",
        });
        if (showloader) {
          dialog.close();
        }
      })
      .catch((e) => {
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to make request to servers.",
        });
      });
  }

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user.position !== "master" && user.position !== "god") {
      console.log("do not show");
    }
    let pagingData = {};

    pagingData = { ...paging };
    if (window.location.search !== "") {
      pagingData.status = window.location.search.replace("?status=", "");
    }
    pagingData.page = parseInt(pagingData.page);
    getData(pagingData, true);
  }, []);
  return (
    <div className="flex justify-center ">
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 w-full">
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
            <div className="mb-10">
              <h1 className="text-4xl text-center font-semibold  leading-6 text-gray-900">
                (Approval Tools)
              </h1>
            </div>
            <div className="px-4 mt-7">
              <div className="mx-auto px-4 mb-4">
                <div className="mt-6 flex gap-x-4" action="#">
                  <div className="min-w-0 flex-1">
                    <label className="sr-only">Search</label>
                    <div className="relative rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <svg
                          className="h-5 w-5 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <input
                        value={paging.text}
                        onChange={(e) => {
                          setPaging({ ...paging, text: e.target.value });
                        }}
                        type="search"
                        name="search"
                        id="search"
                        className="block w-full rounded-md border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Search using Loan number, Client Name,Policy Number"
                      />
                    </div>
                  </div>
                  <div className="w-1/4">
                    <label className="sr-only">Select Coverage Type</label>
                    <div
                      className="relative rounded-md shadow-sm"
                      style={{ height: "100%" }}
                    >
                      <select
                        id="status"
                        value={paging.status}
                        onChange={(e) => {
                          selectStatus(e.target.value);
                        }}
                        name="type"
                        autoComplete="type"
                        className="block h-full w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        <option key={""} value={""} selected>
                          Choose a loan status
                        </option>
                        {statuses.map((state, index) => {
                          return (
                            <option key={index} value={state.key}>
                              {state.value}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="w-1/4">
                    <input
                      id="date"
                      value={paging.date}
                      onChange={(e) => {
                        setPaging({ ...paging, date: e.target.value });
                      }}
                      type="date"
                      name="type"
                      placeholder="Date"
                      autoComplete="type"
                      className="block h-full w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <span className="isolate inline-flex rounded-md shadow-sm">
                    <button
                      onClick={() => {
                        getData(paging, true);
                      }}
                      type="button"
                      className="relative -ml-px inline-flex items-center bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
                    >
                      Send
                    </button>
                  </span>
                </div>
              </div>

              {/* User table*/}
              <div className="hidden sm:block">
                <div className="mx-auto px-4">
                  <div className="mt-2 flex flex-col">
                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                      <ApprovalList contracts={contracts} />
                    </div>
                    <div className="shadow sm:overflow-hidden sm:rounded-b-md">
                      <Pagination getData={getData} paging={paging} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
