import { useState, useEffect } from "react";
import { setDeepValue } from "../../../util/util";
import Http from "../../../util/http";
import Swal from "sweetalert2";
import AccessControl from "../../../util/accessControl";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function UserCreate() {
  const [user, setUser] = useState({
    telephone: "",
    email: "",
    firstName: "",
    lastName: "",
    position: "regular",
    organization: "",
    branch: "",
  });
  const [roles,setRoles] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState({});
  const [selectedBranch, setSelectedBranch] = useState("");
  const [branchInfo, setBranchInfo] = useState({
    title: "",
    address: "",
    email: "",
    contactPerson: "",
    telephone: "",
    organization: "",
    roles:[]
  });

  useEffect(() => {
    async function init(){
        let userData= {...user,roles:[]}
        const queryParams = new URLSearchParams(window.location.search);
        if (!queryParams.has("branch")) {
          window.location.href = `${window.location.origin}/dashboard/organizations`;
        }
        let response = await Http.request("admin", "roles/get-all", {
            total: 0,
            page: 1,
            count: 0,
            perPage: 100000,
          });
  
          response.roles.forEach((role) => {
            userData.roles.forEach((id) => {
              if (role._id === id) {
                role.checked = true;
              }
            });
          });

          var search = window.location.search.substring(1);
          search = JSON.parse(
            '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
            function (key, value) {
              return key === "" ? value : decodeURIComponent(value);
            }
          );
    
        Http.request("admin", "organizations/get-one", {
          id: queryParams.get("organization"),
        })
          .then((response) => {
            setSelectedOrganization(response);
          })
          .catch((e) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to make request to servers.",
            });
          });
    
        setSelectedBranch(queryParams.get("branch"));
        getBranchInfo(queryParams.get("branch"));
        let org = await Http.request('admin', 'organizations/get-one',{id: search.organization});
        if(org.type !== 'BFTTL Administration'){
           let idx = response.roles.findIndex(x => x.name === 'Master');
           response.roles.splice(idx, 1);
           setUser({...user,position: 'regular'});
        }else{
            let idx = response.roles.findIndex(x => x.name === 'Master');
            response.roles = [response.roles[idx]];
            setUser({...user,position: 'master'});
        }
        setSelectedOrganization(org);
        setRoles(response.roles);
    }
    init();
  }, []);

  function getBranchInfo(id, showloader = true) {
    let dialog;
    if (showloader) {
      dialog = Swal.fire({
        title: "Loading...",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {},
      });
    }

    Http.request("admin", "branches/get-one", { id })
      .then((response) => {
        setBranchInfo(response);
        if (showloader) {
          dialog.close();
        }
      })
      .catch((e) => {
        if (showloader) {
          dialog.close();
        }
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to make request to servers.",
        });
      });
  }

  function setKey(key, value) {
    let object = { ...user };
    setDeepValue(object, value, key);
    setUser(object);
  }

  function redirect() {
    window.location.href = `${window.location.origin}/dashboard/users?organization=${selectedOrganization._id}&branch=${selectedBranch}`;
  }

  function save(e) {
    let dialog = Swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {},
    });
    e.preventDefault();
    const newUser = { ...user };
    newUser.organization = selectedOrganization._id;
    newUser.branch = selectedBranch;
    try {
      if (
        newUser.position == "master" &&
        selectedOrganization.type != "BFTTL Administration"
      ) {
        Swal.fire(
          "Oops",
          "Master position cannot be given to user that is not apart of the BFTTL Administration"
        );
      } else {
        Http.request("admin", "users/create", newUser)
          .then((response) => {
            dialog.close();
            Swal.fire({
              icon: "success",
              title: "Hurray!",
              text: "User added successfully.",
            }).then(() => {
              redirect();
            });
          })
          .catch((err) => {
            dialog.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text:
                `${err}`.replace("Error: ", "") ||
                "Failed to make request to servers.",
            });
          });
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      <nav
        className="flex border-b border-gray-200 bg-white mb-5"
        aria-label="Breadcrumb"
      >
        <ol
          role="list"
          className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8"
        >
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <span className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                <b>Organization Name: </b>
                {selectedOrganization?.name}
              </span>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <span className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                <b>Branch Name: </b>
                {branchInfo?.title}
              </span>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <span className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                <b>Branch Address: </b>
                {branchInfo?.address}
              </span>
            </div>
          </li>
        </ol>
      </nav>

      <div className="flex justify-center ">
        <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 w-full">
          <form
            onSubmit={(e) => {
              save(e);
            }}
          >
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
                <div>
                  <h2 className="text-base font-semibold leading-6 text-gray-900">
                    User Information
                  </h2>
                  <p className="mt-1 text-sm text-gray-500">
                    Please ensure all fields are filled in correctly.
                  </p>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      First Name
                    </label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setKey("firstName", e.target.value);
                      }}
                      value={user.firstName}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Last Name
                    </label>
                    <input
                      type="text"
                      value={user.lastName}
                      onChange={(e) => {
                        setKey("lastName", e.target.value);
                      }}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="email-address"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email address
                    </label>
                    <input
                      value={user.email}
                      onChange={(e) => {
                        setKey("email", e.target.value?.toLowerCase());
                      }}
                      type="email"
                      name="email-address"
                      id="email-address"
                      autoComplete="email"
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="telephone"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Telephone
                    </label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setKey("telephone", e.target.value);
                      }}
                      value={user.telephone}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      User Role
                    </label>
                    <select
                      id="type"
                      name="type"
                      value={user?.roles?.[0]}
                      onChange={(e) => {
                        setKey("roles", [e.target.value]);
                      }}
                      className="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      <option value={null}>Select User Role</option>
                      {roles.map((role, index) => {
                        return <option value={role._id}>{role.name}</option>;
                      })}
                    </select>
                  </div>

                  {/*<div className="col-span-6 sm:col-span-3">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Organization
                                    </label>
                                    <select
                                        id="type"
                                        name="type"
                                        autoComplete="type"
                                        value={user.organization}
                                        onChange={(e) => {
                                            setKey('organization', e.target.value._id);
                                            setSelectedOrganization(e.target.value)
                                        }}
                                        className="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >
                                        {organizations.map((organization, index) => {
                                            return (
                                                <option key={index} value={organization}>{organization.name}</option>
                                            )
                                        })

                                        }

                                    </select>
                                </div>*/}
                </div>
              </div>
              <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    redirect();
                  }}
                  type="button"
                  className="text-sm font-semibold leading-6 text-gray-900 mx-3"
                >
                  Cancel
                </a>
                <AccessControl
                  permission={"addUser"}
                  fallback={<></>}
                  target={["god"]}
                >
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Save
                  </button>
                </AccessControl>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
