import { Menu } from "@headlessui/react";
import {
  EllipsisHorizontalIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/20/solid";
import { useState } from "react";
import moment from "moment";
export default function AutomaticWidget({ getAutomaticApprovals }) {
  const [duration, setDuration] = useState("Day");
  return (
    <div className="mr-6">
      <Menu>
        <Menu.Button className="flex items-center space-x-1 text-sm font-medium text-gray-500">
          <span>{duration}</span>
          <EllipsisVerticalIcon className="w-5 h-5" />
        </Menu.Button>
        <Menu.Items className="absolute right-0 w-40 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => {
                    setDuration("Day");
                    getAutomaticApprovals(
                      moment().subtract(1, "day").format("YYYY-MM-DD")
                    );
                  }}
                  className={`${
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                >
                  Day
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => {
                    setDuration("Week");
                    getAutomaticApprovals(
                      moment().subtract(7, "day").format("YYYY-MM-DD")
                    );
                  }}
                  className={`${
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                >
                  Week
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => {
                    setDuration("Month");
                    getAutomaticApprovals(
                      moment().subtract(1, "month").format("YYYY-MM-DD")
                    );
                  }}
                  className={`${
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                >
                  Month
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
    </div>
  );
}
