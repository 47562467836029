import { FormatNumber } from "../../util/util";
import { useState } from "react";


export default function MonthGrid({ info,months,setSummary,setInfo,summary }) {
  const [title, setTitle] = useState("Please select a loan tenure");
  const clickSummary = (item)=>{
    setInfo({...info,months: item.data.length});
    if(summary === item){
      setSummary(null);
      setTitle("Please select a loan tenure");
      return;
    }
    setTitle(`Loan Tenure Selected`);
    setSummary(item);
  }
  return (
    <div>
      <div className="text-xl font-bold leading-7 text-gray-900">
          {title}
      </div>
        {summary !== null &&
            <div className={"text-sm font-semibold text-center"}>
                <hr className={"my-2"}/>
                <div>Loan Tenure: {summary?.data.length} Months</div>
                <div>Monthly Installment: ${FormatNumber(summary?.headers?.monthlyInstalment?.toFixed(2))}</div>
                <hr className={"my-2"}/>
            </div>

        }

        <div className="mt-3 grid grid-cols-3 gap-4 w-100">
            {months.length > 0 && (
                <>
                    {months.map((item, index) => (
                        <button
                            onClick={()=>clickSummary(item)}
                    type="button"
                    className={summary?.data.length === item.data.length ? `rounded-md bg-sky-800 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-900 border-4 border-yellow-400` : "rounded-md bg-sky-800 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-900"}
                >
                    <span>Tenure: {item.data.length} Months</span>
                    <br/>
                    <span>Installment: ${FormatNumber(item.headers.monthlyInstalment.toFixed(2))}</span>
                </button>
            ))}
          </>
        )}

      </div>
        {months.length === 0 && (
            <div className="flex items-center justify-center">
                Please enter your policy end date to see your payment options.
          </div>
        )}

    </div>
  );
}
