import Pagination from "../../../components/pagination";
import { useState, useEffect } from "react";
import Http from "../../../util/http";
import { getDeepValue } from "../../../util/util";
import Swal from "sweetalert2";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function UsersIndex(props) {
  const [users, setUsers] = useState([]);
  const [paging, setPaging] = useState({
    text: "",
    sort: "",
    total: 0,
    page: 1,
    count: 0,
    perPage: 10,
    branch: "",
    organization: "",
  });
  const [branchInfo, setBranchInfo] = useState({
    title: "",
    address: "",
    email: "",
    contactPerson: "",
    telephone: "",
    organization: "",
  });
  const [organizationInfo, setOrganizationInfo] = useState({
    name: "",
  });

  async function search(field) {
    let pagingData = { ...paging };
    pagingData[field.key] = field.value;
    pagingData.page = parseInt(pagingData.page);
    setPaging(pagingData);
    getData(pagingData);
  }

  useEffect(() => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      if (!queryParams.has("branch")) {
        window.location.href = `${window.location.origin}/dashboard/organizations`;
      }
      let user = JSON.parse(localStorage.getItem("user"));
      if (user.position !== "master" && user.position !== "god") {
        console.log("do not show");
      }

      const pagingData = { ...paging };
      pagingData.branch = queryParams.get("branch");
      pagingData.organization = queryParams.get("organization");
      pagingData.page = parseInt(pagingData.page);
      getData(pagingData, true);
      getBranchInfo(pagingData);
      getOrganizationInfo(pagingData);
    } catch (e) {
      console.log("Failed page load");
    }
  }, []);

  const fields = [
    { title: "First Name", key: "firstName", sorting: false },
    { title: "Last Name", key: "lastName", sorting: false },
    { title: "Organization", key: "organization", sorting: false },
    { title: "Account Type", key: "accountType", sorting: false },
    { title: "Email", key: "email", sorting: false },
    { title: "Telephone", key: "telephone", sorting: false },
    { title: "Role", key: "roles", sorting: false },
    { title: "Status", key: "status", sorting: false },
  ];

  function getData(pagingData, showloader) {
    let dialog;
    if (showloader) {
      dialog = Swal.fire({
        title: "Loading...",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {},
      });
    }
    Http.request("admin", "users/get-all", pagingData)
      .then((response) => {
        setUsers(response.users);
        let newPagingData = { ...pagingData };
        delete newPagingData.text;
        setPaging({
          ...newPagingData,
          total: response.total,
          count: response.users.length,
          perPage: response.perPage,
        });
        if (showloader) {
          dialog.close();
        }
      })
      .catch((e) => {
        if (showloader) {
          dialog.close();
        }
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to make request to servers.",
        });
      });
  }

  function getBranchInfo(pagingData, showloader = true) {
    let dialog;
    if (showloader) {
      dialog = Swal.fire({
        title: "Loading...",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {},
      });
    }

    Http.request("admin", "branches/get-one", { id: pagingData.branch })
      .then((response) => {
        setBranchInfo(response);
        if (showloader) {
          dialog.close();
        }
      })
      .catch((e) => {
        if (showloader) {
          dialog.close();
        }
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to make request to servers.",
        });
      });
  }

  function getOrganizationInfo(pagingData, showloader = true) {
    let dialog;
    if (showloader) {
      dialog = Swal.fire({
        title: "Loading...",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {},
      });
    }

    Http.request("admin", "organizations/get-one", {
      id: pagingData.organization,
    })
      .then((response) => {
        setOrganizationInfo({ name: response.name });
        if (showloader) {
          dialog.close();
        }
      })
      .catch((e) => {
        if (showloader) {
          dialog.close();
        }
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to make request to servers.",
        });
      });
  }

  function goToCreate() {
    window.location.href = `${window.location.origin}/dashboard/users/create?organization=${paging.organization}&branch=${paging.branch}`;
  }

  return (
    <div className="flex justify-center ">
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 w-full">
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
            <div className="mb-10">
              <h1 className="text-4xl text-center font-semibold  leading-6 text-gray-900">
                (Users)
              </h1>
            </div>
            <div className="px-4 mt-7">
              <div className="mx-auto w-full px-4 ">
                <nav
                  className="flex border border-gray-200 bg-gray-50"
                  aria-label="Breadcrumb"
                >
                  <ol
                    role="list"
                    className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8"
                  >
                    <li className="flex">
                      <div className="flex items-center">
                        <svg
                          className="h-full w-6 flex-shrink-0 text-gray-200"
                          viewBox="0 0 24 44"
                          preserveAspectRatio="none"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <span className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                          <b>Organization Name: </b>
                          {organizationInfo.name}
                        </span>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex items-center">
                        <svg
                          className="h-full w-6 flex-shrink-0 text-gray-200"
                          viewBox="0 0 24 44"
                          preserveAspectRatio="none"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <span className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                          <b>Branch Name: </b>
                          {branchInfo.title}
                        </span>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex items-center">
                        <svg
                          className="h-full w-6 flex-shrink-0 text-gray-200"
                          viewBox="0 0 24 44"
                          preserveAspectRatio="none"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <span className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                          <b>Branch Address: </b>
                          {branchInfo.address}
                        </span>
                      </div>
                    </li>
                  </ol>
                </nav>

                <div className="mt-6 flex gap-x-4" action="#">
                  <div className="min-w-0 flex-1">
                    <label className="sr-only">Search</label>
                    <div className="relative rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <svg
                          className="h-5 w-5 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <input
                        value={paging.text}
                        onChange={(e) => {
                          search({ key: "text", value: e.target.value });
                        }}
                        type="search"
                        name="search"
                        id="search"
                        className="block w-full rounded-md border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      goToCreate();
                    }}
                    //onClick={()=>{window.location.href = window.location.href + ''}}
                    type="button"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    New User
                  </a>
                </div>
              </div>

              {/* User table*/}
              <div className="hidden sm:block">
                <div className="mx-auto w-full px-4">
                  <div className="mt-2 flex flex-col">
                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            {fields.map((field, index) => {
                              return (
                                <th
                                  key={index}
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6"
                                >
                                  {field.title}
                                </th>
                              );
                            })}
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {users.map((user, index) => (
                            <tr //onClick={() => window.location.replace(`${window.location.pathname}/${user._id}`)}
                              key={index}
                              className="bg-white cursor-pointer"
                            >
                              {fields.map((field, i) => (
                                <td
                                  key={i}
                                  //onClick={() => window.location = `${window.location.pathname}/${user._id}`}
                                  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                                >
                                  {getDeepValue(user, field.key) == "disabled"
                                    ? "inactive"
                                    : getDeepValue(user, field.key)}
                                </td>
                              ))}
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <a
                                  href={`${window.location.pathname}/${user._id}?organization=${paging.organization}&branch=${paging.branch}`}
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  Edit
                                  <span className="sr-only">, {user.name}</span>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/* Pagination */}
                      <Pagination getData={getData} paging={paging} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
