import { useState ,useEffect} from 'react'
import {setDeepValue} from '../../../util/util'
import Http from '../../../util/http'
import Swal from 'sweetalert2'
import AccessControl from "../../../util/accessControl";



function isEmpty(value){
    return (value == null || value.length === 0);
  }

export default function RoleCreate() {

  const [role,setRole] =  useState({
    description: '',
    name: '',
    permissions:{
      permissions: {},
      permissionsList: []
    }
  })

  const [permissions,setPermissions] = useState([]);

  useEffect(() => {
    Http.request('admin','permissions/all').then((response)=>{
      setPermissions(response)
  }).catch((e)=>{
    console.log(e)
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Failed to make request to servers.',
    })
  })
  }, [])

  function setKey(key,value) {
    console.log({key:key,value:value})
    let object = {... role}

    setDeepValue(object,value,key)
    setRole(object)
  }
  function save(e){
  
      let dialog = Swal.fire({
          title: 'Loading...',
          timerProgressBar: true,
          allowOutsideClick: false, 
          didOpen: () => {
            Swal.showLoading()
          },
          willClose: () => {
          }
        })
      e.preventDefault();
        if(isEmpty(role.description) || isEmpty(role.name)) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `Please enter data in all the fields`,
          })
        } else {
          try
          {
              Http.request('admin','roles/create',role).then((response)=>{
                console.log(response)
                dialog.close()
                Swal.fire({
                  icon: 'success',
                  title: 'Hurray!',
                  text: 'Role added successfully.',
                })
                window.location = `${window.location.origin}/dashboard/roles`
              }).catch((err)=>{
                dialog.close()
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: `${err}`.replace('Error: ','') || 'Failed to make request to servers.',
                })
              })
          }catch(err){
            dialog.close()
            console.log(err)
          }
        }
     
  }

  function add(permission,idx){
   
  
    let data = {...role}
    data.permissions.permissionsList.push(permission)
    setRole(data)
    // setPermissions(missions)

  }
  function remove(permission,idx){
    // let missions = JSON.parse(JSON.stringify(permissions));
    let data = {...role}
    data.permissions.permissionsList.map((item,index) => {
      if(item.name == permission.name) {
        data.permissions.permissionsList.splice(index, 1)
      }
    })
    setRole(data)
    
  }

  function checker(permission, idx, event) {
    if(event.target.checked) {
      add(permission, idx)
    } else {
      remove(permission, idx)
    }
    console.log(role)
  }

  return (
    <div className="shadow sm:overflow-hidden sm:rounded-md ">
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <form onSubmit={(e)=>{save(e)}}>
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
              <div>
                <h2 className="text-base font-semibold leading-6 text-gray-900">Role Information</h2>
                <p className="mt-1 text-sm text-gray-500">Please ensure all fields are filled in correctly.</p>
              </div>

              <div className="grid grid-cols-6 gap-10">
                <div className="col-span-6">
                  <label  className="block text-sm font-medium leading-6 text-gray-900">
                    Name
                  </label>
                  <input
                    type="text"
                    onChange={(e)=>{setKey('name', e.target.value)}}
                    value={role.name}
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="col-span-6">
                  <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                    Description
                  </label>
                  <textarea
                    rows={3}
                    type="text"
                    name="description"
                    id="description"
                    value={role.description}
                    onChange={(e)=>{setKey('description', e.target.value)}}
                    autoComplete="description"
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

          
              </div>

              <div class='grid grid-cols-5 gap-5' style={{width:'100%'}} >
                {permissions.map((permission,index) =>{
                       
                       return (
                         // <PermissionListItem add={add} index={index} key={index} permission={permission}/>
                    
                         <div class="grid grid-cols-7" key={index}>
                         <div className="col-span-1">
                           <input
                           type="checkbox"
                           className='mt-2'
                           checked={permission.valid}
                           style={{ width: "70%",height: "70%" }}
                           onChange={(event) => checker(permission, index, event)}
                         /></div>
                         <div className="col-span-6 text-base">{permission.name}</div>
                       </div>
                         
                     
                         
                       )
                     })}
                </div>
              
              {/* <div style={{height: '230px',marginBottom: '20px'}} className="">
                <h1>List of role permissions.</h1>
                <div className='flex'>
                  <div style={{height: '230px',overflowY: 'scroll',borderRadius: '5px'}} className='w-full ring-1 ring-inset ring-gray-300'>
                    {
                      role.permissions.permissionsList.map((permission,index) =>{
                       
                        return (
                          <PermissionListItem remove={remove} key={index} permission={permission}/>
                        )
                      })
                    }
                  </div>
                </div>

              </div> */}

              {/* <div style={{height: '230px',marginBottom: '20px'}} className="">
                <h1>Please choose from list of permissions below.</h1>
                <div className='flex'>
                  <div style={{height: '230px',overflowY: 'scroll',borderRadius: '5px'}} className='w-full ring-1 ring-inset ring-gray-300'>
                    {
                      permissions.map((permission,index) =>{
                       
                        return (
                          // <PermissionListItem add={add} index={index} key={index} permission={permission}/>
                          <input type='checkbox' class='' />
                        )
                      })
                    }
                  </div>
                </div>

              </div> */}
            </div>

            <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
              <a href='/dashboard/roles'  type="button" className="text-sm font-semibold leading-6 text-gray-900 mx-3">Cancel</a>
              <AccessControl
                  fallback={<></>}
                  permission={"assignRole"}
                  target={["god"]}
              >
                <button
                    type="submit"
                    className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Save
                </button>
              </AccessControl>

            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

