import Pagination from "../../../components/pagination";
import { useState, useEffect } from "react";
import Http from "../../../util/http";
import { subString } from "../../../util/util";
import { json2csv } from "json-2-csv";
import Swal from "sweetalert2";
import CancellationRoomOptions from "../../../components/dashboard/cancelroomOptions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const statuses = {
  awaitingCancellation: "text-yellow-500 bg-yellow-50 ring-yellow-400/20",
  writtenOff: "text-gray-600 bg-gray-50 ring-gray-500/10",
  late: "text-red-700 bg-red-50 ring-red-600/10",
  cancelled: "text-red-700 bg-red-200 ring-red-600/10",
  paidUp: "text-teal-700 bg-teal-200 ring-teal-600/10",
  paidOff: "text-cyan-700 bg-cyan-200 ring-cyan-600/10",
  refunded: "text-teal-700 bg-teal-200 ring-teal-600/10",
  noticeCancellation: "text-blue-700 bg-blue-50 ring-blue-600/10",
};

export default function CancellationRoom(props) {
  function nullTransform(val) {
    if (val === null || val === undefined) {
      return "";
    }
    return val;
  }

  const [paging, setPaging] = useState({
    text: "",
    page: 1,
    sort: "",
    total: 0,
    count: 0,
    perPage: 10,
    companyType: "",
    limit: 10,
    cancellationStatus: null, // Added
  });

  async function search(field) {
    let pagingData = { ...paging };
    pagingData[field.key] = field.value;
    pagingData.page = 1;
    setPaging(pagingData);
    // let url = window.location.origin + window.location.pathname + '?' + new URLSearchParams(pagingData)
    // window.history.replaceState({}, 'users', url)
    getData(pagingData);
  }

  const [loans, setLoans] = useState([]);

  useEffect(() => {
    let pagingData = {};
    if (window.location.search !== "") {
      pagingData = JSON.parse(
        '{"' +
          decodeURI(window.location.search.substring(1))
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      if (pagingData.text) {
        pagingData.text = pagingData.text.replaceAll("+", " ");
      } else {
        pagingData.text = "";
      }
      pagingData.page = parseInt(pagingData.page);
      setPaging(pagingData);
    } else {
      pagingData = { ...paging };
      pagingData.page = parseInt(pagingData.page);
    }
    getData(pagingData, true);
  }, []);

  const fields = [
    { title: "Client Name", key: "name", sorting: false },
    { title: "Company", key: "name", sorting: false },
    { title: "Agent", key: "agent", sorting: false },
    { title: "Policy Number", key: "policy_num", sorting: false },
    { title: "Loan Number", key: "loanNumber", sorting: false },
    { title: "Premium Financed", key: "premiumFinanced", sorting: false },
    { title: "Total Due", field: "balance.total" },
    { title: "Principal Balance", field: "balance.principal" },
    { title: "Status", field: "status" },
    { title: "Cancellation Status", field: "cancellationStatus" },
    { title: "N.O.C Sent", field: "noc" },
    { title: "NOC Issue Date", field: "cancelRequestedAt" },
    { title: "NOC Expiry", field: "nocExpiry" },
    { title: "Cancelled On", field: "canceledAt" },
  ];

  function FormatNumber(str) {
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleInputChange = (e) => {
    let { value } = e.target;
    // Replace non-special characters with an empty string
    const disallowedChars = new Set("!@#$%^&*()\\?\"{}[]'");

    // Filter the input to remove disallowed characters
    const filteredValue = Array.from(value)
      .filter((char) => !disallowedChars.has(char))
      .join("");

    // Log the original and filtered values for debugging
    console.log(`Original input received: ${value}`);
    console.log(
      `Filtered input (disallowed characters removed): ${filteredValue}`
    );

    if (filteredValue !== value) {
      console.warn(
        "Invalid character detected, certain special characters are not allowed."
      );
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Invalid character detected, certain special characters are not allowed.",
      });
    }

    // Update the state with the filtered value regardless
    search({ key: "text", value: filteredValue });
  };

  async function requestCancelLoan(id) {
    Swal.fire({
      title: "Attention!",
      text: "You are about to mark this client has Notice of Cancellation issued.\n!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, send it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response = await Http.request(
            "contracts",
            `contracts/cancel-notice/${id}`
          );
          Swal.fire(
            "Success!",
            "Success, This client has been marked with NOC issued.",
            "success"
          ).then(() => {
            window.location.reload();
          });
        } catch (e) {
          console.log(e);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Failed to send a notice of cancellation for the client!",
          }).then(() => {
            window.location.reload();
          });
        }
      }
    });
  }
  async function cancelLoan(id) {
    try {
      Swal.fire({
        title: "Attention!",
        text: "Are you sure you want to cancel this loan\n!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, send it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let response = await Http.request(
            "contracts",
            `contracts/cancel/${id}`
          );
          Swal.fire(
            "Success!",
            "Loan has been cancelled successfully.",
            "success"
          ).then(() => {
            window.location.reload();
          });
        }
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to cancel loan!",
      });
    }
  }

  function resetFilter() {
    const pagingData = { ...paging };
    pagingData.page = 1;
    pagingData.total = 0;
    pagingData.text = "";
    pagingData.companyType = "";
    pagingData.sort = "";
    setPaging(pagingData);
    getData(pagingData);
  }

  function getData(pagingData, showloader) {
    let dialog = null;
    if (showloader) {
      dialog = Swal.fire({
        title: "Loading...",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {},
      });
    }
    Http.request("contracts", "contracts/room/get-all", pagingData)
      .then((response) => {
        setLoans(response.loans);
        console.log(response.loans);
        let newPagingData = { ...pagingData };
        delete newPagingData.text;
        setPaging({
          ...newPagingData,
          total: response.total,
          count: response.loans.length,
          perPage: response.perPage,
        });
        if (showloader) {
          dialog.close();
        }
      })
      .catch((e) => {
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to make request to servers.",
        });
      });
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const modelTitle = (str) => {
    var text = [];
    for (var i = 0; i < str.length; i++) {
      let char = "";
      if (str.charAt(i) === str.charAt(i).toUpperCase()) {
        char = ` ${str.charAt(i)}`;
      } else {
        char = str.charAt(i);
      }
      text.push(char);
    }
    text = text.join(",");
    while (text.includes(",")) {
      text = text.replace(",", "");
    }
    return capitalizeFirstLetter(text);
  };
  function renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map((key) => {
      const newKey = newKeys[key] || key;
      return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
  }
  function updateFields(items) {
    for (var i = 0; i < items.length; i++) {
      delete items[i].contractClient;
      delete items[i].contractId;
      let oldKeys = Object.keys(items[i]);
      let newKeys = {};

      for (let j = 0; j < oldKeys.length; j++) {
        newKeys[oldKeys[j]] = modelTitle(oldKeys[j]);
      }

      items[i] = renameKeys(items[i], newKeys);
      console.log(items[i]);
    }
    return items;
  }
  function exportData(type) {
    let dialog;
    let pagingData = { ...paging };
    pagingData.text = document.getElementById("search").value;
    let showloader = true;
    if (showloader) {
      dialog = Swal.fire({
        title: "Loading...",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {},
      });
    }
    console.log(pagingData);
    if (type === "csv") {
      pagingData.exportCsv = true;
    } else {
      pagingData.exportExcel = true;
    }
    Http.request("contracts", "contracts/room/export/get-all", pagingData)
      .then((response) => {
        if (showloader) {
          dialog.close();
        }
        if (pagingData.exportExcel) {
          const url = window.URL.createObjectURL(
            new Blob([new Uint8Array(response.buffer.data)])
          );
          const a = document.createElement("a");
          a.setAttribute("href", url);
          a.setAttribute("download", `${Date()}_excel_export_arrears.xlsx`);
          a.click();
        } else {
          response.loans = updateFields(response.loans);
          json2csv(response.loans, {}).then((jsonData) => {
            const blob = new Blob([jsonData], { type: "text/csv" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.setAttribute("href", url);
            a.setAttribute("download", `${Date()}_csv_export_arrears.csv`);
            a.click();
          });
        }
      })
      .catch((e) => {
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to make request to servers.",
        });
      });
  }
  function openLoan(contract) {
    let purpose = contract.purpose?.toLowerCase();
    if (purpose === "insurance-premium-financing") {
      purpose = "retail";
    }
    window.location.replace(
      `${window.location.origin}/dashboard/clients/${purpose}/profile/${contract.clientId}/${contract.contractId}`
    );
  }
  return (
    <div className="flex justify-center ">
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0 w-full">
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
            <div className="mb-10">
              <h1 className="text-4xl text-center font-semibold  leading-6 text-gray-900">
                (Cancel Room)
              </h1>
            </div>

            <div className="px-4 mt-7 ">
            <div className="mx-auto px-4 mb-4">
  <div className="mt-6 flex gap-x-4" action="#">
    <div className="min-w-0 flex-1">
      <label className="sr-only">Search</label>
      <div className="relative rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <svg
            className="h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
        <input
          id={"search"}
          value={paging.text}
          onChange={handleInputChange}
          type="search"
          name="search"
          className="block w-full rounded-md border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Search using Loan number, Client Name, Policy Number"
        />
      </div>
    </div>
    
    {/* Cancellation Status Dropdown */}
    <div className="min-w-0 flex-1">
      <label className="sr-only">Search By Cancellation Status</label>
      <div
        className="relative rounded-md shadow-sm"
        style={{ height: "100%" }}
      >
        <select
      name="cancellationStatus"
      id="cancellationStatus"
      className="block h-full w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      value={paging.cancellationStatus || ""}
      onChange={(e) => {
        const newStatus = e.target.value || null; // Get the selected status
        const updatedPaging = { ...paging, cancellationStatus: newStatus };
        setPaging(updatedPaging); // Update paging state
        getData(updatedPaging); // Trigger data fetch
      }}
    >
      <option value="">Select a Cancellation Status</option>
      <option value="awaitingCancellation">Awaiting Cancellation</option>
      <option value="noticeCancellation">Notice of Cancellation</option>
    </select>
      </div>
    </div>

    {/* Buttons */}
    <span className="isolate inline-flex rounded-md shadow-sm">
      <button
        onClick={() => {
          resetFilter();
        }}
        type="button"
        className="relative -ml-px inline-flex items-center bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
      >
        Reset
      </button>
      
      <button
        onClick={() => {
          exportData("csv");
        }}
        type="button"
        className="relative -ml-px inline-flex items-center bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
      >
        Export CSV
      </button>
      <button
        onClick={() => {
          exportData("excel");
        }}
        type="button"
        className="relative -ml-px inline-flex items-center rounded-r-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-10"
      >
        Export Excel
      </button>
    </span>
  </div>
</div>


              {/* loan table*/}
              <div className="hidden sm:block">
                <div className="mx-auto px-4">
                  <div className="mt-2 flex flex-col">
                    <div className="min-w-full overflow-x-auto align-middle shadow sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            {fields.map((field, index) => {
                              return (
                                <th
                                  key={index}
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6"
                                >
                                  {field.title}
                                </th>
                              );
                            })}
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {loans.map((loan, index) => (
                            <tr
                              key={index}
                              className="bg-white cursor-pointer w-100"
                            >
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {nullTransform(
                                  loan?.client?.personal?.fullName || ""
                                )}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {nullTransform(
                                  subString(
                                    loan?.meta?.insuranceDetails?.company_name,
                                    20
                                  ) || ""
                                )}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {nullTransform(loan?.createdBy?.fullName || "")}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {nullTransform(
                                  loan?.meta?.insuranceDetails?.policy_number ||
                                    ""
                                )}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {nullTransform(loan?.loanNumber || "")}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                $
                                {nullTransform(
                                  FormatNumber(
                                    parseFloat(loan?.principal).toFixed(2) ||
                                      "0"
                                  )
                                )}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                $
                                {nullTransform(
                                  FormatNumber(
                                    parseFloat(
                                      loan?.balance?.total || 0
                                    ).toFixed(2) || "0"
                                  )
                                )}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                $
                                {nullTransform(
                                  FormatNumber(
                                    parseFloat(
                                      loan?.balance?.principal || 0
                                    ).toFixed(2) || "0"
                                  )
                                )}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <div
                                  className={classNames(
                                    statuses[loan?.status],
                                    "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset flex justify-center"
                                  )}
                                >
                                  <div>{nullTransform(loan?.status || "")}</div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <div
                                  className={classNames(
                                    statuses[loan?.cancellationStatus],
                                    "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset flex justify-center"
                                  )}
                                >
                                  <div>
                                    {nullTransform(
                                      loan?.cancellationStatus || ""
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <div class="flex items-center justify-end gap-x-2 sm:justify-start">
                                  {loan.noc && (
                                    <div class="flex-none rounded-full p-1 text-green-400 bg-green-400/10">
                                      <div class="h-1.5 w-1.5 rounded-full bg-current"></div>
                                    </div>
                                  )}
                                  {loan.noc === false && (
                                    <div class="flex-none rounded-full p-1 text-rose-400 bg-rose-400/10">
                                      <div class="h-1.5 w-1.5 rounded-full bg-current"></div>
                                    </div>
                                  )}
                                  <div class="hidden sm:block">
                                    {nullTransform(`${loan?.noc}` || "")}
                                  </div>
                                </div>
                              </td>

                              <td
                                className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                                onClick={() => {
                                  window.location = `${window.location.origin}/dashboard/clients/${loan.contractType}/profile/${loan.contractClient}/${loan.contractId}`;
                                }}
                              >
                                {nullTransform(
                                  loan?.cancelRequestedAt?.substring(0, 10) ||
                                    ""
                                )}
                              </td>

                              <td
                                className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                                onClick={() => {
                                  window.location = `${window.location.origin}/dashboard/clients/${loan.contractType}/profile/${loan.contractClient}/${loan.contractId}`;
                                }}
                              >
                                {nullTransform(
                                  loan?.nocExpiry?.substring(0, 10) || ""
                                )}
                              </td>

                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <>
                                  {loan.status === "cancelled" && (
                                    <>
                                      {nullTransform(
                                        loan?.cancelledAt?.substring(0, 10) ||
                                          ""
                                      )}
                                    </>
                                  )}
                                </>
                              </td>
                              <td
                                className={classNames(
                                  "relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                                )}
                              >
                                <CancellationRoomOptions
                                  cancelLoan={cancelLoan}
                                  requestCancelLoan={requestCancelLoan}
                                  loan={loan}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/* Pagination */}
                    </div>
                    <Pagination getData={getData} paging={paging} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
