import React from "react";
import moment from "moment";
import Http from "../../../../util/http";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { findFlagUrlByIso3Code } from "country-flags-svg";
import CommercialClientInfoVerifyModal from "../../../../components/CommercialClientInfoVerifyModal";

import { useEffect, useState } from "react";
import AccessControl from "../../../../util/accessControl";
import Infopib from "../../../../components/infopib";
import swal from "sweetalert";
import SlimSchedule from "../../../../components/dashboard/slimschedule";
import {sortListAlphabeticallyByKey} from "../../../../util/util";

export default function CreateCommercialContract() {
  const [showBreakdown,setShowBreakDown] = useState(false)
  const [client, setClient] = useState({
    commercial: {},
    contact: {},
    address: {},
  });
  const [info, setInfo] = useState({
    name: "",
    premium: 0,
    months: "",
    startDate: "",
    paymentDate: "",
    policyNumber: "",
    policyType:"",
    paymentType: "cash",
    policyEndDate:""
  });
  const [policies, setPolicies] = useState([
    {
      number: "",
      type: "",
      premium: 0,
      company: "",
    },
  ]);
  const [summary, setSummary] = useState({
    data: [],
    headers: {
      interest: 2,
    },
  });
  const [organization, setOrganization] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [modalToggle, setModalToggle] = useState(false);
  const [coverTypes, setCoverTypes] = useState([]);
  const [modalAction, setModalAction] = useState(0);
  const [commUser, setCommUser] = useState({
    _id: "",
    photoLink: "",
    businessPhoneNumberOne: "...",
    businessPhoneNumberTwo: "...",
    businessFaxNumber: "...",
    businessEmailAddress: "...",
    businessName: "...",
    businessAddress: "...",
    businessAddressFirstLine: "...",
    businessAddressSecondLine: "",
    businessAddressCity: "...",
    businessAddressState: "...",
    businessAddressCountry: "...",
    businessClientCode: "...",
    businessDescription: "...",
    businessRegistrationNumber: "...",
    authorizerFirstName: "...",
    authorizerLastName: "...",
    paymentInfoType: "...",
    paymentInfoBankName: "...",
    paymentInfoAccountType: "...",
    paymentInfoAccountNumber: "...",
  });

  async function getCompanies() {
    try {
      let payload = {
        page: 1,
        sort: "",
        total: 2,
        count: 2,
        perPage: 100,
        companyType: "Insurance Company",
        limit: 100,
      };
      let response = await Http.request(
        "admin",
        "organizations/get-all",
        payload
      );

      let _org = JSON.parse(localStorage.organization);
      if (
        _org.type === "Insurance Company" ||
        _org.type === "BFTTL Administration"
      ) {
        setCompanies([_org]);
      } else {
        response.organizations = sortListAlphabeticallyByKey(response.organizations, 'name');
        setCompanies(response.organizations);
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to get companies.",
      });
    }
  }

  useEffect(() => {
    async function Init() {
      const contractDraft = window.localStorage.getItem("contractDraft");
      if (typeof contractDraft !== "undefined" && contractDraft !== null) {
        const contractDraftContent = JSON.parse(contractDraft);
        if (contractDraftContent.action !== "commercial") return;
        setPolicies(contractDraftContent.payload.policies);
        contractDraftContent.payload.info["paymentType"] = "cash";
        setInfo(contractDraftContent.payload.info);
        try {
          contractDraftContent.payload.info.policies =
            contractDraftContent.payload.policies;
          contractDraftContent.payload.info.months = parseInt(
            contractDraftContent.payload.info.months || 0
          );
          contractDraftContent.payload.info.premium = parseFloat(
            contractDraftContent.payload.info.premium || 0
          );

          contractDraftContent.payload.info.type = "commercial";
          contractDraftContent.payload.info.policyStartDate = moment().format("YYYY-MM-DD");
          contractDraftContent.payload.info.policyEndDate = moment(contractDraftContent.payload.info.startDate).add(3, 'months').format('YYYY-MM-DD');
          //window.localStorage.removeItem("contractDraft");
          let response = await Http.request(
            "contracts",
            "contracts/amortise",
            contractDraftContent.payload.info
          );
          setSummary(response);
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.message,
          });
        }
      } else {
        window.localStorage.removeItem("contractDraft");
      }
    }

    async function fetchAPI() {
      let dialog = Swal.fire({
        title: "Loading...",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {},
      });
      try {
        let response = await Http.request(
          "contracts",
          "client/commercial/get-one",
          {
            id: window.location.search.replace("?id=", "") /*,type:'clientId'*/,
          }
        );
        setClient({
          commercial: response,
          contact: {
            email: response.businessEmailAddress,
            phoneOne: response.businessPhoneNumberOne,
          },
        });
        setCommUser(response);
        await getCoverTypes();
        await getCompanies();
        dialog.close();
      } catch (e) {
        console.log(e);
        dialog.close();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to make request to servers.",
        }).then(() => {
          window.location.href = `${window.location.origin}/dashboard/clients/not-found`;
        });
      }
    }

    fetchAPI().then(() => {
      Init().then(() => {});
    });
  }, []);
  const delay = (delayInms) => {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  };

  async function changeVal(val, key){
    // console.log("Disabled buttons")
    // return;
    let payload = { ...info };

    payload[key] = val;
    console.log(payload);
    if (key === "startDate") {
      let _date = moment(val).add(1, "months").format().substring(0, 10);
      payload.paymentDate = _date;
      console.log({ payload });
    }
    if (
      key === "premium" &&
      payload.premium > 0 &&
      payload.premium !== isNaN(val)
    ) {
      let newSummary = { ...summary };
      newSummary.headers.interest = await getInterest(payload.premium);
      setSummary(newSummary);
    }

    setInfo(payload);
    await delay(1000);
    let isBetween = inRange(payload);
    if (isBetween) {
      await getSummary(payload);
    }
  }
  async function setInvalidFields(ids) {
    for (let i = 0; i < ids.length; i++) {
      try{
        console.log(ids[i]);
        let input = document.getElementById(ids[i]);

        if (input.value === "0" || input.value === "") {
          input.className =
              "block w-9/12 rounded-md border-0 py-1.5 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6";
        } else {
          input.className =
              "block w-9/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6";
        }
      }catch (e) {
        console.log(e)
      }
    }
  }
  async function setInvalidFieldsList(ids) {
    try{
      for (let i = 0; i < ids.length; i++) {
        let inputs = document.querySelectorAll(`#${ids[i]}`);

        for (let j = 0; j < inputs.length; j++) {
          let input = inputs[j];
          if (input !== undefined) {
            console.log({ input });
            if (input.value === "0" || input.value === "") {
              input.className =
                  "block w-full rounded-md border-0 py-1.5 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6";
            } else {
              input.className =
                  "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6";
            }
          }
        }
      }
    }catch (e) {
      console.log("Failure to set invalid fields", e);
    }
  }
  function isvalidSecondPaymentDate(startDate,secondPaymentDate){
    var start = moment(startDate);
    var second = moment(secondPaymentDate);
    console.log({start,second})
    var diff = second.diff(start,"day");
    console.log("diff",diff)
    if(diff > 0 && diff <= 61){
      console.log("in range")
      return true
    }else{
      console.log("out of range")
      Swal.fire({
        icon: "info",
        title: "Oops...",
        text: "Payment Date can only be within a 2 month range of Start Date.",
      })
      return false
    }
  }

  async function createContract() {
    console.log({months: parseInt(info.months)});
    if (parseInt(info.months) > 11 || parseInt(info.months) < 3) {
      alert("Contract period must be between 3 and 12 months");
      return;
    }
    let validsecond = isvalidSecondPaymentDate(info.startDate,info.paymentDate)
    if(!validsecond){
      return;
    }

    let dialog = Swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {},
    });
    let _info = {...info}
    _info.paymentType = 'cash'
    _info.policyType = document.getElementById("policyType")?.value;
    _info.insuranceCompany = document.getElementById("insuranceCompany")?.value;
    _info.policyNumber = document.getElementById("policyNumber")?.value;
    _info.policyStartDate = document.getElementById("policyStartDate")?.value;
    console.log({_info})
    setInfo(_info)
    let ids = [
      "premium",
      "paymentType",
      "insuranceCompany",
      "policyNumber",
      "policyType",
      "months",
      "policyStart",
      "policyEndDate",
      "paymentDate",
        "policyStartDate"
    ];
    setInvalidFields(ids);
    setInvalidFieldsList([
      "company",
      "policy-number",
      "coverage-type",
      "policy-premium",
    ]);
    try {
      let _sum = parseFloat(
        policies
          .reduce((n, { premium }) => n + parseFloat(premium), 0)
          .toFixed(2)
      );
      let send = false;
      if (_sum <= info.premium) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "The sum of the policies is less than the premium financed.",
          showConfirmButton: false,
          timer: 2000,
        });
        send = true;
      }

      if (_sum > info.premium) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title:
            "The sum of the policies is greater than the premium financed.",
          showConfirmButton: false,
          timer: 2000,
        });
      }

      if (send) {
        dialog.close();dialog.close();
        const missingFields = [];
        if (!_info.policyType) missingFields.push("policyType");
        if (!_info.policyEndDate) missingFields.push("policyEndDate");
        if (!_info.paymentType) missingFields.push("paymentType");
        if (!_info.insuranceCompany) missingFields.push("insuranceCompany");
        if (!_info.policyNumber) missingFields.push("policyNumber");
        if (_info.premium <= 0) missingFields.push("premium");
        if (_info.months <= 1) missingFields.push("months");
        if (!_info.startDate) missingFields.push("startDate");
        if (!_info.paymentDate) missingFields.push("paymentDate");

        if (missingFields.length === 0)
        {


          if (summary.data.length === 0) {
            return;
          } else {
            let payload = { ...info };
            //check ranger here

            let isBetween = inRange(payload);
            if (!isBetween) {
              Swal.fire({
                icon: "info",
                title: "Opps!",
                text: `Please check for start date invalid field.`,
              });
              return;
            }

            payload.summary = summary;
            payload.client = client.commercial;
            for (let i = policies.length - 1; i >= 0; i--) {
              if (policies[i].company === "" || policies[i].type === "" || policies[i].premium === 0 || policies[i].number ==="") { // Condition to remove elements, for example, remove 3
                policies.splice(i, 1); // Remove the element at index i
              }
            }
            payload.policies = policies;
            payload.user = localStorage.getItem("user")._id;
            try {
              let response = await Http.request(
                "contracts",
                "contracts/commercial/create",
                payload
              );
              setModalToggle(false);
              Swal.fire({
                icon: "success",
                title: "Hurray!",
                text: `Client contract created successfully. Contract will be returned to your inbox shortly`,
              }).then(() => {
                window.location.href = `${
                  window.location.origin
                }/dashboard/clients/commercial/profile/${window.location.search.replace(
                  "?id=",
                  ""
                )}`;
              });
            } catch (e) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to create a contract for this client. Please contact Solis staff.",
              });
            }
          }
        } else {
          console.log({missingFields})
          alert("Please check all fields.");
        }
      }
      dialog.close();
    } catch (err) {
      dialog.close();
      console.log(err);
      setModalToggle(false);
      Swal.fire({
        icon: "info",
        title: "Oops...",
        text: "Please check the fields.",
      });
    }
  }

  async function setDay(day) {
    let paymentDate;
    let daysInMonth = moment(info.startDate).add(1, "months").daysInMonth();
    if (day === 30 && daysInMonth < 30) {
      day = daysInMonth;
    }
    paymentDate = moment(info.startDate)
      .set({ date: day })
      .add(1, "month")
      .format()
      .substring(0, 10);
    if (
      info.paymentDate !== "" &&
      info.paymentDate !== undefined &&
      info.paymentDate !== null
    ) {
      paymentDate = moment(new Date(`${info.startDate}`))
        .add(1, "month")
        .set({ date: day })
        .format()
        .substring(0, 10);
    }

    let payload = { ...info };
    payload.paymentDate = paymentDate;
    setInfo(payload);
    getSummary(payload);
  }

  async function getSummary(payload) {
    try {
      let isBetween = inRange(payload);
      if (!isBetween) {
        return;
      }
      if (parseInt(payload.months) > 11 || parseInt(payload.months) < 3) {
        alert("Contract period must be between 3 and 12 months");
        return;
      }
      if (
        payload.premium === 0 ||
        payload.months === undefined ||
        payload.months === 0 ||
        payload.months === "" ||
        payload.startDate === "" ||
        payload.paymentDate === ""
      ) {
        return;
      }
      let validsecond = isvalidSecondPaymentDate(payload.startDate,payload.paymentDate)
      if(!validsecond){
        return;
      }
      payload.policies = policies;

      //Removed so that user can see the amortized table with having to fill policy information
      // if (
      //   policies[0].premium === "" ||
      //   policies[0].premium === 0 ||
      //   payload.startDate === "" ||
      //   payload.paymentDate === ""
      // ) {
      //   return;
      // }
      payload.months = parseInt(payload.months || 0);
      payload.premium = parseFloat(payload.premium || 0);

      payload.type = "commercial";

      let response = await Http.request(
        "contracts",
        "contracts/amortise",
        payload
      );
      setSummary(response);
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err.message,
      });
    }
  }

  async function setMonth(val) {
    console.log({ val });
    if (val === "") {
      await changeVal("", "months");
      return;
    }

    if (parseInt(val) < 12 || parseInt(val) > 2) {
      await changeVal(val, "months");
      //getSummary()
    }
  }

  async function mutatePolicy(action, index, value, field) {
    let payload = JSON.parse(JSON.stringify(policies));
    let _info = { ...info };
    switch (action) {
      case "update":
        payload[index][field] = value;
        setPolicies(payload);
        break;

      case "add":
        payload.push({
          number: "",
          type: coverTypes[0].name,
          premium: 0,
          company: "",
        });
        setPolicies(payload);
        break;
      case "remove":
        payload.splice(index, 1);
        setPolicies(payload);
        break;
    }
    _info.policies = payload;

    let _sum = parseFloat(
      payload.reduce((n, { premium }) => n + parseFloat(premium), 0).toFixed(2)
    );

    getSummary(_info);
  }
  function FormatNumber(str) {
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function saveModalData(obj) {
    //let isvalidPolicyEnd = isContractPeriodValid();
    let isvalidPolicyEnd = true;
    if (!isvalidPolicyEnd) return;
    Http.request("contracts", "client/commercial/edit-info", obj)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Hurray!",
          text: "Commercial client information saved",
        }).then(() => {
          createContract();
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err || "Failed to make request to servers.",
        }).then(() => {
          createContract();
        });
      });
  }
  async function getInterest(premium) {
    try {
      let response = await Http.request("contracts", "contracts/get-interest", {
        premium,
      });
      //console.log({response})
      return response.rate;
    } catch (err) {
      console.log(err);
    }
  }
  function openModal() {
    setModalToggle(true);
  }
  async function getCoverTypes() {
    try {
      let payload = {
        text: "",
        sort: "",
        total: 0,
        page: 1,
        count: 0,
        perPage: 999,
        type: "",
        companyType: "",
      };
      let response = await Http.request("admin", "coverTypes/get-all", payload);
      if (coverTypes.length < 1) {
        response.coverages = response.coverages.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.name === value.name)
        );
        response.coverages = sortListAlphabeticallyByKey(response.coverages, 'name');
        setCoverTypes(response.coverages);
        let _polices = JSON.parse(JSON.stringify(policies));
        _polices[0].type = response.coverages[0].name;
        console.log({ p: _polices });
        //setPolicies(_polices);
      }
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to make request to servers.",
      });
    }
  }
  function nullTransform(val) {
    if (val === null || val === undefined) {
      return "";
    }
    return val;
  }

  function inRange(payload) {
    let isBetween = false;
    try {
      let date = moment(payload.startDate);
      isBetween = date.isBetween(
        moment().subtract(1, "day"),
        moment().add(2, "years")
      );
      console.log({ isBetween, date: date.toDate(), raw: payload.startDate });
    } catch (error) {
      console.log("Error checking date", error);
    }
    return isBetween;
  }
  function isContractPeriodValid() {
    var ldate = new Date(new Date(summary.data[summary.data.length - 1]?.dueDate.replaceAll("-","/")).setHours(0,0,0,0));
    var ppdate = new Date(new Date(info.policyEndDate.replaceAll("-","/")).setHours(0,0,0,0));
    var sdate = new Date(new Date(info.startDate.replaceAll("-","/")).setHours(0,0,0,0));
    var diffDays = parseInt((ldate - ppdate) / (1000 * 60 * 60 * 24), 10); 
    var isAfterStart = ppdate > sdate
    let isValid = false;
    console.log({
      ldate,
      ppdate,
      isValid,
      diffDays,
      isAfterStart
    });
    if(diffDays >= 30 && isAfterStart === true) {
      isValid = true;
    }
    if (!isValid) {
      Swal.fire({
        icon: "info",
        title:
          "Please review the policy end date it does not meet the requirements.",
        text: "Last payment for the contract should be at least one month prior to the policy end date.",
      });
      return isValid;
    } else {
      return true;
    }
  }

  return (
    <>
      <Infopib />
      <div className="mx-20">
        <div className="mt-6 flex justify-center w-100">
          <div className="w-100">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              New IPF Contract
            </h2>
            <CommercialClientInfoVerifyModal
              open={modalToggle}
              setOpen={setModalToggle}
              commUser={commUser}
              setCommUser={setCommUser}
              save={saveModalData}
            ></CommercialClientInfoVerifyModal>
          </div>
        </div>
        <div className="mt-7 flex">
          <div
            className="w-1/12"
            style={{
              border: "1px solid black",
              borderRadius: "12%",
              height: "64px",
              width: "64px",
            }}
          >
            <img style={{}} src="/avatar4.png" alt="Solis Credit" />
          </div>
          <div className="w-11/12 grid ml-4" style={{}}>
            <div className="min-w-100">
              {" "}
              <h2 className="text-xl  leading-7 text-gray-900">
                {nullTransform(client.commercial.businessName || "")}
              </h2>
            </div>
            <div className="min-w-100 flex mt-3">
              <div className="mr-2 mt-1" style={{ width: "35px" }}>
                <img
                  className="mb-2"
                  style={{ width: "35px", height: "19px" }}
                  src={findFlagUrlByIso3Code(
                    nullTransform(client.commercial.businessAddressCountry)
                  )}
                />
              </div>
              <div className="text-sm font-semibold leading-7 text-gray-900">
                {nullTransform(client.commercial.businessClientCode || "")}
              </div>
              <div className="flex">
                <div className="ml-3 text-sm font-semibold leading-7 text-gray-900">
                  Email: 
                </div>
                <div className="ml-2 text-sm leading-7 text-gray-900">
                  {nullTransform(client.contact.email || "")}
                </div>
              </div>
              <div className="flex">
                <div className="ml-3 text-sm font-semibold leading-7 text-gray-900">
                  Registration: 
                </div>
                <div className="ml-2 text-sm leading-7 text-gray-900">
                  {nullTransform(
                    client.commercial.businessRegistrationNumber || ""
                  )}
                </div>
              </div>
              <div className="flex">
                <div className="ml-3 text-sm font-semibold leading-7 text-gray-900">
                  Phone: 
                </div>
                <div className="ml-2 text-sm leading-7 text-gray-900">
                  {nullTransform(client.contact.phoneOne || "")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-7" />

        <div className="flex w-100 pt-12">
          <div className="grid w-1/2 mr-10">
            <div>
              <div className="text-xl font-bold leading-7 text-gray-900">
                Insurance Company
              </div>
              <div className="text-sm text-gray-500">
                Enter desired insurance company.
              </div>
              <div className="mt-2">
                <select
                    required
                    onChange={(e) => {
                      changeVal(e.target.value, "insuranceCompany");
                    }}
                    value={info.insuranceCompany}
                    id="insuranceCompany"
                    className="mt-2 block w-9/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value="">Select Insurance Company</option>
                  {companies.map((company) => (
                      <option value={company._id}>{company.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="text-xl font-bold leading-7 text-gray-900 mt-5">
              Policy Number:
            </div>
            <div className="text-sm text-gray-500">
              Enter Policy number here.
            </div>
            <div className="mt-2">
              <input
                  onChange={(e) => {
                    changeVal(e.target.value, "policyNumber");
                  }}
                  value={info.policyNumber}
                  type="text"
                  name="policyNumber"
                  id="policyNumber"
                  className="block w-9/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Enter policy number"
              />
            </div>

            <div>
              <div className="text-xl font-bold leading-7 text-gray-900 mt-6">
                Policy Type:
              </div>

              <select
                  id={"policyType"}
                  required
                  onChange={(e) => {
                    changeVal(e.target.value, "policyType");
                  }}
                  value={info.policyType}
                  className="mt-2 block w-9/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option value="">Select Policy Type</option>
                {coverTypes.map((policy) => (
                    <option value={policy.name}>{policy.name}</option>
                ))}
              </select>
            </div>
            <div className="mt-6">
              <div className="text-xl font-bold leading-7 text-gray-900">
                Policy Start Date:
              </div>
              <div className="text-sm text-gray-500">
                When did your policy start.
              </div>
              <div className="mt-2">
                <input
                    value={info.policyStartDate}
                    onChange={(e) => {
                      changeVal(e.target.value, "policyStartDate");
                    }}
                    type="date"
                    name="policyStartDate"
                    id="policyStartDate"
                    className="block w-9/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="mt-6">
              <div className="text-xl font-bold leading-7 text-gray-900">
                Policy End Date:
              </div>
              <div className="mt-2 flex">
                <input
                    value={info.policyEndDate}
                    onChange={(e) => {
                      changeVal(e.target.value, "policyEndDate");
                    }}
                    type="date"
                    name="start"
                    id="policyEndDate"
                    min={moment(info.startDate).add(3, 'months').format('YYYY-MM-DD')} // Set min attribute to today's date
                    max={moment().add(12, 'months').format('YYYY-MM-DD')}
                    className="block w-4/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="mt-6">
              <div className="text-xl font-bold leading-7 text-gray-900">
                Preferred Payment Date:
              </div>
              <div className="text-sm text-gray-500">
                What day of the month will regular payments be made?
              </div>
              <div className="mt-2 flex">
                <input
                    value={info.paymentDate}
                    onChange={(e) => {
                      changeVal(e.target.value, "paymentDate");
                    }}
                    min={new Date().toISOString().split('T')[0]} // Sets the minimum selectable date to today
                    max={moment().endOf("month").format("YYYY-MM-DD")}
                    type="date"
                    name="start"
                    id="paymentDate"
                    className="block w-4/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <div className="flex w-8/12 ml-3">
                  <span
                      onClick={() => {
                        setDay(15);
                      }}
                      style={{cursor: "pointer"}}
                      className="flex justify-center rounded-full bg-gray-300 px-5 py-1 text-sm font-medium text-gray-600 pt-2"
                  >
                    15
                  </span>
                  <span
                      onClick={() => {
                        setDay(25);
                      }}
                      style={{cursor: "pointer"}}
                      className="flex justify-center rounded-full bg-gray-300 px-5 py-1 text-sm font-medium text-gray-600 pt-2 ml-2"
                  >
                    25
                  </span>
                  <span
                      onClick={() => {
                        setDay(30);
                      }}
                      style={{cursor: "pointer"}}
                      className="flex justify-center rounded-full bg-gray-300 px-5 py-1 text-sm font-medium text-gray-600 pt-2 ml-2"
                  >
                    30
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="grid w-1/2">
            <div>
              <div className="text-2xl leading-7 text-gray-900">Summary</div>
              <div className="grid pt-5">
                <div className="text-lg leading-6 text-gray-900 w-100">
                  Interest Rate:
                </div>
                <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                  {summary.headers.interest || 0}%
                </div>
              </div>
              <div className="grid pt-4">
                <div className="text-lg leading-6 text-gray-900 w-100">
                  Interest Amount:
                </div>
                <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                  $
                  {FormatNumber(
                      nullTransform(
                          parseFloat(summary.headers.totalInterest || 0).toFixed(2)
                      )
                  )}
                </div>
              </div>
              <div className="grid pt-4">
                <div className="text-lg leading-6 text-gray-900 w-100">
                  Premium Financed + Interest:
                </div>
                <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                  $
                  {FormatNumber(
                      nullTransform(
                          parseFloat(summary.headers.total || 0).toFixed(2)
                      )
                  )}
                </div>
              </div>
              <div className="grid pt-4 mb-4">
                <div className="text-lg leading-6 text-gray-900 w-100">
                  First Installment Amount:
                </div>
                <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                  {summary.data.length > 0 && (
                    <>
                      $
                      {FormatNumber(
                        nullTransform(
                          parseFloat(summary.data[0].instalment).toFixed(2) ||
                            ""
                        )
                      )}
                    </>
                  )}
                  {summary.data.length === 0 && <>$0</>}
                </div>
              </div>
              <div className="grid pt-4 mb-4">
                <div className="text-lg leading-6 text-gray-900 w-100">
                  Monthly Installment Amount:
                </div>
                <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                  {summary.data.length > 0 && (
                    <>
                      $
                      {FormatNumber(
                        nullTransform(
                          parseFloat(summary.data[1]?.instalment).toFixed(2) ||
                            ""
                        )
                      )}{" "}
                      starting{" "}
                      {
                        moment(nullTransform(summary.data[0]?.dueDate || ""))
                          .format("ll")
                          .split(",")[0]
                      }
                    </>
                  )}
                  {summary.data.length === 0 && <>$0 starting</>}
                </div>
              </div>
              <div className="flex">
                <div className="grid w-1/3 pt-6">
                  <a
                      href={"/dashboard/clients"}
                      type="button"
                      className="rounded-md text-center bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                  >
                    Cancel
                  </a>
                </div>
                <div className="grid mt- pt-6 w-1/3 mx-2">
                  <AccessControl
                      permission={"createCommercialContract"}
                      fallback={<></>}
                      target={["god"]}
                  >
                    <button
                        onClick={() => {
                          createContract();
                        }}
                        type="button"
                        className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Create Contract
                    </button>
                  </AccessControl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-7"/>
        <div className="flex w-100 pt-5">
          <div className="grid w-100">
            <div className="text-2xl leading-7 text-gray-900">Policies</div>
            {policies.map((policy, index) => {
              return (
                  <div className="mt-3 grid bg-zinc-200" key={index}>
                    <div className="flex">
                      <div className="w-11/12 pl-5 pt-2 text-sm leading-7 text-gray-900">
                        Enter the details of a single policy here.
                      </div>
                      {policies.length !== 1 && (
                          <div className="w-1/12">
                            <FontAwesomeIcon
                                onClick={() => {
                            mutatePolicy("remove", index, null, null);
                          }}
                          icon={faTrash}
                          className="fas fa-check pt-3 text-lg leading-7"
                          style={{ color: "red", cursor: "pointer" }}
                        />
                      </div>
                    )}
                  </div>

                  <div className="px-5 pb-5 grid grid-cols-1 gap-x-6 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <div className="mt-2">
                        <select
                          value={policy.company}
                          onChange={(e) => {
                            mutatePolicy(
                              "update",
                              index,
                              e.target.value,
                              "company"
                            );
                          }}
                          type="text"
                          name="company"
                          id="company"
                          autoComplete="company"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                          <option value="">Select Insurance Company</option>
                          {companies.map((company) => (
                            <option value={company._id}>{company.name}</option>
                          ))}
                        </select>
                      </div>
                      <label
                        htmlFor="coverage-type"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Insurance Company
                      </label>
                    </div>
                    <div className="sm:col-span-3">
                      <div className="mt-2">
                        <select
                          value={policy.type}
                          onChange={(e) => {
                            mutatePolicy(
                              "update",
                              index,
                              e.target.value,
                              "type"
                            );
                          }}
                          type="text"
                          name="coverage-type"
                          id="coverage-type"
                          autoComplete="coverage-type"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                          <option value="">Select a Coverage Type</option>
                          {coverTypes.map((policy, i) => (
                            <option key={i} value={policy.name}>
                              {policy.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <label
                        htmlFor="coverage-type"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Coverage Type
                      </label>
                    </div>
                    <div className="sm:col-span-3 mt-2">
                      <div className="mt-2">
                        <input
                          value={policy.number}
                          onChange={(e) => {
                            mutatePolicy(
                              "update",
                              index,
                              e.target.value,
                              "number"
                            );
                          }}
                          type="text"
                          name="policy-number"
                          id="policy-number"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <label
                        htmlFor="policy-number"
                        className="block font-bold text-sm font-medium leading-6 text-gray-900"
                      >
                        Policy Number
                      </label>
                    </div>

                    <div className="sm:col-span-3 mt-2">
                      <div className="mt-2">
                        <input
                          value={policy.premium}
                          onChange={(e) => {
                            mutatePolicy(
                              "update",
                              index,
                              e.target.value,
                              "premium"
                            );
                          }}
                          type="number"
                          name="policy-number"
                          id="policy-premium"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <label
                        htmlFor="policy-number"
                        className="block font-bold text-sm font-medium leading-6 text-gray-900"
                      >
                        Premium to be Financed
                      </label>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="flex mt-4">
              <button
                onClick={() => {
                  mutatePolicy("add", null, null, null);
                }}
                className="rounded-full h-10 w-10 bg-orange-500"
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className="font-medium text-2xl leading-7 mt-1"
                  style={{ color: "white" }}
                />
              </button>
              <div className="text-base ml-3 mt-2">Add another Policy</div>
            </div>
          </div>
        </div>
        <hr className="mt-7" />
        <div className="flex w-100 pt-5">
          <div className="grid w-100">

            {showBreakdown === false &&
                <div style={{"cursor": "pointer"}} onClick={() => {
                  setShowBreakDown(true)
                }} className="text-2xl leading-7 text-blue-500 hover:text-blue-700">
                  Show Payment Schedule <i className="fa-solid fa-arrow-down"></i>
                </div>
            }
            {showBreakdown === true &&
                <div style={{"cursor": "pointer"}} onClick={() => {
                  setShowBreakDown(false)
                }} className="text-2xl leading-7 text-gray-900">
                  Payment Schedule
                </div>
            }

            {showBreakdown === true &&
                <SlimSchedule summary={summary}/>
            }
          </div>
        </div>
      </div>
    </>
  );
}
