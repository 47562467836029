import {
  getDeepValue,
  getStatus,
  nullTransform,
  FormatNumber,
} from "../../util/util";
import React from "react";

export default function RetailContractsListView({
  contracts,
  setCurrentContract,
  setContractView,
  genLink,
}) {
  return (
    <ul className="divide-y divide-gray-100">
      {contracts.length > 0 && (
        <>
          {contracts.map((contract, index) => (
            <li
              onClick={(e) => {
                setCurrentContract(contract);
                setContractView(true);
              }}
              key={index}
              className="relative justify-between gap-x-6 py-5"
            >
              <div
                style={{ cursor: "pointer" }}
                className="border border-solid"
              >

                <div className="grid grid-cols-4 px-5 py-5">
                  <div className="col-span-1">
                    <span className="whitespace-nowrap py-4 pl-4 pr-3 text-2xl  text-gray-900">
                      {contract?.loan.loanNumber}
                    </span>
                    <br />
                    <br />
                    <span className="whitespace-nowrap py-4 pl-4 pr-3 text-md font-medium text-gray-900">
                      Balance:{" "}
                      <b>
                        $
                        {nullTransform(
                          FormatNumber(
                            `${parseFloat(
                              getDeepValue(contract, "loan.balance.total") || 0
                            ).toFixed(2)}`
                          )
                        )}
                      </b>
                    </span>
                  </div>
                  <div className="col-span-2">
                    <span className="whitespace-nowrap px-3 py-4 font-extrabold text-lg text-cyan-700">
                      {nullTransform(contract.policyType || "")}
                    </span>
                    <br />
                    <br />
                    <span className="whitespace-nowrap px-3 py-4 text-md font-medium text-gray-900">
                      Policy Number:{" "}
                      <b>{nullTransform(contract.policyNumber || "")}</b>
                    </span>
                    <span className="whitespace-nowrap px-3 py-4 text-md font-medium text-gray-900">
                      Months:{" "}
                      <b>({nullTransform(contract.months || "")}) </b>
                    </span>
                  </div>
                  <div className="col-span-1 ">
                    <span className={getStatus(contract.status).style}>
                      {getStatus(contract.status).name}
                    </span>
                    <br />
                    <br />
                    <span className="whitespace-nowrap py-4 text-md font-medium text-gray-900">
                      Premium Financed:{" "}
                      <b>
                        $
                        {nullTransform(
                          FormatNumber(
                            `${getDeepValue(contract, "loan.principal")}`
                          )
                        )}
                      </b>
                    </span>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </>
      )}
    </ul>
  );
}
