import { useEffect } from 'react';

export default function Index() {

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('user'))
    let token = localStorage.getItem('token')

    if(user && token){
      window.location.href = '/dashboard'
    }else{
      window.location.href = '/signin'
    }
  });
  return (
    <div className="text-3xl font-bold underline">

    </div>
  )
}
