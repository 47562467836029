/* eslint-disable no-restricted-globals */
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import Http from "../../util/http";
import moment from "moment";
import AccessControl from "../../util/accessControl";

export default function PaymentDateModal({ contract, installment, index }) {
  const [open, setOpen] = useState(false);
  const [start, setStart] = useState("");
  const [current, setCurrent] = useState("");
  const [days, setDays] = useState(0);
  const [active, setActive] = useState(true);

  useEffect(() => {
    try {
      setCurrent(installment.dueDate.substring(0, 10));
      if (installment.status !== "scheduled") {
        setActive(false);
      }
      var duration = moment.duration(
        moment().diff(
          moment(contract.startDate || contract?.loan.firstInstalmentDate)
        )
      );
      duration = duration.asDays();
      if (duration > 30) {
        setActive(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [contract]);

  function isSameMonthAndYear(date1, date2) {
    return date1.year() === date2.year() && date1.month() === date2.month();
  }
  async function changeDate(val) {
    let chosen = moment(val);
    let isCorrect = isSameMonthAndYear(chosen, moment(current));
    console.log({ isCorrect, chosen });
    if (isCorrect) {
      setCurrent(val);
    } else {
      alert(`Date has to be in in this month and year.`);
    }
  }
  async function updateDays(all) {
    try {
      let text = `Are you sure you want to set the payment date for this installment to ${moment(
        current
      ).format("Do")} of ${moment(current).format("MMMM")}`;

      if (all) {
        text = `Are you sure you want to set the payment date for all future installments to ${moment(
          current
        ).format("Do")} of the month.`;
        if (confirm(text)) {
        } else {
          return;
        }
      } else {
        if (confirm(text)) {
        } else {
          return;
        }
      }

      let payload = {
        all: all,
        index: index,
        id: contract?.loan._id,
        date: current,
        installment: installment._id,
      };
      let response = await Http.request(
        "contracts",
        "contracts/set-installments",
        payload
      );

      console.log(response);
      setOpen(false);
      window.location.reload();
    } catch (error) {}
  }
  return (
    <div>
      {active && (
        <AccessControl
          permission={"editSchedule"}
          fallback={<></>}
          target={["god"]}
        >
          {/*          <span>
            <svg
              onClick={() => {
                setOpen(true);
              }}
              xmlns="http://www.w3.org/2000/svg"
              className="grid-cols-1 ml-3 mt-1 h-4 w-4 cursor-pointer hover:indigo-500"
              viewBox="0 0 512 512"
            >
              <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
            </svg>
          </span> */}
        </AccessControl>
      )}

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <CalendarDaysIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Change the Payment Date
                      </Dialog.Title>
                      <div className="mt-6">
                        <div className="text-xl font-bold leading-7 text-gray-900">
                          New Payment Date:
                        </div>
                        <div className="mt-2 flex">
                          <input
                            value={current}
                            onChange={(e) => {
                              changeDate(e.target.value);
                            }}
                            type="date"
                            name="start"
                            id="start"
                            //min={Date(start).split('T')[0]} // Set min attribute to today's date
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex mx-2 w-3/12 justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => updateDays(false)}
                    >
                      Update One
                    </button>
                    <button
                      type="button"
                      className="inline-flex mx-2 w-3/12 justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                      onClick={() => updateDays(true)}
                    >
                      Update All
                    </button>
                    <button
                      type="button"
                      className="inline-flex mx-2 w-3/12 justify-center rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
