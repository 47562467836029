import axios from "axios";
import Swal from "sweetalert2";

let services = {
  authentication: `${process.env.REACT_APP_IDENTITY_API}/api`,
  admin: `${process.env.REACT_APP_ADMIN_API}/api`,
  contracts: `${process.env.REACT_APP_ADMIN_CONTRACT_API}/api`,
};
const delay = (delayInms) => {
  return new Promise((resolve) => setTimeout(resolve, delayInms));
};

let Http = {
  async request(service, target, payload) {
    try {
      let token = localStorage.getItem("token");
      let user = JSON.parse(localStorage.getItem("user"));
      if (!token) {
        window.location.href = `${window.location.origin}/signin`;
      }
      if (!user) {
        window.location.href = `${window.location.origin}/signin`;
      }
      let geo = localStorage.getItem("geolocation");
      //console.log({geo})
      let url = services[service];
      let headers = {
        "x-auth-token": token,
      };
      if (geo === null) {
        headers["geolocation"] = user.geolocation;
      } else {
        headers["geolocation"] = JSON.parse(geo)._id;
      }
      let response = await axios.post(url, { target, payload }, { headers });

      return response.data;
    } catch (e) {
      console.log(e);
      let errorMessage = e.response?.data?.error || "Failed to successfully make request";
      if (errorMessage === "User is not allowed to commit this action") {
        window.location.href = `${window.location.origin}/error/403`;
        return delay(200);
      }else if (errorMessage === "Invalid auth token."){
        window.location.href = `${window.location.origin}/signin`;
        return delay(200);
      }else{
        throw new Error(errorMessage);
      }
    }
  },
};

export default Http;
