import React, { useEffect, useState } from "react";

export default function TelephoneInput({ setKey, path, value, className, isRequired,disabled }) {
  const [code, setCode] = useState("");

  useEffect(() => {
    // Set country code based on geolocation
    const geolocation = localStorage.getItem("geolocation");
    const country = geolocation ? JSON.parse(geolocation) : {};
    let countryCode = country.areacode; // Default code

    // switch (country.code) {
    //   case "tto":
    //     countryCode = "868";
    //     break;
    //   case "vct":
    //     countryCode = "784";
    //     break;
    //   case "lca":
    //     countryCode = "758";
    //     break;
    //   case "grd":
    //     countryCode = "473";
    //     break;
    //   default:
    //     break;
    // }

    setCode(countryCode);

    // Format the value if there's any input
    if (value) {
      const formatted = formatPhoneNumber(value, countryCode);
      if (formatted !== value) {
        setKey(path, formatted);
      }
    }
  }, [value, path, setKey]);

  // Format function to apply the mask and handle country code replacement
  function formatPhoneNumber(inputValue, countryCode) {
    // Remove non-numeric characters
    const cleaned = inputValue.replace(/\D/g, "");

    // Check if the number starts with "876" or "1876" and replace with the country code
    let fullNumber = cleaned;
    if (fullNumber.startsWith("876") || fullNumber.startsWith("1876")) {
      fullNumber = `${countryCode}${cleaned.slice(3)}`; // Replace the "876" or "1876" with the country code
    } else if (!fullNumber.startsWith(countryCode)) {
      fullNumber = `${countryCode}${cleaned}`; // Add country code if not present
    }

    // Ensure the full phone number length doesn't exceed the allowed length
    if (fullNumber.length > 10) {
      fullNumber = fullNumber.slice(0, 10);
    }

    // Format into XXX-XXX-XXXX
    if (fullNumber.length === 10) {
      return `${fullNumber.slice(0, 3)}-${fullNumber.slice(3, 6)}-${fullNumber.slice(6)}`;
    } else if (fullNumber.length >= 7) {
      return `${fullNumber.slice(0, 3)}-${fullNumber.slice(3, 6)}-${fullNumber.slice(6)}`;
    } else if (fullNumber.length >= 4) {
      return `${fullNumber.slice(0, 3)}-${fullNumber.slice(3)}`;
    } else {
      return fullNumber; // Return as-is for fewer than 4 digits
    }
  }

  // Handle input change
  function handleInputChange(e) {
    const newValue = e.target.value;
    const formattedValue = formatPhoneNumber(newValue, code);
    setKey(path, formattedValue); // Update parent state
  }

  return (
    <input
      type="text"
      value={value} // Reflects the formatted value
      onChange={handleInputChange}
      required={isRequired || false}
      className={className}
      maxLength={12} // Max length is 12 to accommodate the format (XXX-XXX-XXXX)
      placeholder="Enter phone number"
      disabled={disabled || false}
    />
  );
}
