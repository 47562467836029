import {useState} from 'react'
import {setDeepValue} from '../../../util/util'
import Http from '../../../util/http'
import Swal from 'sweetalert2'
import AccessControl from "../../../util/accessControl";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


function isEmpty(value) {
    return (value == null || value.length === 0);
}

export default function PermissionCreate() {

    const [permission, setPermission] = useState({
        description: '',
        name: '',
        text: '',
        status: 'active',
    })

    function setKey(key, value) {
        console.log({key: key, value: value})
        let object = {...permission}

        setDeepValue(object, value, key)
        setPermission(object)
    }

    function save(e) {
        let dialog = Swal.fire({
            title: 'Loading...',
            timerProgressBar: true,
            allowOutsideClick: false, 
            didOpen: () => {
              Swal.showLoading()
            },
            willClose: () => {
            }
          })
        e.preventDefault();
        if (isEmpty(permission.description) || isEmpty(permission.name) || isEmpty(permission.text)) {
            alert('Please check fields they cannot be empty')
        }
        try {
            Http.request('admin', 'permissions/create', permission).then((response) => {
                dialog.close()
                Swal.fire({
                    icon: 'success',
                    title: 'Hurray!',
                    text: 'Permission added successfully.',
                }).then(() => {
                    window.location = `${window.location.origin}/dashboard/permissions`
                })
            }).catch((err) => {
                dialog.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Failed to make request to servers.',
                })
            })
        } catch (err) {
            dialog.close()
            console.log(err)
        }
    }

    return (
        <div className="shadow sm:overflow-hidden sm:rounded-md  ">
            <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
                <form onSubmit={(e) => {
                    save(e)
                }}>
                    <div className="shadow sm:overflow-hidden sm:rounded-md">
                        <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
                            <div>
                                <h2 className="text-base font-semibold leading-6 text-gray-900">Permission
                                    Information</h2>
                                <p className="mt-1 text-sm text-gray-500">Please ensure all fields are filled in
                                    correctly.</p>
                            </div>

                            <div className="grid grid-cols-6 gap-10">
                                <div className="col-span-6">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        onChange={(e) => {
                                            setKey('name', e.target.value)
                                        }}
                                        value={permission.name}
                                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Key
                                    </label>
                                    <input
                                        type="text"
                                        onChange={(e) => {
                                            setKey('text', e.target.value)
                                        }}
                                        value={permission.text}
                                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Permission Status
                                    </label>
                                    <select
                                        id="type"
                                        name="type"
                                        autoComplete="type"
                                        value={permission.status}
                                        onChange={(e) => {
                                            setKey('status', e.target.value)
                                        }}
                                        className="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >
                                        <option value={'active'}>Active</option>
                                        <option value={'disabled'}>Disabled</option>
                                    </select>
                                </div>
                                <div className="col-span-6">
                                    <label htmlFor="description"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Description
                                    </label>
                                    <textarea
                                        rows={3}
                                        type="text"
                                        name="description"
                                        id="description"
                                        value={permission.description}
                                        onChange={(e) => {
                                            setKey('description', e.target.value)
                                        }}
                                        autoComplete="description"
                                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>


                            </div>
                        </div>
                        <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
                            <a href='/dashboard/permissions' type="button"
                               className="text-sm font-semibold leading-6 text-gray-900 mx-3">Cancel</a>
                            <AccessControl fallback={<></>} permission={'addPermissions'} target={['god']}>
                                <button
                                    type="submit"
                                    className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Save
                                </button>
                            </AccessControl>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

