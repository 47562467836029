import React from "react";
import { useState } from "react";

export default function (props) {
  const [mode, setMode] = useState(false);
  const handleModeChange = async ()=>{
    setMode(!mode)

  }
  return (
    <>
      {mode && (
        <span
          style={{ cursor: "pointer" }}
          className="h-7 inline-flex items-center rounded-full bg-gray-200 pl-4 text-[12px] font-medium text-gray-600 mr-3"
        >
          <div>{props.field.value}</div>
          <input
          value={props.field.text}
          onChange={(e)=>{props.setTargetField(props.index,e.target.value)}}
          onBlur={()=>{handleModeChange()}} 
          className="h-7 rounded-full ml-3 text-[12px] border-gray-200 font-medium text-gray-600 focus:outline-none focus:border-gray-300 focus:ring-1 focus:ring-gray-300" />
        </span>
      )}
      {!mode &&
            <span
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                    setMode(true)
                }}
                className="inline-flex items-center rounded-full bg-gray-200 px-7 py-1 text-xs font-medium text-gray-600 mx-5"
                >
                <div className={"" + (props.field.text !== "" ? 'font-bold' : '')}>{props.field.value}</div>
                {props.field.text !== "" &&
                    <> : {props.field.text}</>
                }
            </span>
      }
    </>
  );
}
