import DashboardLayout from "../../../layouts/dashboard"
import Layout from "../../../layouts/layout"
export default function NotFound(){
    return (
        <div className="flex items-center justify-center min-h-screenpy-48 m-40">
            <div className="flex flex-col">



                <div className="flex flex-col items-center align-middle">
                    <div className="text-indigo-500 font-bold text-7xl text-center">
                        404
                    </div>

                    <div className="font-bold text-3xl xl:text-7xl lg:text-6xl md:text-5xl mt-10 text-center">
                        This client does not exist
                    </div>

                    <div className="text-gray-400 font-medium text-sm md:text-xl lg:text-2xl mt-8 text-center">
                        Please search for another client.
                    </div>
                </div>

            </div>
        </div>
    )
}

NotFound.getLayout = function getLayout(page) {
    return (
        <Layout>
            <DashboardLayout>{page}</DashboardLayout>
        </Layout>
    )
}