import React, { Fragment, useState, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Swal from "sweetalert2";
import http from "../util/http";
import { CheckIcon } from "@heroicons/react/24/outline";
import swal from "sweetalert2";
import moment from "moment";
import QuotationPreview from "./dashboard/qoutes/preview";
import QuotationSend from "./dashboard/qoutes/send";
import QuotationButtons from "./dashboard/qoutes/buttons";


export default function QuotationModal() {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("preview");
  const [info, setInfo] = useState({
    name: "",
    email: "",
    premium: 0,
    startDate: moment().format("YYYY-MM-DD"),
    policyEndDate: moment().add(12, "months").format("YYYY-MM-DD"),
    months: "",
    paymentDate: "",
    type: "",
  });
  const [previewInfo, setPreviewInfo] = useState([]);
  const cancelButtonRef = useRef(null);
  const [tabs, setTabs] = useState([
    { name: "6 Months", href: "#", current: true },
    { name: "9 Months", href: "#", current: false },
    { name: "11 Months", href: "#", current: false },
  ]);
  const [type, setType] = useState(null);
  const [preview, setPreview] = useState(null);

  const [summary, setSummary] = useState({
    data: [],
    headers: {
      interest: 2,
    },
  });
  const [premiumMask, setPremiumMask] = useState("$0.00");

  const [loading, setLoading] = useState(false);
  const [months, setMonths] = useState([]);

  useEffect(() => {
    try {
      // if(mode === 'preview'){
      //   setLoading(true);
      //   getSummaries();
      //   setLoading(false);
      // }
    } catch (error) {
      console.log(error);
    }
  }, [mode]);

  async function changeVal(val, key) {
    let payload = { ...info };
    payload[key] = val;

    if (key === "startDate") {
      let _date = moment(val).add(1, "months").format().substring(0, 10);
      payload.paymentDate = _date;
      console.log({ payload });
    }
    setInfo(payload);
    await delay(1000);
    if (key === "premium") {
      if (isNaN(val) || val <= 0) {
        setPremiumMask("$0.00");
      } else {
        const CurrencyFormat = Intl.NumberFormat("en-us", {
          style: "currency",
          currency: "USD",
        });
        setPremiumMask(CurrencyFormat.format(val));
      }
    }

    //await getSummary(payload);
  }

  async function getInterest(premium) {
    try {
      let response = await http.request("contracts", "contracts/get-interest", {
        premium,
      });
      console.log({ response });
      return response.rate;
    } catch (err) {
      console.log(err);
    }
  }

  function closeModal() {
    setOpen(false);
    setMode("preview");
    setPreview(null);
    setPreviewInfo([]);
    setInfo({
      name: "",
      email: "",
      premium: 0,
      startDate: moment().format("YYYY-MM-DD"),
      policyEndDate: moment().add(12, "months").format("YYYY-MM-DD"),
      months: "",
      paymentDate: "",
      type: "",
    });

    setSummary({
      data: [],
      headers: {
        interest: 2,
      },
    });
    setType(null);
    setPremiumMask("$0.00");
    
  }
  async function send() {
    try {
      if (info.name === "" || info.email === "" || info.premium === 0) {
        return swal.fire("Please check your fields", "", "info");
      }
      let payload = { ...info };
      payload.type = type;
      payload.summary = preview;
      let results = await http.request(
        "contracts",
        "contracts/send-quotes",
        payload
      );
      swal.fire("Quotation sent successfully", "", "success");
      closeModal();
      console.log(results);
    } catch (e) {
      console.log("Error sending Quotation", e);
      swal.fire("Something went wrong", "", "error");
    }
  }
  async function getSummaries() {
    let months = [6, 9, 11];
    let results = [];

    for (let index = 0; index < months.length; index++) {
      const month = months[index];
      info.months = month;
      try {
        let response = await http.request(
          "contracts",
          "contracts/amortise",
          info
        );
        if (info.premium > 0 && info.type?.includes("commercial")) {
          response.headers.interest = await getInterest(info.premium);
          results.push(response);
        } else {
          results.push(response);
        }
      } catch (err) {
        console.log(err);
      }
    }
    setPreview(results[0]);
    setPreviewInfo(results);
    setTabs([
      { name: "6 Months", href: "#", current: true },
      { name: "9 Months", href: "#", current: false },
      { name: "11 Months", href: "#", current: false },
    ]);
  }
  async function getSummary(payload) {
    try {
      if (
        payload.premium === 0 ||
        payload.startDate === "" ||
        payload.policyEndDate === ""
      ) {
        return;
      }
      console.log({ payload });
      let response = await http.request(
        "contracts",
        "contracts/amortise/dynamic",
        payload
      );
      let _tabs = response.payload.map((obj, index) => {
        let item = {
          name: `${obj.data.length} months`,
          href: "#",
          current: false,
        };
        if (index === 0) {
          item.current = true;
        }
        return item;
      });
      setTabs(_tabs);
      if (response.payload.length > 0) {
        setPreview(response.payload[0]);
      }
      setPreviewInfo(response.payload);
    } catch (err) {
      console.log(err);
    }
  }
  async function generate() {
    try {
      if (!type || info.premium === 0) {
        return alert("Please fill all required fields", "", "info");
      }
      // let data = {
      //   name: "",
      //   email: "",
      //   premium: info.premium,
      //   startDate: moment().format("YYYY-MM-DD"),
      //   months: 3,
      //   paymentDate: "",
      //   type: type,
      // };
      let data = { ...info };
      data.type = type;
      let d = data.startDate.split("-");
      data.paymentDate = moment()
        .add(1, "months")
        .set(d[d.length - 1], "date")
        .format("YYYY-MM-DD");
      setInfo(data);
      setLoading(true);
      console.log({ data });
      setTimeout(async () => {
        if (type === "commercial") {
          await getSummaries();
        } else if (type === "retail") {
          console.log("Retail");
          await getSummary(data);
        }
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  }
  const delay = (delayInms) => {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  };
  return (
    <React.Fragment>
      <div class="fixed bottom-6 right-6">
        <button
          onClick={(e) => {
            setOpen(true);
          }}
          class="w-16 h-16 bg-blue-500 hover:bg-blue-600 text-white rounded-full shadow-lg flex items-center justify-center transition transform hover:scale-110"
        >
          <i className="fa-solid fa-calculator mx-2"></i>
        </button>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all opacity-100 translate-y-0 sm:scale-100">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Quotation Information
                      </Dialog.Title>
                      {mode === "send" && (
                        <QuotationSend setInfo={setInfo} info={info} />
                      )}
                      {mode === "preview" && (
                        <QuotationPreview
                          generate={generate}
                          preview={preview}
                          changeVal={changeVal}
                          previewInfo={previewInfo}
                          premiumMask={premiumMask}
                          loading={loading}
                          tabs={tabs}
                          setTabs={setTabs}
                          setPreview={setPreview}
                          setType={setType}
                          type={type}
                          setInfo={setInfo}
                          setSummary={setSummary}
                          months={months}
                          info={info}
                          summary={summary}
                          setPreviewInfo={setPreviewInfo}
                          setMode={setMode}
                        />
                      )}
                    </div>
                    <QuotationButtons
                      mode={mode}
                      send={send}
                      setMode={setMode}
                      summary={summary}
                      previewInfo={previewInfo}
                      closeModal={closeModal}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
}
