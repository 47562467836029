import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PencilSquareIcon, PlusIcon } from "@heroicons/react/24/outline";
import { setDeepValue } from "../util/util";
import Http from "../util/http";
import AccessControl from "../util/accessControl";
import Swal from "sweetalert2";
import TelephoneInput from "./dashboard/telephoneInput";

export default function OrganizationBranchManageModal({
  open,
  setOpen,
  action,
  clients,
  index,
  organization,
  branchInfo,
  save,
}) {
  const cancelButtonRef = useRef(null);

  const [permission, setPermission] = useState("");
  const [branch, setBranch] = useState({
    _id: "",
    title: "",
    address: "",
    type: "branch",
    email: "",
    contactPerson: "",
    telephone: "",
    selectedOrganization: "",
  });
  const [title, setTitle] = useState("");

  useEffect(() => {
    action === "create"
      ? setPermission("createOrganizationBranch")
      : setPermission("editOrganizationBranch");
    action === "create"
      ? setTitle("Create Organization Branch")
      : setTitle("Edit Organization Branch");
    if (index !== null) {
      if (
        branchInfo.contactPerson.name === undefined ||
        branchInfo.contactPerson.name === null ||
        branchInfo.contactPerson.email === undefined ||
        branchInfo.contactPerson.email === null
      ) {
        branchInfo.contactPerson = {
          name: "",
          email: "",
        };
      }
      setBranch(branchInfo);
    } else {
      setBranch({
        _id: "",
        title: "",
        address: "",
        email: "",
        contactPerson: {
          name: "",
          email: "",
        },
        telephone: "",
        selectedOrganization: organization._id
      });
    }
  }, [action, index, branchInfo]);

  function setKey(key, value) {
    let object = { ...branch };

    setDeepValue(object, value, key);
    setBranch(object);
  }
  function checkFields(e) {
    e.preventDefault();
    console.log(branch);
    let notEmpty = false;
    if (
      branch.title !== "" &&
      branch.selectedOrganization !== "" &&
      branch.telephone !== "" &&
      branch.contactPerson.email !== "" &&
      branch.contactPerson.name !== "" &&
      branch.email !== "" &&
      branch.address !== ""
    ) {
      notEmpty = true;
    }
    if (notEmpty) {
      save(branch, index, action);
    } else {
      Swal.fire({
        icon: "info",
        title: "Oops...",
        text: "Please check fields. They cannot be empty.",
      });
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                      {action === "create" && (
                        <PlusIcon
                          className="h-6 w-6 text-white-600"
                          aria-hidden="true"
                        />
                      )}
                      {action === "edit" && (
                        <PencilSquareIcon
                          className="h-6 w-6 text-white-600"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {title}
                      </Dialog.Title>
                      <div className="mt-10">
                        <div className="col-span-6 sm:col-span-3">
                          <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Title
                            </label>
                            <input
                              required
                              type="text"
                              onChange={(e) => {
                                setKey("title", e.target.value);
                              }}
                              value={branch.title}
                              className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3 mt-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Type
                            </label>
                            <select
                              type="text"
                              re
                              value={branch.type}
                              onChange={(e) => {
                                setKey("type", e.target.value);
                              }}
                              className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              <option value={"agency"}> Agency</option>
                              <option value={"branch"}> Branch</option>
                            </select>
                          </div>

                          <div className="col-span-6 sm:col-span-3 mt-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Address
                            </label>
                            <input
                              type="text"
                              re
                              value={branch.address}
                              onChange={(e) => {
                                setKey("address", e.target.value);
                              }}
                              className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3 mt-2">
                            <label
                              htmlFor="email-address"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Email address
                            </label>
                            <input
                              value={branch.email}
                              onChange={(e) => {
                                setKey("email", e.target.value);
                              }}
                              type="email"
                              name="email-address"
                              id="email-address"
                              autoComplete="email"
                              className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3 mt-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Contact Person Name
                            </label>
                            <input
                              id="type"
                              type="text"
                              autoComplete="type"
                              value={branch.contactPerson?.name}
                              onChange={(e) => {
                                setKey("contactPerson.name", e.target.value);
                              }}
                              className="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            ></input>
                          </div>
                          <div className="col-span-6 sm:col-span-3 mt-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Contact Person Email
                            </label>
                            <input
                              id="email"
                              name="type"
                              autoComplete="type"
                              type="email"
                              value={branch.contactPerson?.email}
                              onChange={(e) => {
                                setKey("contactPerson.email", e.target.value);
                              }}
                              className="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            ></input>
                          </div>
                          <div className="col-span-6 sm:col-span-3 mt-2">
                            <label
                              htmlFor="telephone"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Telephone
                            </label>
                            <TelephoneInput setKey={setKey} path={'telephone'} value={branch.telephone}className={"mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <AccessControl
                      permission={permission}
                      fallback={<></>}
                      target={["god"]}
                    >
                      <button
                        onClick={(e) => checkFields(e)}
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      >
                        Save
                      </button>
                    </AccessControl>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
