import { useEffect, useState } from "react"


export default function AccessControl(props){

    const [user,setUser] = useState(null)
    let fallback = 
                    <>
                        <span className={"no-bold text-danger"}>Access Denied</span>
                    </>

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('user')))
        if(props.page){
            fallback =  <div className="w-full">
                            <span className={"no-bold text-danger"}>You lack the permissions to view this content.</span>
                        </div>
        }
    }, [])
    return(
        <>
            {user &&
                <>
                    {user.position === 'god' &&
                        <>{props.children}</>
                    }
                    {/*{props.target.includes(user.position) &&
                        <>{props.children}</>
                    }*/}
                    {user.position !== 'god' && !props.target.includes(user.position) &&
                        <>
                            {user.permissions.includes(props.permission) &&
                                <>
                                    {props.children}
                                </>
                            }
                            { !user.permissions.includes(props.permission) &&
                                <>{props.fallback}</>
                            }
                        </>
                    }
                </>
            }
        </>
    )
}